import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class CommonCodeService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorObservable (error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }

  private handleErrorPromise (error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 공통그룹코드 리스트 가져오기
  getCommonGroupList(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonGroupList'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통그룹코드 상세 가져오기
  getCommonGroupView(seq: number): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonGroupView'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통그룹코드 추가하기(POST방식)
  setAddCommonGroupCode( form ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'setAddCommonGroupCode'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  deleteCommonGroup( form ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'deleteCommonGroup'
      }, {
        seq: form.controls.seq.value
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통그룹코드 삭제하기
  setCommonGroupDelete(seq: number): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'setCommonGroupDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통코드 리스트 가져오기
  getCommonList( code: number): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonList'
      }, {
        code: code
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통코드 리스트 가져오기
  getCommonListCode( groupCode: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonListCode'
      }, {
        groupCode: groupCode
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통코드 상세 가져오기
  getCommonView(seq: number): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonView'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통코드 추가하기(POST방식)
  setAddCommonCode(form): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'setAddCommonCode'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공통코드 삭제하기
  setCommonDelete(seq: number): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'setCommonDelete'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회원구분 가져오기
  getMemberTypeSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getMemberTypeSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 폐지여부 가져오기
  getStatusSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getStatusSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 사무소 가져오기
  getOfficeSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getOfficeSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시/도 가져오기
  getRegionSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getRegionSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시/구/군 가져오기
  getCitySelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getCitySelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 사용용도 가져오기
  getUseSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getUseSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 냉매가스 가져오기
  getFreezeSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getFreezeSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // DM발송여부 가져오기
 getDmSendSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getDmSendSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 완성검사기간 가져오기
  getCompleteTypeSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getCompleteTypeSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 완성검사주기 가져오기
  getCompleteGubunSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getCompleteGubunSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사가능여부 가져오기
  getInspectableSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getInspectableSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 관리지사 리스트 가져오기
  getkgsBranchSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getkgsBranchSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 접수상태 리스트 가져오기
  getReceptionStatusSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getReceptionStatusSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사기관 리스트 가져오기
  getInspectionAgencySelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getInspectionAgencySelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사종류 리스트 가져오기
  getInspectionTypeSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getInspectionTypeSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사표 판정결과 리스트 가져오기
  getJudgmentSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getJudgmentSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상담구분 리스트 가져오기
  getCounselingTypeSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getCounselingTypeSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 발행구분 리스트 가져오기
  getCertificateStatusSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'commoncode',
        version: '1.0',
        action: 'getCertificateStatusSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
