import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-print-facility-checklist',
  templateUrl: './print-facility-checklist.component.html',
  styleUrls: ['./print-facility-checklist.component.scss']
})
export class PrintFacilityChecklistComponent implements OnInit {

  public year: any = moment().format('YYYY');
  public month: any = moment().format('MM');
  public day: any = moment().format('DD');

  constructor() { }

  ngOnInit() {
  }

}
