import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorObservable (error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }

  private handleErrorPromise (error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 시/도 가져오기
  getRegionSelect(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'region',
        version: '1.0',
        action: 'getRegionSelect'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시/구/군 가져오기
  getCitySelect( regionCode ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'region',
        version: '1.0',
        action: 'getCitySelect'
      }, {
        regionCode: regionCode
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시/도 가져오기
  getRegionList(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'region',
        version: '1.0',
        action: 'getRegionList'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 구/군 가져오기
  getCityList( regionCode ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'region',
        version: '1.0',
        action: 'getCityList'
      }, {
        regionCode: regionCode
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 용도별 검사가능여부 리스트 가져오기
  getRegionInspectableList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'region',
        version: '1.0',
        action: 'getRegionInspectableList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사지역 상세정보 가져오기
  getRegionInspectableInfo( citySeq ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'region',
        version: '1.0',
        action: 'getRegionInspectableInfo'
      }, {
        citySeq: citySeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사지역 정보 수정
  setRegionInspectableInfoUpdate( form: any ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'region',
        version: '1.0',
        action: 'setRegionInspectableInfoUpdate'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 용도별 검사가능여부 일괄 등록
  setRegionInspectableInfoBatch( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'region',
        version: '1.0',
        action: 'setRegionInspectableInfoBatch'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 용도별 검사가능여부 일괄 저장
  setRegionInspectableBatch( params: any ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'region',
        version: '1.0',
        action: 'setRegionInspectableBatch'
      }, {
        params: params
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
