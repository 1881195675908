/*******************************************************************************
  설  명 : 시설대장
  작성일 : 2020-02-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { CommonService } from '@app/service/common.service';
import { Globals } from '@app/service/globals.service';
import { MemberService } from '@app/service/member.service';
import { RegionService } from '@app/service/region.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { ReportService } from '@app/service/report.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

import { ModalFacilityInfoComponent } from '@app/components/modal-facility-info/modal-facility-info.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-books-facility',
  templateUrl: './books-facility.component.html',
  styleUrls: ['./books-facility.component.scss']
})
export class BooksFacilityComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 공통코드 데이터 리스트
  memberTypeList: any = [];
  regoinList: any = [];
  cityList: any = [];
  officeList: any = [];
  memberList: any = [];

  // 검색 필드
  public search = {
    searchField: 'facilityName',
    searchText: '',
    memberType: '',
    regionCode: '',
    cityCode: '',
    officeCode: '',
    memberNo: '',
    useYn: ''
  }

  // 그리드 관련 선언
  selectedRows: number = 0;
  dataList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private memberService: MemberService,
    private regionService: RegionService,
    private commonCodeService: CommonCodeService,
    private reportService: ReportService,
    private globals: Globals
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '순번', field: 'rowIndex', cellClass: 'cp center', checkboxSelection: true,  valueGetter: "node.rowIndex + 1" ,width:85},
      {headerName: '순번', field: 'facilitySeq', cellClass: 'cp center', checkboxSelection: true , hide:true},
      {headerName: '업체명', field: 'companyName', cellClass: 'cp', width:150 },
      {headerName: '시설코드', field: 'facilityCode', cellClass: 'cp center' , hide:true },
      {headerName: '시설코드', field: 'facilityCodeFormat', cellClass: 'cp center', width:85 },
      {headerName: '시설명', field: 'facilityName', cellClass: 'cp' , width:150},
      {headerName: '시/도', field: 'regionName', cellClass: 'cp center', width:90 },
      {headerName: '구/군', field: 'cityName', cellClass: 'cp center', width:90 },
      {headerName: '주소', field: 'facilityAddressFull', cellClass: 'cp ', width:200 },
      {headerName: '대표자', field: 'ceoName', cellClass: 'cp center' , width:90},
      {headerName: '전화번호', field: 'chargeTel', cellClass: 'cp center', width:120 },
      {headerName: '사용용도', field: 'usageName', cellClass: 'cp center' , width:90},
      {headerName: '허가번호', field: 'permitNum', cellClass: 'cp' , width:150},
      {headerName: '허가일자', field: 'permitDate', cellClass: 'cp center' , width:90},
      {headerName: '냉동톤', field: 'freezeCapacity', cellClass: 'cp center' , width:90},
      {headerName: '냉매', field: 'freezeName', cellClass: 'cp center', width:90 },
      {headerName: '검사가능여부', field: 'trmYnName', cellClass: 'cp', width:120 },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 공통코드 리스트 가져오기
    this.getCommonList();

    // 시설대장 리스트 가져오기
//    this.getFacilityReportList();
  }

  /*******************************************************************************
    설  명 : 뷰 처리 완료 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {

    // indicator 표시
    setTimeout (() => {
      if( this.dataList.length < 1 )
        this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchField: 'facilityName',
      searchText: '',
      memberType: '',
      regionCode: '',
      cityCode: '',
      officeCode: '',
      memberNo: '',
      useYn: ''
    }

//   this.getFacilityReportList();
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 회원구분
    await this.commonCodeService.getMemberTypeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.memberTypeList = response.data;
      } else {
        this.memberTypeList = [];
      }
    });

    // 시/도
    await this.regionService.getRegionSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.regoinList = response.data;
      } else {
        this.regoinList = [];
      }
    });

    // 사무소
    await this.commonCodeService.getOfficeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.officeList = response.data;
      } else {
        this.officeList = [];
      }
    });

    // 지역담당
    await this.memberService.getMemberSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.memberList = response.data;
      } else {
        this.memberList = [];
      }
    });
    
    // 사무소코드
    await this.memberService.getMemberOffice().then( response => {
     // console.log(response.data['officeCd']);
      if( response.ResultCode ) {
        this.search.officeCode = response.data['officeCd'];
      } else {
        this.search.officeCode = '';
      }
    });
    
  }

  /*******************************************************************************
    설  명 : 시설대장 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getFacilityReportList() {
    await this.reportService.getFacilityReportList( this.search ).then( response => {

      this.globals.indicator.next(true);
      if( response.ResultCode ) {
        this.dataList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : 시/구/군 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCitySelect() {
    this.cityList = [];

    await this.regionService.getCitySelect( this.search.regionCode ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.cityList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClicked($event) {
    this.addFacility( $event.data.companySeq, $event.data.facilitySeq );
  }

  /*******************************************************************************
    설  명 : 업체 및 시설 수정
    입력값 : seq = 0 일 경우 추가, 이외에는 수정
    리턴값 : 없음
  *******************************************************************************/
  addFacility( companySeq, facilitySeq ) {
    const modalRef = this.modalService.open(ModalFacilityInfoComponent, options);

    modalRef.componentInstance.companySeq = companySeq;
    modalRef.componentInstance.facilitySeq = facilitySeq;

    modalRef.result.then((result) => {
      if( result ) {
        this.getFacilityReportList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getFacilityReportList();
    }
  }

  /*******************************************************************************
    설  명 : 시/도 선택 시
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setRegionChange() {
    this.search.cityCode = '';

    this.getCitySelect();
  }

  /*******************************************************************************
    설  명 : 시설대장출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openFacility() {
    let rowsSelection = this.gridApi.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '시설을 선택하시기 바랍니다.', '');
      return false;
    }

    let url = '/books/facility/print/facility?seq=' + rowsSelection[0].facilitySeq;

    window.open(url, 'booksFacilityPrintFacility', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
  }

}
