/*******************************************************************************
  설  명 : 상담정보 등록/수정
  작성일 : 2020-02-26
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { MemberService } from '@app/service/member.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { ReceptionService } from '@app/service/reception.service';
import { Globals } from '@app/service/globals.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-modal-counseling-add',
  templateUrl: './modal-counseling-add.component.html',
  styleUrls: ['./modal-counseling-add.component.scss']
})
export class ModalCounselingAddComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역 변수
  *******************************************************************************/
  public counselingSeq: any;
  public receptionSeq: any;
  public facilityCode: any;

  public typeList: any = [];
  public memberList: any = [];

  public title: any = '등록';

  private dateModel: NgbDateStruct;

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      counselingSeq:["", [] ],
      receptionSeq:["", [Validators.required] ],
      facilityCode:["", [Validators.required] ],
      counselingDate:["", [Validators.required] ],
      counselingType:["", [Validators.required] ],
      memberNo:["", [Validators.required] ],
      counselingMemo:["", [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private globals: Globals,
    private memberService: MemberService,
    private commonCodeService: CommonCodeService,
    private receptionService: ReceptionService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.form.patchValue({
      counselingSeq: this.counselingSeq,
      receptionSeq: this.receptionSeq,
      facilityCode: this.facilityCode
    });

    // 공통코드 가져오기
    this.getCommonList();
    
    // 수정 시
    if( this.counselingSeq != false && this.counselingSeq != '0' ) {
      this.title = '수정';

      this.getReceptionCounselingInfo();
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 상담구분
    await this.commonCodeService.getCounselingTypeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        response.data.forEach( row => {
          this.typeList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.typeList = [];
      }
    });

    // 상담자
    await this.memberService.getMemberSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        response.data.forEach( row => {
          this.memberList.push({
            title: row.name,
            value: row.memberNo
          });
        });
      } else {
        this.memberList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 상담 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getReceptionCounselingInfo() {
    await this.receptionService.getReceptionCounselingInfo( this.counselingSeq ).then( response => {
      if( response.ResultCode ) {
        let counselingInfo = response.data;

        if( counselingInfo.counselingDate !== null ) {
          this.dateModel = {
            year: parseInt( counselingInfo.counselingDate.substr( 0, 4 ) ),
            month: parseInt( counselingInfo.counselingDate.substr( 5, 2 ) ),
            day: parseInt( counselingInfo.counselingDate.substr( 8, 2 ) )
          };

          counselingInfo.counselingDate = this.dateModel;
        }

        this.form.patchValue( counselingInfo );

      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 상담 등록/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCounseling() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.receptionService.setCounseling( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 상담 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCounselingDelete() {
    if( confirm("해당 상담을 삭제하시겠습니까?") ) {
      this.receptionService.setCounselingDelete( this.counselingSeq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');

        this.activeModal.close();
      });
    }
  }

}
