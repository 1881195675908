import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { Globals } from '@app/service/globals.service';

@Injectable()
export class AuthService {

  private isLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loginData: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  get getIsLogin() {
    return this.isLogin.asObservable();
  }

  get getLoginData() {
    return this.loginData.asObservable();
  }

  constructor(
    private router: Router,
    private globals: Globals,
  ) {}

  /*******************************************************************************
    설  명 : 로그인
  *******************************************************************************/
  login(response: any) {
    this.isLogin.next(true);
    this.loginData.next(response.data);
    this.globals.setToken(response.token);
  }

  /*******************************************************************************
    설  명 : 로그아웃
  *******************************************************************************/
  logout() {
    this.isLogin.next(false);
    this.loginData.next([]);
    this.globals.clearToken();
  }
}