/*******************************************************************************
  설  명 : 시설검사관리
  작성일 : 2020-02-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbCalendar, NgbModal, ModalDismissReasons, NgbModalOptions, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { RegionService } from '@app/service/region.service';
import { MemberService } from '@app/service/member.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { ReceptionService } from '@app/service/reception.service';
import { Globals } from '@app/service/globals.service';
import { CompanyfacilityService } from '@app/service/companyfacility.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Select2OptionData } from 'ng2-select2';

import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

import { ModalFacilityInfoComponent } from '@app/components/modal-facility-info/modal-facility-info.component';

import { InspectAddComponent } from './inspect-add/inspect-add.component';
import { CounselingComponent } from './counseling/counseling.component';
import { InspectReportComponent } from './inspect-report/inspect-report.component';
import { ReceiptInfoComponent } from './receipt-info/receipt-info.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const optionsInspect: NgbModalOptions = {
  backdrop: false,
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-backdrop-able'
};

@Component({
  selector: 'app-inspect-facility',
  templateUrl: './inspect-facility.component.html',
  styleUrls: ['./inspect-facility.component.scss']
})
export class InspectFacilityComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  tabIndex: any = 0;
  facilityCode: any;
  useYn: any;

  // 공통코드 데이터 리스트
  regionList: any = [];
  select2List: Array<Select2OptionData>;
  cityList: any = [];
  officeList: any = [];
  memberTypeList: any = [];

  officeCd:string;

  calendarInfo: any = {
    year: moment().format('YYYY'),
    month: moment().format('MM'),
    sdate: moment().format('YYYY-MM-01'),
    edate: moment().add(1, 'month').date(0).format('YYYY-MM-DD'),
    start_day: '',
    end_day: ''
  }

  // 검색 필드
  public search = {
    //receptionSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
    //receptionEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
    receptionSdate: '',
    receptionEdate: '',
    searchField: 'inspectionSdate',
    //inspectionSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
    //inspectionEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
    inspectionSdate: '',
    inspectionEdate: '',
    searchField1: 'facilityName',
    searchText: '',
    officeCode: '',
    regionCode: '',
    cityCode: [],
    paymentMemo: '',
    companyCode: '',
    facilityCode: '',
    memberType: '',
    useYn: '0'
  }

  // 그리드 관련 선언
  selectedRows: number = 0;
  selectedRowsDetail: number = 0;
  clientList: any = [];
  detailList: any = [];

  facilityInfo: any = {};

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;
  columnDefsDetail: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  select2Options = {
    multiple: true
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private memberService: MemberService,
    private regionService: RegionService,
    private commonCodeService: CommonCodeService,
    private receptionService: ReceptionService,
    private companyfacilityService: CompanyfacilityService,
    private globals: Globals,
    private calendar: NgbCalendar,
    private ngbDatepickerConfig: NgbDatepickerConfig,
    private ngZone: NgZone
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '순번', field: 'rowIndex', cellClass: 'cp center', checkboxSelection: true,valueGetter: "node.rowIndex + 1", width:80  },
      { headerName: '시설코드', field: 'facilityCodeFormat', cellClass: 'cp',  width:100  },
      { headerName: '시설코드', field: 'facilityCode', cellClass: 'cp',  width:100 , hide:true  },
      { headerName: '시설명', field: 'facilityName', cellClass: 'cp' ,   width:150},
      { headerName: '업체코드', field: 'companyCode', cellClass: 'cp',  width:90   },
      { headerName: '업체명', field: 'companyName', cellClass: 'cp',  width:150},
    ];

    // ag grid 컬럼 선언
    this.columnDefsDetail = [
      { headerName: '순번', field: 'rnum', cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, width:80, pinned:true},
      { headerName: '업체명', field: 'companyName', cellClass: 'cp center',width:150, pinned:true },
      { headerName: '시설명', field: 'facilityName', cellClass: 'cp center',width:150, pinned:true },
      { headerName: '검사종류', field: 'inspectionTypeName', cellClass: 'cp center',width:90 },
      { headerName: '접수일', field: 'receptionDate', cellClass: 'cp center',width:90 },
      { headerName: '접수번호', field: 'receptionNumberFormat', cellClass: 'cp center',width:90 },
      { headerName: '접수상태', field: 'receptionStatusName', cellClass: 'cp center',width:90 },
      { headerName: '예정일', field: 'planDate', cellClass: 'cp center',width:90 },
      { headerName: '검사일', field: 'inspectionDate', cellClass: 'cp center',width:90 },
      { headerName: '검사여부', field: 'inspectionYnName', cellClass: 'cp center',width:90 },
      { headerName: '검사원', field: 'memberName', cellClass: 'cp center',width:90 },
      { headerName: '검사결과', field: 'passYnName', cellClass: 'cp center',width:90,
        cellStyle: function(params) {
          if( params.data.passYnName == '합격' ) {
            return {color: '#5cb85c'};
          } else {
            return {color: '#d9534f'};
          }
        }
      } ,
      { headerName: '검사기관', field: 'inspectionAgencyName', cellClass: 'cp center',width:90 },
      { headerName: '필증번호', field: 'certificateNumberFormat', cellClass: 'cp center',width:90 },
      { headerName: '필증발행상태', field: 'certificateStatusName', cellClass: 'cp center',width:110 },
      { headerName: '냉동톤', field: 'frozenCapacity', cellClass: 'cp right' ,width:90},
      { headerName: '수수료', field: 'inspectionFeeTotal', cellClass: 'cp right', valueFormatter: currencyFormatter ,width:90},
      { headerName: '결재', field: 'approvalYnName', cellClass: 'cp center',width:90 },
      { headerName: '접수자', field: 'receptionMemberName', cellClass: 'cp center',width:90 },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    ngbDatepickerConfig.minDate = {year: 1900, month: 1, day: 1};
    ngbDatepickerConfig.maxDate = {year: 2099, month: 12, day: 31};
    ngbDatepickerConfig.displayMonths = 1;
    ngbDatepickerConfig.firstDayOfWeek = 7; // 일요일 먼저

    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    // 공통코드 리스트 가져오기
    await this.getCommonList();

    // 파라미터 가져오기
    await this.activatedRoute.queryParams.subscribe(params => {
      this.facilityCode = ( typeof params.fcode == 'undefined' || params.fcode == '' ) ? '' : params.fcode;
      this.useYn = ( typeof params.useType == 'undefined' || params.useType == '' ) ? '0' : params.useType;

      this.search.facilityCode = this.facilityCode;
      this.search.useYn = this.useYn;

      // 시설정보 리스트 가져오기
      if( this.facilityCode !== '' ) {
        this.getReceptionFacilityList(0);
        // this.getFacilityReceptionList( this.search.facilityCode );
        this.getFacilityReceptionList();
      }
    });

    window.fn = window.fn || {};
    window.fn.namespace = window.fn.namespace || {};
    window.fn.namespace.publicFunc = this.publicFunc.bind(this);
  }

  publicFunc() {
    this.ngZone.run(() => this.getFacilityReceptionList());
  }

  /*******************************************************************************
    설  명 : 뷰 처리 완료 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {

  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      //receptionSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
      //receptionEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
      receptionSdate: '',
      receptionEdate: '',
      searchField: 'inspectionSdate',
      //inspectionSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
      //inspectionEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
      inspectionSdate: '',
      inspectionEdate: '',
      searchField1: 'facilityName',
      searchText: '',
      officeCode: this.officeCd,
      regionCode: '',
      cityCode: [],
      paymentMemo: '',
      companyCode: '',
      facilityCode: '',
      memberType: '',
      useYn: '0'
    };

    this.facilityCode = '';

    this.select2List = [];

    this.getReceptionFacilityList(0);
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 시/도
    await this.regionService.getRegionSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.regionList = response.data;
      } else {
        this.regionList = [];
      }
    });

    // 사무소
    await this.commonCodeService.getOfficeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.officeList = response.data;
      } else {
        this.officeList = [];
      }
    });
    // 회원구분
    await this.commonCodeService.getMemberTypeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.memberTypeList = response.data;
      } else {
        this.memberTypeList = [];
      }
    });

    // 사무소코드
    await this.memberService.getMemberOffice().then( response => {
     // console.log(response.data['officeCd']);
      if( response.ResultCode ) {
        this.officeCd = response.data['officeCd'];
      } else {
        this.officeCd = '';
      }
    });
  }

  /*******************************************************************************
    설  명 : 시설정보 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getReceptionFacilityList( facilitySeq ) {
    // indicator 표시
    await setTimeout (() => {
      //if( this.detailList.length < 1 )
        this.globals.indicator.next(true);
    });

    await this.receptionService.getReceptionFacilityList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.clientList = response.data;

        let nodes = '';
        setTimeout(() => {
          nodes = this.gridApi.getSelectedNodes();

          if( nodes.length < 1 ) {
            let index = 0;
            this.gridApi.forEachNode(function(node) {
              if( facilitySeq !== 0 ) {
                if ( facilitySeq == node.data.facilitySeq ) {
                  node.setSelected(true);
                }
              } else {
                if( index++ == 0 ) {
                  node.setSelected(true);
                }
              }
            });
          }
        }, 500);

        if( this.facilityCode == '' && this.clientList.length > 0 ) {
          this.facilityCode = this.clientList[0].facilityCode;

          this.getFacilityReceptionList();
        } else {
          this.globals.indicator.next(false);
        }
      } else {
        this.toastrService.error( response.ResultMessage, '' );
        this.globals.indicator.next(false);
      }
    });
  }

  /*******************************************************************************
    설  명 : 시설별 검사내역 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getFacilityReceptionList() {
    let selectedRowIndex = this.gridApiDetail.getFocusedCell();
    console.log('선택 Row index 확인');
    console.log(selectedRowIndex);

    await this.receptionService.getFacilityReceptionList( this.facilityCode ).then( response => {
      if( response.ResultCode ) {
        this.detailList = response.data;

        setTimeout(() => {
          if (selectedRowIndex !== null ) {
            let index = 0;
            this.gridApiDetail.forEachNode(function(node) {
              if (index++ == selectedRowIndex.rowIndex ) {
                node.setSelected(true);
              }
            });

          }

        }, 500);

        this.facilityInfo = {};
        let index = 0;
        if (selectedRowIndex !== null ) {
          index = selectedRowIndex.rowIndex;
        }
        if( this.detailList.length > 0 ) {
          this.facilityInfo = this.detailList[index];
        }
        console.log(this.facilityInfo);

      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : 시/구/군 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCitySelect() {
    await this.regionService.getCitySelect( this.search.regionCode ).then( response => {
      //console.log(response);
      /*
      if( response.ResultCode ) {
        this.cityList = response.data;
      } else {
        this.cityList = [];
      }
      */
      if( response.ResultCode ) {
        this.cityList = [];
        response.data.forEach( row => {
          this.cityList.push({
            text: row.cityName,
            id: row.cityCode
          });
        });

        this.select2List = this.cityList;
      }
    });
  }

  /*******************************************************************************
    설  명 : 시설 정보 가져와서 그리드 선택된 ROW에 반영하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getFacilityInfo( facilitySeq ) {
    await this.companyfacilityService.getFacilityInfo( {facilitySeq: facilitySeq} ).then( response => {
      if( response.ResultCode ) {

        this.gridApi.forEachNode(function(rowNode, index) {
          if( rowNode.data.facilitySeq == facilitySeq ) {
            rowNode.setDataValue('facilityName', response.data.facilityName);
            rowNode.setDataValue('companyName', response.data.companyName);
          }
        });

      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid rowData 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDataChanged($event) {
    this.selectedRows = $event.api.gridOptionsWrapper.gridOptions.rowData.length;
  }
  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 시설별 검사내역 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    //console.log($event);
    this.facilityCode = $event.data.facilityCode;

    this.getFacilityReceptionList();
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 업체/시설 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClicked($event) {
    //console.log($event);
    this.addClient( $event.data.companySeq, $event.data.facilitySeq );
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyDetail(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid rowData 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDataChangedDetail($event) {
    this.selectedRowsDetail = $event.api.gridOptionsWrapper.gridOptions.rowData.length;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedDetail($event) {
    //console.log($event);
    this.facilityInfo = $event.data;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClickedDetail($event) {
    //console.log($event.data);
    this.openInspectInfo( $event.data.receptionSeq, $event.data.facilityCode, $event.data.usageCode );
  }

  /*******************************************************************************
    설  명 : 등록/수정
    입력값 : companySeq = 0 일 경우 등록, 이외에는 수정
    리턴값 : 없음
  *******************************************************************************/
  addClient( companySeq, facilitySeq ) {
    const modalRef = this.modalService.open(ModalFacilityInfoComponent, options);

    modalRef.componentInstance.companySeq = companySeq;
    modalRef.componentInstance.facilitySeq = facilitySeq;

    modalRef.result.then((result) => {
      if( result ) {
        //this.getReceptionFacilityList(facilitySeq); // 다시 로딩 시 스크롤이 맨 위로 가기때문에 사용안함
        this.getFacilityInfo(facilitySeq);
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 시설별 검사내역 등록/수정
    입력값 : receptionSeq = 0 일 경우 등록, 이외에는 수정
    리턴값 : 없음
  *******************************************************************************/
  addInspect( receptionSeq ) {
    let rowsSelection = this.gridApi.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '등록하기 위한 시설을 선택하시기 바랍니다.', '시설별 검사내역' );
      return false;
    }

    this.openInspectInfo( receptionSeq, rowsSelection[0].facilityCode, rowsSelection[0].usageCode );
  }

  /*******************************************************************************
    설  명 : 시설별 검사내역 등록/수정
    입력값 : receptionSeq = 0 일 경우 등록, 이외에는 수정
    리턴값 : 없음
  *******************************************************************************/
  openInspectInfo( receptionSeq, facilityCode, usageCode ) {
    /*
    const modalRef = this.modalService.open(InspectAddComponent, optionsInspect);

    modalRef.componentInstance.receptionSeq = receptionSeq;
    modalRef.componentInstance.facilityCode = facilityCode;
    modalRef.componentInstance.usageCode = usageCode;

    modalRef.result.then((result) => {
      //if( result ) {
        this.getFacilityReceptionList();
      //}
    }, (reason) => {
    });
    */

    if( !usageCode || usageCode == 'undefined' ) usageCode = '';

    let url = '/inspect/facility/add?receptionSeq='+receptionSeq+'&facilityCode='+facilityCode+'&usageCode='+usageCode;

    window.open(url, 'inspectFacilityAdd', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1250, height=880, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.facilityCode = '';

      this.getReceptionFacilityList(0);
    }
  }

  /*******************************************************************************
    설  명 : 검색 버튼 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setSearchList() {
    this.facilityCode = '';

    this.getReceptionFacilityList(0);
  }

  /*******************************************************************************
    설  명 : 시/도 선택 시
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setRegionChange() {
    this.search.cityCode = [];

    this.getCitySelect();
  }

  /*******************************************************************************
    설  명 : select2 변경 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changed( event: any ): void {
    //console.log(event);
    this.search.cityCode = event.value;
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: any ) {
    if( check == 1 ) {
      this.search.receptionSdate = this.utilService.getDate( this.calendar.getToday() );

    } else if( check == 2 ) {
      this.search.receptionEdate = this.utilService.getDate( this.calendar.getToday() );

    } else if( check == 3 ) {
      this.search.inspectionSdate = this.utilService.getDate( this.calendar.getToday() );

    } else if( check == 4 ) {
      this.search.inspectionEdate = this.utilService.getDate( this.calendar.getToday() );
    }

    obj.close();
  }

  /*******************************************************************************
    설  명 : 발행취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCancelCertificate() {
    let rowsSelection = this.gridApiDetail.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '발행취소할 내역을 선택하시기 바랍니다.', '시설별 검사내역' );
      return false;
    }

    let seqArray: any = [];
    let check: any = true;
    rowsSelection.forEach(item => {
      if( item.certificateStatus != '2' && item.certificateStatus != '3' ) check = false;

      seqArray.push({
        receptionSeq: item.receptionSeq,
        certificateStatus: item.certificateStatus
      });
    });

    if( check === false ) {
      this.toastrService.error( '발행상태가 없거나 미발행인 내역이 포함되어 있습니다.' );
      return false;
    } else {
      if( confirm("발행취소 하시겠습니까?") ) {
        this.receptionService.setCancelCertificate( seqArray ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.getFacilityReceptionList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 검사증명서 출력 (필증발행)
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openCertificate() {
    let rowsSelection = this.gridApiDetail.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '발행할 검사내역을 선택하시기 바랍니다.', '시설별 검사내역' );
      return false;
    }

    let seqArray: any = [];
    let check: any = true;
    let check2: any = true;
    let check3: any = true;
    let check4: any = true;
    rowsSelection.forEach(item => {
      if( item.passYn != '1' && item.passYn != true ) check = false;
      if( item.inspectionAgency != '4' && item.inspectionAgency != '2' ) check2 = false;
      if( item.memberNo == '0' && item.memberNo == null ) check3 = false;
      if( item.certificateNumber == '' && item.certificateNumber == null) check4 = false;

      seqArray.push( item.receptionSeq );
    });

    if( check === false ) {
      this.toastrService.error( '검사결과가 합격이 아닌 내역이 있습니다.' );
      return false;
    } else if( check2 === false ) {
      this.toastrService.error( '안전원 및  협회 검사가 아닌 내역이 있습니다.' );
      return false;
    } else if( check3 === false ) {
      this.toastrService.error( '검사원이 지정되지 않은 내역이 있습니다.' );
      return false;
    } else if( check4 === false ) {
      this.toastrService.error( '필증번호가 부여되지 않은 내역이 있습니다.' );
      return false;
    } else {
      let params = JSON.stringify( seqArray );
      sessionStorage.setItem( 'booksInspectPrintCertificate1', params );

      let url = '/books/inspect/print/certificate1';

      window.open(url, 'booksInspectPrintCertificate1', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
    }
  }

  /*******************************************************************************
    설  명 : 현장검사표 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openChecklist() {
    let url = '/inspect/facility/print/checklist';

    window.open(url, 'inspectFacilityPrintChecklist', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : 접수증 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openReceipt() {
    let rowsSelection = this.gridApiDetail.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '출력할 검사내역을 선택하시기 바랍니다.', '시설별 검사내역' );
      return false;
    }

    let tmp: any = [];
    let check: any = true;
    rowsSelection.forEach(item => {
      if( item.receptionNumber == '' || item.receptionNumber == false ) check = false;

      tmp.push({
        seq: item.receptionSeq,
        code: item.facilityCode
      });
    });

//    if( check === false ) {
//      this.toastrService.error( '선택한 것 중에서 검사 접수되지 않은 내역이 있습니다.', '시설별 검사내역' );
//      return false;
//    } else {
      let params = JSON.stringify( tmp );
      sessionStorage.setItem( 'inspectFacilityPrintReceipt', params );

      let url = '/inspect/facility/print/receipt';

      window.open(url, 'inspectFacilityPrintReceipt', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
//    }
  }

  /*******************************************************************************
    설  명 : 대체처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSubstitutionProcess() {
    let rowsSelection = this.gridApiDetail.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '처리할 검사내역을 선택하시기 바랍니다.', '시설별 검사내역' );
      return false;
    }

    if( confirm("대체처리 하시겠습니까?") ) {
      this.receptionService.setSubstitutionProcess( rowsSelection[0].receptionSeq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.getFacilityReceptionList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

}
