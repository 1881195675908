const baseURL = 'https://fms.krasi.or.kr';
//const baseURL = (window.location.hostname === 'localhost') ? 'http://localhost:8097' : 'http://' + window.location.hostname;
const hrmsURL = 'https://hrms.krasi.or.kr';

export const config = {
  production: false,
  baseUrl: baseURL,
  hrmsUrl: hrmsURL,
  apiBaseUrl: baseURL + "/api",
  apiFileUploadUrl: baseURL + "/api/file/file_upload.php",
  apiImageUploadUrl: baseURL + "/api/file/image_upload.php",
  cookie_token: 'fmstoken'
};
