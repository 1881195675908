import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})

export class ReceptionService {

  constructor(
    private restful: RestfulService
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.log(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 시설정보 리스트 가져오기
  getReceptionFacilityList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getReceptionFacilityList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시설별 검사내역 리스트 가져오기
  getFacilityReceptionList( facilityCode ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getFacilityReceptionList'
      }, {
        facilityCode: facilityCode
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시설별 검사내역 상세정보 가져오기
  getFacilityReceptionInfo( receptionSeq ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getFacilityReceptionInfo'
      }, {
        receptionSeq: receptionSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 접수증 출력 데이터 가져오기
  getPrintReceiptList( params: any ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getPrintReceiptList'
      }, {
        params: params
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }


  // 상담이력 리스트 가져오기
  getCounselingList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getCounselingList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사이력 리스트 가져오기
  getReceptionList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getReceptionList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 지로출력 상세정보 가져오기
  getReceptionGiroInfo( seqArray ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getReceptionGiroInfo'
      }, {
        seqArray: seqArray
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // DM출력 상세정보 가져오기
  getReceptionDmList( seqArray ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getReceptionDmList'
      }, {
        seqArray: seqArray
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 비고 수정
  setReceptionNoninspectionReason( form: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'setReceptionNoninspectionReason'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 차기 검사 정보 조회
  getNextInspectionInfo( usageCode, completeDate, inspectionType ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getNextInspectionInfo'
      }, {
        usageCode: usageCode,
        completeDate: completeDate,
        inspectionType: inspectionType,
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 발행취소
  setCancelCertificate( seqArray ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'setCancelCertificate'
      }, {
        seqArray: seqArray
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사표 조회 - 다중
  getInspectionReport( seqArray ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getInspectionReport'
      }, {
        seqArray: seqArray
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사표 조회
  getInspectionReportNew( receptionSeq ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getInspectionReportNew'
      }, {
        receptionSeq: receptionSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시설검사관리 - 상담이력 조회
  getReceptionCounselingList( receptionSeq ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getReceptionCounselingList'
      }, {
        receptionSeq: receptionSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시설검사관리 - 상담이력 상세정보
  getReceptionCounselingInfo( counselingSeq ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getReceptionCounselingInfo'
      }, {
        counselingSeq: counselingSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사표 생성
  setInspectionReportCreate( receptionSeq, facilityCode ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'setInspectionReportCreate'
      }, {
        receptionSeq: receptionSeq,
        facilityCode: facilityCode
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사표 일괄 저장
  setInspectionReport( params: any ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'setInspectionReport'
      }, {
        params: params
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상담 등록/수정
  setCounseling( form: any ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'setCounseling'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 상담 삭제
  setCounselingDelete( counselingSeq ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'setCounselingDelete'
      }, {
        counselingSeq: counselingSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사 접수 등록/수정
  setFacilityReception( form: any ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'setFacilityReception'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사접수 등록 - 접수번호 가져오기
  getReceptionNumber( facilityCode ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getReceptionNumber'
      }, {
        facilityCode: facilityCode
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사접수 등록 - 필증번호 가져오기
  getCertificateNumber( facilityCode, inspectionDate ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getCertificateNumber'
      }, {
        facilityCode: facilityCode,
        inspectionDate: inspectionDate
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 지로 최종생성년월 가져오기
  getGiroLastMonth(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getGiroLastMonth'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 지로 생성
  setGiroCreate( createMonth ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'setGiroCreate'
      }, {
        createMonth: createMonth
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 일자 변경
  setChangeDate( form: any ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'setChangeDate'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 필증 발급일 가져오기
  getCertificateDate( inspectionDate ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'getCertificateDate'
      }, {
        inspectionDate: inspectionDate
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사 접수 삭제하기
  setFacilityReceptionDelete( receptionSeq ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'setFacilityReceptionDelete'
      }, {
        receptionSeq: receptionSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 대체처리
  setSubstitutionProcess( receptionSeq ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'reception',
        version: '1.0',
        action: 'setSubstitutionProcess'
      }, {
        receptionSeq: receptionSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
