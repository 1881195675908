import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  constructor(
    private restful: RestfulService
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.log(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 메인 통계 가져오기
  getMainBusiness(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'dashboard',
        version: '1.0',
        action: 'getMainBusiness'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 캘린더 정보 가져오기
  getCalendar( year, month ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'dashboard',
        version: '1.0',
        action: 'getCalendar'
      }, {
        year: year,
        month: month
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 접수내역 리스트 가져오기
  getReceptionListDashboard( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'dashboard',
        version: '1.0',
        action: 'getReceptionListDashboard'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
