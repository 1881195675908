import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ReportService } from '@app/service/report.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-print-books-unfitness',
  templateUrl: './print-books-unfitness.component.html',
  styleUrls: ['./print-books-unfitness.component.scss']
})
export class PrintBooksUnfitnessComponent implements OnInit {

  private seqArray: any;

  public reportList: any = [];

  public year: any = moment().format('YYYY');
  public month: any = moment().format('MM');
  public day: any = moment().format('DD');

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private reportService: ReportService,
  ) { }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  ngOnInit() {
    // 파라미터 가져오기
    this.seqArray = sessionStorage.getItem( 'booksInspectPrintUnfitness' );
    sessionStorage.removeItem( 'booksInspectPrintUnfitness' );

    this.getRejectReport();
  }

  /*******************************************************************************
    설  명 : view 로딩 후 생성자
  *******************************************************************************/
  ngAfterViewInit() {
    var data = $(".table tbody").children();
    setTimeout (() => {
      if( typeof data[0] !== undefined ) {
        window.print();
      }
    }, 500);
  }

  /*******************************************************************************
    설  명 : 검사부적합통지서 정보 가져오기
  *******************************************************************************/
  getRejectReport() {
    this.reportService.getRejectReport( this.seqArray ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.reportList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

}
