/*******************************************************************************
  설  명 : 수수료 추가/수정
  작성일 : 2020-02-26
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { CommonService } from '@app/service/common.service';
import { Globals } from '@app/service/globals.service';
import { InspectFeeService } from '@app/service/inspect.fee.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-fee-detail-add',
  templateUrl: './fee-detail-add.component.html',
  styleUrls: ['./fee-detail-add.component.scss']
})
export class FeeDetailAddComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역 변수
  *******************************************************************************/
  public inspectFeeSeq: any;
  public memberType: any;
  public registDate: any;

  title: any = '추가';

  public form: FormGroup;
  public formErrors = {};

  public setFeeClacFunc: any;     // 예정일 변경 시 실행

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      inspectFeeSeq:["", [] ],
      memberType:["", [Validators.required] ],
      registDate:["", [Validators.required] ],
      freezeCapacityText:["", [Validators.required] ],
      freezeCapacityMax:["", [Validators.required, CustomValidator.numeric] ],
      freezeCapacityMin:["", [Validators.required, CustomValidator.numeric] ],
      memberFee:["", [Validators.required, CustomValidator.numeric] ],
      memberVat:["", [Validators.required, CustomValidator.numeric] ],
      feeTotal:["", [Validators.required, CustomValidator.numeric] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private globals: Globals,
    private modalService: NgbModal,
    private inspectFeeService: InspectFeeService,
    public activeModal: NgbActiveModal,
  ) {
    this.buildForm();

    this.setFeeClacFunc = this.setFeeClac.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.form.patchValue({
      inspectFeeSeq: this.inspectFeeSeq,
      memberType: this.memberType,
      registDate: this.registDate
    });

    if( this.inspectFeeSeq != 0 ) {
      this.title = '수정';
      this.getInspectFeeInfo();
    }
  }

  /*******************************************************************************
    설  명 : 수수료 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getInspectFeeInfo() {
    await this.inspectFeeService.getInspectFeeInfo( this.inspectFeeSeq ).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue( response.data );
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 예정일 변경 시 검사일도 동일하게 바인딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setFeeClac() {
    if( !this.form.controls.memberFee.value || this.form.controls.memberFee.value == false ) {
      this.toastrService.error( '수수료(공급가액)를 입력하시기 바랍니다.', '' );
    } else {
      // 부가세, 합계 변경
      this.form.patchValue({
        memberVat: parseInt(this.form.controls.memberFee.value) * 0.1,
        feeTotal: parseInt(this.form.controls.memberFee.value) + ( parseInt(this.form.controls.memberFee.value) * 0.1 )
      });
    }
  }

  /*******************************************************************************
    설  명 : 수수료적용일 복사 등록하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setInspectFee() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.inspectFeeService.setInspectFee( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 수수료 정보 삭제하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setInspectFeeDelete() {
    if( confirm("해당 수수료 정보를 삭제하시겠습니까?") ) {
      this.inspectFeeService.setInspectFeeDelete( this.inspectFeeSeq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');

        this.activeModal.close();
      });
    }
  }
}
