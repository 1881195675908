/*******************************************************************************
  설  명 : 시설검사대행실정
  작성일 : 2020-02-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbCalendar, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { Globals } from '@app/service/globals.service';
import { MemberService } from '@app/service/member.service';
import { RegionService } from '@app/service/region.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { ReportService } from '@app/service/report.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-books-performance',
  templateUrl: './books-performance.component.html',
  styleUrls: ['./books-performance.component.scss']
})
export class BooksPerformanceComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  tabIndex: any = 0;

  // 검색 필드
  public search = {
    inspectionYear: moment().format('YYYY'),
    inspectionType: '',
    regionCode: '',
    officeCode: '',
  }

  officeCd:string;

  // 그리드 관련 선언
  selectedRows1: number = 0;
  selectedRows2: number = 0;
  selectedRows3: number = 0;
  yearList: any = [];
  regionList: any = [];
  cityList: any = [];
  officeList: any = [];
  kgsList: any = [];

  gridApi1: any;
  gridApi2: any;
  gridApi3: any;
  gridColumnApi1: any;
  gridColumnApi2: any;
  gridColumnApi3: any;
  columnDefs1: any;
  columnDefs2: any;
  columnDefs3: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private memberService: MemberService,
    private regionService: RegionService,
    private commonCodeService: CommonCodeService,
    private reportService: ReportService,
    private calendar: NgbCalendar,
    private globals: Globals
  ) {
    // ag grid 컬럼 선언
    this.columnDefs1 = [
      { headerName: '보고기관', field: 'reportingAgency', cellClass: 'cp center',width:120 },
      { headerName: '시/도', field: 'regionName', cellClass: 'cp center',width:120 },
      { headerName: '1월', field: 'januaryPass', cellClass: 'cp center' ,width:90, 
        valueGetter: function(params) {
          return params.data.januaryPass + " / " + params.data.januaryNoPass;
        }
      },
      { headerName: '2월', field: 'februaryPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.februaryPass + " / " + params.data.februaryNoPass;
        }
      },
      { headerName: '3월', field: 'marchPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.marchPass + " / " + params.data.marchNoPass;
        }
      },
      { headerName: '4월', field: 'aprilPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.aprilPass + " / " + params.data.aprilNoPass;
        }
      },
      { headerName: '5월', field: 'mayPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.mayPass + " / " + params.data.mayNoPass;
        }
      },
      { headerName: '6월', field: 'junePass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.junePass + " / " + params.data.juneNoPass;
        }
      },
      { headerName: '7월', field: 'julyPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.julyPass + " / " + params.data.julyNoPass;
        }
      },
      { headerName: '8월', field: 'augustPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.augustPass + " / " + params.data.augustNoPass;
        }
      },
      { headerName: '9월', field: 'septemberPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.septemberPass + " / " + params.data.septemberNoPass;
        }
      },
      { headerName: '10월', field: 'octoberPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.octoberPass + " / " + params.data.octoberNoPass;
        }
      },
      { headerName: '11월', field: 'novemberPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.novemberPass + " / " + params.data.novemberNoPass;
        }
      },
      { headerName: '12월', field: 'decemberPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.decemberPass + " / " + params.data.decemberNoPass;
        }
      },
      { headerName: '합계', field: 'Pass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.Pass + " / " + params.data.NoPass;
        }
      },
    ];

    this.columnDefs2 = [
      { headerName: '시/도', field: 'regionName', cellClass: 'cp center',width:100 },
      { headerName: '구/군', field: 'cityName', cellClass: 'cp center' ,width:120},
      { headerName: '1월', field: 'januaryPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.januaryPass + " / " + params.data.januaryNoPass;
        }
      },
      { headerName: '2월', field: 'februaryPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.februaryPass + " / " + params.data.februaryNoPass;
        }
      },
      { headerName: '3월', field: 'marchPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.marchPass + " / " + params.data.marchNoPass;
        }
      },
      { headerName: '4월', field: 'aprilPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.aprilPass + " / " + params.data.aprilNoPass;
        }
      },
      { headerName: '5월', field: 'mayPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.mayPass + " / " + params.data.mayNoPass;
        }
      },
      { headerName: '6월', field: 'junePass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.junePass + " / " + params.data.juneNoPass;
        }
      },
      { headerName: '7월', field: 'julyPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.julyPass + " / " + params.data.julyNoPass;
        }
      },
      { headerName: '8월', field: 'augustPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.augustPass + " / " + params.data.augustNoPass;
        }
      },
      { headerName: '9월', field: 'septemberPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.septemberPass + " / " + params.data.septemberNoPass;
        }
      },
      { headerName: '10월', field: 'octoberPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.octoberPass + " / " + params.data.octoberNoPass;
        }
      },
      { headerName: '11월', field: 'novemberPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.novemberPass + " / " + params.data.novemberNoPass;
        }
      },
      { headerName: '12월', field: 'decemberPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.decemberPass + " / " + params.data.decemberNoPass;
        }
      },
      { headerName: '합계', field: 'Pass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.Pass + " / " + params.data.NoPass;
        }
      },
    ];
    
    this.columnDefs3 = [
      { headerName: '보고기관', field: 'reportingAgency', cellClass: 'cp center',width:120},
      { headerName: '지역본부', field: 'branchyName', cellClass: 'cp center' ,width:180},
      { headerName: '1월', field: 'januaryPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.januaryPass + " / " + params.data.januaryNoPass;
        }
      },
      { headerName: '2월', field: 'februaryPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.februaryPass + " / " + params.data.februaryNoPass;
        }
      },
      { headerName: '3월', field: 'marchPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.marchPass + " / " + params.data.marchNoPass;
        }
      },
      { headerName: '4월', field: 'aprilPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.aprilPass + " / " + params.data.aprilNoPass;
        }
      },
      { headerName: '5월', field: 'mayPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.mayPass + " / " + params.data.mayNoPass;
        }
      },
      { headerName: '6월', field: 'junePass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.junePass + " / " + params.data.juneNoPass;
        }
      },
      { headerName: '7월', field: 'julyPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.julyPass + " / " + params.data.julyNoPass;
        }
      },
      { headerName: '8월', field: 'augustPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.augustPass + " / " + params.data.augustNoPass;
        }
      },
      { headerName: '9월', field: 'septemberPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.septemberPass + " / " + params.data.septemberNoPass;
        }
      },
      { headerName: '10월', field: 'octoberPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.octoberPass + " / " + params.data.octoberNoPass;
        }
      },
      { headerName: '11월', field: 'novemberPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.novemberPass + " / " + params.data.novemberNoPass;
        }
      },
      { headerName: '12월', field: 'decemberPass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.decemberPass + " / " + params.data.decemberNoPass;
        }
      },
      { headerName: '합계', field: 'Pass', cellClass: 'cp center',width:90, 
        valueGetter: function(params) {
          return params.data.Pass + " / " + params.data.NoPass;
        }
      },
    ];    

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    let startYear: any = moment().subtract(10, 'years').format('YYYY');
    let endYear: any = moment().add(10, 'years').format('YYYY');
    for(var year = startYear; year <= endYear; year++) {
      this.yearList.push(year);
    }

    // 공통코드 리스트 가져오기
    this.getCommonList();
    
  }

  /*******************************************************************************
    설  명 : 뷰 처리 완료 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {

    // indicator 표시
    setTimeout (() => {
//      if( this.regionList.length < 1 )
 //       this.globals.indicator.next(true);
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      inspectionYear: moment().format('YYYY'),
      inspectionType: '',
      regionCode: '',
      officeCode: this.officeCd,
    }

    this.getInspectionPerformanceRegion();
    this.getInspectionPerformanceCity();
    this.getInspectionPerformanceKgs();
  }

  /*******************************************************************************
    설  명 : 검색 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearch() {
    this.globals.indicator.next(true);
    this.getInspectionPerformanceRegion();
    this.getInspectionPerformanceCity();
    this.getInspectionPerformanceKgs();
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 시/도
    await this.regionService.getRegionSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.regionList = response.data;
      } else {
        this.regionList = [];
      }
    });

    // 사무소
    await this.commonCodeService.getOfficeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.officeList = response.data;
      } else {
        this.officeList = [];
      }
    });
    // 사무소코드
    await this.memberService.getMemberOffice().then( response => {
     // console.log(response.data['officeCd']);
      if( response.ResultCode ) {
        this.officeCd = response.data['officeCd'];
      } else {
        this.officeCd = '';
      }
    });
    
    this.searchInit(); 

  }

  /*******************************************************************************
    설  명 : 시설검사대행 시도별실적 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getInspectionPerformanceRegion() {
    await this.reportService.getInspectionPerformanceRegion( this.search ).then( response => {
      if( response.ResultCode ) {
        this.regionList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : 시설검사대행 구군별실적 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getInspectionPerformanceCity() {
    await this.reportService.getInspectionPerformanceCity( this.search ).then( response => {
      if( response.ResultCode ) {
        this.cityList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : 시설검사대행 안전공사지사별 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getInspectionPerformanceKgs() {
    await this.reportService.getInspectionPerformanceKgs( this.search ).then( response => {
      if( response.ResultCode ) {
        this.kgsList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady1(params) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady2(params) {
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady3(params) {
    this.gridApi3 = params.api;
    this.gridColumnApi3 = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged1($event) {
    let filteredRowCount = 0;
    this.gridApi1.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows1 = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged2($event) {
    let filteredRowCount = 0;
    this.gridApi2.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows2 = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged3($event) {
    let filteredRowCount = 0;
    this.gridApi3.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows3 = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {

  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getInspectionPerformanceRegion();
      this.getInspectionPerformanceCity();
      this.getInspectionPerformanceKgs();
    }
  }

}
