import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ReceptionService } from '@app/service/reception.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-print-books-inspect',
  templateUrl: './print-books-inspect.component.html',
  styleUrls: ['./print-books-inspect.component.scss']
})
export class PrintBooksInspectComponent implements OnInit {

  public seqArray: any;

  public reportList: any = [];

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private receptionService: ReceptionService,
  ) { }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  ngOnInit() {
    // 파라미터 가져오기
    this.seqArray = sessionStorage.getItem( 'booksInspectPrintInspect' );
    sessionStorage.removeItem( 'booksInspectPrintInspect' );

    this.getInspectionReport();
  }

  /*******************************************************************************
    설  명 : view 로딩 후 생성자
  *******************************************************************************/
  ngAfterViewInit() {
    var data = $(".table tbody").children();
    setTimeout (() => {
      if( typeof data[0] !== undefined ) {
        window.print();
      }
    }, 500);
  }

  /*******************************************************************************
    설  명 : 검사표 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getInspectionReport() {
    await this.receptionService.getInspectionReport( this.seqArray ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.reportList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

}
