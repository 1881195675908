/*******************************************************************************
  설  명 : 검사지역정보관리
  작성일 : 2020-02-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { CommonService } from '@app/service/common.service';
import { Globals } from '@app/service/globals.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { RegionService } from '@app/service/region.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

import { RegionAddComponent } from '@app/page/basic/region/region-add/region-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

let inspectableList: any = [];

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss']
})
export class RegionComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 검색 필드
  public search = {
    regionSeq: '',
    regionCode: '',
    regionName: '',
    citySeq: '',
    cityCode: '',
    cityName: ''
  };

  // 그리드 관련 선언
  selectedRows: number = 0;
  
  regionList: any = [];
  cityList: any = [];
  usageList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiCity: any;
  gridColumnApiCity: any;
  columnDefsCity: any;

  gridApiInspectable: any;
  gridColumnApiInspectable: any;
  columnDefsInspectable: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private commonService: CommonService,
    private globals: Globals,
    private commonCodeService: CommonCodeService,
    private regionService: RegionService,
  ) {

  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    // 공통코드 가져오기
    await this.getCommonList();
    
    // 그리드 초기화
    await this.setGridInit();

    // 시/도 리스트 가져오기
    await this.getRegionList();
  }

  /*******************************************************************************
    설  명 : 뷰 처리 완료 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {

    // indicator 표시
    setTimeout (() => {
      if( this.regionList.length < 1 )
        this.globals.indicator.next(true);
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 회원구분
    //inspectableList = [];
    await this.commonCodeService.getInspectableSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        inspectableList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 그리드 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async setGridInit() {

    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '순번', field: 'regionSeq', cellClass: 'cp', hide: true },
      {headerName: '코드', field: 'regionCode', cellClass: 'cp', hide: true },
      {headerName: '시/도', field: 'regionName', cellClass: 'cp', checkboxSelection: true },
    ];

    // ag grid 컬럼 선언
    this.columnDefsCity = [
      {headerName: '순번', field: 'citySeq', cellClass: 'cp', hide: true },
      {headerName: '코드', field: 'cityCode', cellClass: 'cp', hide: true },
      {headerName: '구/군', field: 'cityName', cellClass: 'cp', checkboxSelection: true, width:200 },
      {headerName: '사무소', field: 'officeName', cellClass: 'cp', width:100 },
      {headerName: '검사원', field: 'memberName', cellClass: 'cp', width:100 },
      {headerName: '관리지사', field: 'managerBranchName', cellClass: 'cp', width:200 },
    ];
/*
    var actionCellRenderer = function () { };
    actionCellRenderer.prototype = {
      init: function (params) {
        this.select = document.createElement('div');
        this.option = '';

        inspectableList.forEach(item => {
          if( params.value == item.commonName )
            this.option += '<option value="'+item.commonCode+'" selected="selected">'+item.commonName+'</option>"';
          else
            this.option += '<option value="'+item.commonCode+'">'+item.commonName+'</option>"';
        });

        this.select.innerHTML = '<select class="form-control form-control-small">'+this.option+'</select>';
      },
      getGui: function () {
        return this.select;
      },
      refresh: function () {
        return true;
      },
      destroy: function () {
        $(this.select).remove();
      },
    }
*/
    var inspectable = [];
    if( inspectableList.length > 0 ) {
      inspectableList.forEach(item => {
        inspectable.push(item.commonName);
      });
    }

    // ag grid 컬럼 선언
    this.columnDefsInspectable = [
      { headerName: '검사가능여부순번', field: 'inspectableSeq', cellClass: 'cp', hide: true },
      { headerName: '검사가능여부코드', field: 'inspectableCode', cellClass: 'cp', hide: true },
      { headerName: '용도코드', field: 'usageCode', cellClass: 'cp' , width:100},
      { headerName: '용도명', field: 'usageName', cellClass: 'cp' , width:150},
      { headerName: '검사가능여부', field: 'InspectableName', cellClass: 'cp', width:250, 
        //cellRenderer: actionCellRenderer
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellRenderer: this.getRenderer(),
        /*
        cellRenderer: function (data: any) {
          var color = inspectableList.find(item => item.commonName == data.value);

          return color.commonName;
        },
        */
        onCellValueChanged: function (data: any) {
          //console.log(data);
          var InspectableName = data.data.InspectableName;
          data.data.inspectableCode = inspectableList.find(item => item.commonName == InspectableName).commonCode;
        },
        cellEditorParams: {
          values: inspectable
        }

      },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : cellRenderer 커스텀
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRenderer() {
    function CellRenderer() {}
    CellRenderer.prototype.createGui = function() {
      var template =
        '<span><button id="theButton" class="btn btn-default f12" style="padding: 0 5px 0 5px !important; vertical-align: text-bottom !important;">수정</button><span id="theValue" style="padding-left: 4px;"></span></span>';
      var tempDiv = document.createElement("div");
      tempDiv.innerHTML = template;
      this.eGui = tempDiv.firstElementChild;
    };
    CellRenderer.prototype.init = function(params) {
      this.createGui();
      this.params = params;
      var eValue = this.eGui.querySelector("#theValue");
      eValue.innerHTML = params.value;
      this.eButton = this.eGui.querySelector("#theButton");
      this.buttonClickListener = this.onButtonClicked.bind(this);
      this.eButton.addEventListener("click", this.buttonClickListener);
    };
    CellRenderer.prototype.onButtonClicked = function() {
      var startEditingParams = {
        rowIndex: this.params.rowIndex,
        colKey: this.params.column.getId()
      };
      this.params.api.startEditingCell(startEditingParams);
    };
    CellRenderer.prototype.getGui = function() {
      return this.eGui;
    };
    CellRenderer.prototype.destroy = function() {
      this.eButton.removeEventListener("click", this.buttonClickListener);
    };
    return CellRenderer;
  }

  /*******************************************************************************
    설  명 : 시/도 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getRegionList() {
    this.regionList = [];

    await this.regionService.getRegionList().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.regionList = response.data;

        if( this.regionList.length > 0 ) {
          this.search.regionSeq = this.regionList[0].regionSeq;
          this.search.regionCode = this.regionList[0].regionCode;
          this.search.regionName = this.regionList[0].regionName;

          this.getCityList();
        }
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : 구/군 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCityList() {
    this.cityList = [];
    this.usageList = [];

    this.search.citySeq = '';
    this.search.cityCode = '';
    this.search.cityName = '';

    await this.regionService.getCityList( this.search.regionCode ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.cityList = response.data;
        
        if( this.cityList.length > 0 ) {
          this.search.citySeq = this.cityList[0].citySeq;
          this.search.cityCode = this.cityList[0].cityCode;
          this.search.cityName = this.cityList[0].cityName;

          this.getRegionInspectableList();
        }
      }
    });
  }

  /*******************************************************************************
    설  명 : 구/군 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getRegionInspectableList() {
    this.usageList = [];

    await this.regionService.getRegionInspectableList( this.search ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.usageList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      regionSeq: '',
      regionCode: '',
      regionName: '',
      citySeq: '',
      cityCode: '',
      cityName: ''
    };

    this.getRegionList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.search.regionSeq = $event.data.regionSeq;
    this.search.regionCode = $event.data.regionCode;
    this.search.regionName = $event.data.regionName;

    this.getCityList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyCity(params) {
    this.gridApiCity = params.api;
    this.gridColumnApiCity = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChangedCity($event) {
    let filteredRowCount = 0;
    this.gridApiCity.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedCity($event) {
    this.search.citySeq = $event.data.citySeq;
    this.search.cityCode = $event.data.cityCode;
    this.search.cityName = $event.data.cityName;

    this.getRegionInspectableList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyInspectable(params) {
    this.gridApiInspectable = params.api;
    this.gridColumnApiInspectable = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChangedInspectable($event) {
    let filteredRowCount = 0;
    this.gridApiInspectable.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 구/군 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addRegion() {
    const modalRef = this.modalService.open(RegionAddComponent, options);

    modalRef.componentInstance.regionSeq = this.search.regionSeq;
    modalRef.componentInstance.regionCode = this.search.regionCode;
    modalRef.componentInstance.regionName = this.search.regionName;
    modalRef.componentInstance.citySeq = this.search.citySeq;
    modalRef.componentInstance.cityCode = this.search.cityCode;
    modalRef.componentInstance.cityName = this.search.cityName;

    modalRef.result.then((result) => {
      if( result ) {
        this.getCityList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getRegionList();
    }
  }

  /*******************************************************************************
    설  명 : 용도별 검사가능여부 일괄등록
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setRegionInspectableInfoBatch() {
    if( confirm("일괄등록 하시겠습니까?") ) {
      if( !this.search.regionCode || this.search.regionCode == '' ) {
        this.toastrService.error( '시/도를 선택하시기 바랍니다.', '');
        return false;
      }

      if( !this.search.cityCode || this.search.cityCode == '' ) {
        this.toastrService.error( '구/군을 선택하시기 바랍니다.', '');
        return false;
      }

      this.regionService.setRegionInspectableInfoBatch( this.search ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getRegionInspectableList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }
  }

  /*******************************************************************************
    설  명 : 용도별 검사가능여부 일괄저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setRegionInspectableBatch() {
    if( confirm("검사가능여부를 일괄저장 하시겠습니까?") ) {
      let gridData = this.gridApiInspectable.gridOptionsWrapper.gridOptions.rowData;

      if( gridData.length < 1 ) {
        this.toastrService.error( '저장할 데이터가 없습니다.', '');
        return false;
      }

      this.regionService.setRegionInspectableBatch( gridData ).then( response => {
        //console.log(response);
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getRegionInspectableList();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }
  }

}
