/*******************************************************************************
  설  명 : 시설검사관리 - 상담이력
  작성일 : 2020-02-26
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbCalendar, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { ReceptionService } from '@app/service/reception.service';
import { Globals } from '@app/service/globals.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

import { ModalCounselingAddComponent } from '@app/components/modal-counseling-add/modal-counseling-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-counseling',
  templateUrl: './counseling.component.html',
  styleUrls: ['./counseling.component.scss']
})
export class CounselingComponent implements OnInit {

   /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() data: any;

  // 그리드 관련 선언
  selectedRows: number = 0;
  dataList: any = [];

  gridApiCounseling: any;
  gridColumnApiCounseling: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private receptionService: ReceptionService,
    private globals: Globals,
    private calendar: NgbCalendar
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '순번', field: 'counselingSeq', cellClass: 'cp center',hide:true },
      {headerName: '순번', field: 'counselingSeq', cellClass: 'cp center', width:80 },
      {headerName: '상담일자', field: 'counselingDate', cellClass: 'cp center', width:90 },
      {headerName: '상담구분', field: 'counselingTypeName', cellClass: 'cp center' , width:100},
      {headerName: '상담자', field: 'memberName', cellClass: 'cp center', width:90 },
      {headerName: '상담내용', field: 'counselingMemo', cellClass: 'cp', width:200},
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {

  }

  ngOnChanges() {
    // 상담이력 리스트 가져오기
    if( this.data.receptionSeq != null ) {
      this.getReceptionCounselingList();
    }
  }

  /*******************************************************************************
    설  명 : 상담이력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getReceptionCounselingList() {
    await this.receptionService.getReceptionCounselingList( this.data.receptionSeq ).then( response => {
      if( response.ResultCode ) {
        this.dataList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApiCounseling = params.api;
    this.gridColumnApiCounseling = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApiCounseling.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 더블클릭 시 처리 - 상담 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClicked($event) {
    this.addCounseling( $event.data.counselingSeq );
  }

  /*******************************************************************************
    설  명 : 상담 등록/수정
    입력값 : counselingSeq = 0 일 경우 등로5, 이외에는 수정
    리턴값 : 없음
  *******************************************************************************/
  addCounseling( counselingSeq ) {
    const modalRef = this.modalService.open(ModalCounselingAddComponent, options);

    modalRef.componentInstance.counselingSeq = counselingSeq;
    modalRef.componentInstance.receptionSeq = this.data.receptionSeq;
    modalRef.componentInstance.facilityCode = this.data.facilityCode;

    modalRef.result.then((result) => {
      if( result ) {
        this.getReceptionCounselingList();
      }
    }, (reason) => {
    });
  }

}
