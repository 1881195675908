import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-books-certificate',
  templateUrl: './print-books-certificate.component.html',
  styleUrls: ['./print-books-certificate.component.scss']
})
export class PrintBooksCertificateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
