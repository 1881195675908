/*******************************************************************************
  설  명 : 앱 설정
  작성일 : 2020-02-24
  작성자 : 송영석
*******************************************************************************/
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public checkLoginPage:boolean = false;    // 관리자 로그인 페이지 여부
  public checkPrintPage:boolean = false;    // 출력 페이지 여부

  public checkPopupPage:boolean = false;    // 새창 페이지 여부

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public location: Location,
    public globals: Globals,
    private authService: AuthService
  ) {
    // 로그인 여부 체크
    let loginCheck$: any = this.authService.getIsLogin;

    loginCheck$.subscribe( check => {
      this.checkLoginPage = ! check;
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public ngOnInit() {

    if( this.location.path().indexOf('/print') > -1 || this.location.path().indexOf('/facility/add') > -1 ) {
      this.checkPrintPage = true;
    }

    if( this.location.path().indexOf('/inspect/facility/add') > -1 ) {
      this.checkPopupPage = true;
    }

  }

}
