/*******************************************************************************
  설명 : 관리자 헤더
  작성자 : 송영석
  작성일 : 2019-08-24
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {
  Router, ActivatedRoute
} from '@angular/router';

import * as $ from 'jquery';

import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { CommonService } from '@app/service/common.service';

/*******************************************************************************
  컴포넌트 클래스 선언부
*******************************************************************************/
@Component({
  selector: 'app-agnb',
  templateUrl: './gnb.component.html',
  styleUrls: ['./gnb.component.scss']
})
export class GnbComponent implements OnInit {

/*******************************************************************************
  전역변수 선언
*******************************************************************************/
  // 메뉴 리스트
  public menuList: any = [];

/*******************************************************************************
  클래스 생성자
*******************************************************************************/
  constructor(
    private commonService: CommonService,
    private utilService: UtilService,
    private authService: AuthService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    public location: Location
  ) { }

/*******************************************************************************
  설명 : angular 데이터 초기화
  입력값 : 없음
  리턴값 : 없음
*******************************************************************************/
  ngOnInit() {
    this.getMenuList();
  }

/*******************************************************************************
  설  명 : 관리자 메뉴 리스트 가져오기
  입력값 : 없음
  리턴값 : 없음
*******************************************************************************/
  getMenuList() {
    this.commonService.menuList = [];
    this.commonService.getMenuList().then(response => {
      this.menuList = this.commonService.menuList;
    })
    .catch(response => {
      this.utilService.handleFormSubmitError(response, null, null);
    });
  }

/*******************************************************************************
  설  명 : 메인메뉴 중 서브메뉴 열림 닫힘 처리
  입력값 : $event
  리턴값 : 없음
*******************************************************************************/
  menu_expand(event) {
    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    var $element = $(".submenu");
    var element: Element = $element.get(0);

    if (!(findrowA.hasClass("depth1-on"))) {
      $(".mainmenu > li > a").removeClass("depth1-on");
      $element.addClass("hide");

      findrowA.addClass("depth1-on");
      findrowA.parent().find( $element ).removeClass("hide");
    } else {
      findrowA.removeClass("depth1-on");
      findrowA.parent().find( $element ).addClass("hide");
    }
  }

/*******************************************************************************
  설  명 : 메인메뉴 클릭 시 링크 처리( ? query string routerLink 오류로 대체 )
  입력값 : url
  리턴값 : 없음
*******************************************************************************/
  query_to_hash = function( uri ) {
    let j, q;
    q = uri.replace(/\?/, "").split("&");
    j = {};
    $.each(q, function(i, arr) {
      arr = arr.split('=');
      return j[arr[0]] = arr[1];
    });
    return j;
  }

  goLink( item ) {
    let uri = item.link_url;
    let queryHash = ( item.query_string != null ) ? this.query_to_hash(item.query_string) : '';

    this.commonService.menuseq = item.seq;

    this.router.navigate(
      [uri],
      {
        queryParams: queryHash,
        relativeTo: this.activateRoute,
        queryParamsHandling: '', // remove to replace all query params by provided
      }
    );
  }

/*******************************************************************************
  설  명 : 서브메뉴 링크가 있으면 펼침
  입력값 : subList
  리턴값 : true / false;
*******************************************************************************/
  checkSub( subList ) {
    for( const index in subList ) {
      if( this.checkSubMenu( subList[index].link_url ) ) {
        return true;
      }
    }
    return false;
  }

/*******************************************************************************
  설  명 : 서브메뉴 서브페이지의 경우 체크
  입력값 : url, checkUrl
  리턴값 : true / false;
*******************************************************************************/
  checkSubMenu( url ) {
    let uri = this.location.path();

    if( uri.indexOf(url) > -1 ) return true;
    else if( uri.indexOf( '/board' ) > -1 && url.indexOf('/board') > -1 ) return true;
    else return false;
  }

/*******************************************************************************
  설  명 : 서브메뉴가 메인메뉴에 포함되는지 체크
  입력값 : item
  리턴값 : true / false;
*******************************************************************************/
  checkMainMenu( item ) {
    for( let i in item.sub ) {
      if( this.checkSubMenu( item.sub[i].link_url ) ) {
        return true;
      }
    }

    return false;
  }
}
