/*******************************************************************************
  설  명 : 시설별 검사내역 등록/수정
  작성일 : 2020-02-26
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { MemberService } from '@app/service/member.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { ReceptionService } from '@app/service/reception.service';
import { InspectFeeService } from '@app/service/inspect.fee.service';
import { CompanyfacilityService } from '@app/service/companyfacility.service';
import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-inspect-add',
  templateUrl: './inspect-add.component.html',
  styleUrls: ['./inspect-add.component.scss']
})
export class InspectAddComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역 변수
  *******************************************************************************/
  recptionStatusList: any = [];
  memberList: any = [];
  inspectionTypeList: any = [];
  inspectionAgencyList: any = [];
  certificateStatusList: any = [];
  inspectionYnList: any = [];
  passYnList: any = [];
  approvalYnList: any = [];

  memberInfo: any = {};
  facilityInfo: any = {};

  public receptionSeq: any;
  public facilityCode: any;
  public usageCode: any;

  title: any = '등록';

  private dateModel: NgbDateStruct;

  public form: FormGroup;
  public formErrors = {};

  public changePlanDateFunc: any;     // 예정일 변경 시 실행
  public changeInspectionDateFunc: any;

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      receptionSeq:["", [] ],
      facilityCode:["", [Validators.required] ],
      companyName:["", [Validators.required] ],
      facilityName:["", [Validators.required] ],
      receptionNumber:["", [] ],
      receptionStatus:["1", [] ],
      memberNo:["", [] ],
      inspectionName:["", [] ],
      receptionDate:[null, [] ],
      inspectionFee:["", [] ],
      inspectionTax:["", [] ],
      inspectionFeeTotal:["", [] ],
      planDate:[null, [] ],
      inspectionDate:[null, [] ],
      inspectionType:["", [] ],
      inspectionAgency:["", [] ],
      inspectionYn:["", [] ],
      receptionMember:["", [] ],
      passYn:["", [] ],
      nonconformityContent:["", [] ],
      necessaryMeasure:["", [] ],
      reinspectionDate:[null, [] ],
      riskFator:["", [] ],
      nextInspectionType:["", [] ],
      nextInspectionDate:[null, [] ],
      certificateNumber:["", [] ],
      certificateDate:[null, [] ],
      certificateStatus:["", [] ],
      frozenCapacity:["", [] ],
      correctMemo:["", [] ],
      totalComment:["", [] ],
      paymentMemo:["", [] ],
      refundMemo:["", [] ],
      taxInvoiceMemo:["", [] ],
      approvalYn:["", [] ],
      trmYnName:["", [] ],
      usageName:["", [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private globals: Globals,
    private authService: AuthService,
    private memberService: MemberService,
    private commonCodeService: CommonCodeService,
    private receptionService: ReceptionService,
    private inspectFeeService: InspectFeeService,
    private companyfacilityService: CompanyfacilityService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
  ) {
    this.buildForm();

    this.changePlanDateFunc = this.setInspectionDate.bind(this);
    this.changeInspectionDateFunc = this.setCertificateDate.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    // 공통코드 리스트 가져오기
    await this.getCommonList();

    await this.activatedRoute.queryParams.subscribe( params => {
      this.receptionSeq = params.receptionSeq;
      this.facilityCode = params.facilityCode;
      this.usageCode = params.usageCode;

      this.form.patchValue({
        receptionSeq: this.receptionSeq,
        facilityCode: this.facilityCode,
      });
    });

    // 로그인 정보 가져오기
    await this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;
      // 등록 시
      if( this.receptionSeq == false || this.receptionSeq == '0' ) {
        if (this.memberInfo.id != 'admin'){
          this.form.patchValue({
            receptionMember: this.memberInfo.mem_no,
          });
        }

        // 시설 정보 가져오기
        this.getFacilityInfo();

        // 시설 수수료 가져오기
        this.getFacilityInspectionFee();
      }
      // 수정 시
      else {
        this.title = '수정';

        this.getFacilityReceptionInfo();
      }
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 접수상태
    await this.commonCodeService.getReceptionStatusSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        response.data.forEach( row => {
          this.recptionStatusList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.recptionStatusList = [];
      }
    });

    // 접수자, 검사원
    await this.memberService.getMemberSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        response.data.forEach( row => {
          this.memberList.push({
            title: row.name,
            value: row.memberNo
          });
        });
      } else {
        this.memberList = [];
      }
    });

    // 검사종류, 차기검사종류
    await this.commonCodeService.getInspectionTypeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        response.data.forEach( row => {
          this.inspectionTypeList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.inspectionTypeList = [];
      }
    });

    // 검사기관
    await this.commonCodeService.getInspectionAgencySelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        response.data.forEach( row => {
          this.inspectionAgencyList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.inspectionAgencyList = [];
      }
    });

    // 발행구분
    await this.commonCodeService.getCertificateStatusSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        response.data.forEach( row => {
          this.certificateStatusList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.certificateStatusList = [];
      }
    });

    // 검사여부
    this.inspectionYnList.push(
      { title: '검사', value: '1' },
      { title: '미검사', value: '0' }
    );

    // 합격여부
    this.passYnList.push(
      { title: '합격', value: '1' },
      { title: '불합격', value: '0' }
    );

    // 결재여부
    this.approvalYnList.push(
      { title: '완결', value: '1' },
      { title: '미결', value: '0' }
    );

  }

  /*******************************************************************************
    설  명 : 접수번호 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getReceptionNumber() {
    this.receptionService.getReceptionNumber( this.facilityCode ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.form.patchValue( response.data );

        // 접수일 데이터가 없다면
        if( moment(this.form.controls.receptionDate.value).format('YYYY-MM-DD') == 'Invalid date' ) {
          this.dateModel = {
            year: parseInt( moment().format('YYYY') ),
            month: parseInt( moment().format('MM') ),
            day: parseInt( moment().format('DD') )
          };

          this.form.patchValue({
            receptionDate: this.dateModel,
            receptionStatus: '2'
          });
        }

        this.memberList.forEach(item => {
          if( this.memberInfo.mem_no == item.value ) {
            this.form.patchValue({
              receptionMember: item.value
            });
          }
        });

        // 검사기관 select 고정
        this.form.patchValue({
          inspectionAgency: "4"
        });

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 필증번호 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCertificateNumber() {
    if( !this.form.controls.inspectionDate.value || this.form.controls.inspectionDate.value == null ) {
      this.toastrService.error( '검사일을 입력하시기 바랍니다.', '');
    } else {
      this.receptionService.getCertificateNumber( this.facilityCode, this.form.controls.inspectionDate.value ).then( response => {
        //console.log(response);
        if( response.ResultCode ) {
          this.form.patchValue({
            certificateNumber: response.data['certificateNumber'],
            certificateStatus: '1'
          });
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 시설 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getFacilityInfo() {
    await this.companyfacilityService.getFacilityInfo( {facilityCode: this.facilityCode} ).then( response => {
      if( response.ResultCode ) {
        this.facilityInfo = response.data;

        this.form.patchValue({
          companyName: this.facilityInfo.companyName,
          facilityName: this.facilityInfo.facilityName,
          frozenCapacity: this.facilityInfo.freezeCapacity,
          trmYnName: this.facilityInfo.trmYnName,
          usageName: this.facilityInfo.usageName
        });
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 시설 수수료 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getFacilityInspectionFee() {
    await this.inspectFeeService.getFacilityInspectionFee( {facilityCode: this.facilityCode} ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.form.patchValue( response.data );
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 차기검사 가져오기(차기 검사일은 예정일 기준으로 찾아온다.)
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getNextInspectionInfo() {
    const inspectionType = this.form.controls.inspectionType.value;
    const inspectionDate = this.form.controls.inspectionDate.value;
    const planDate = this.form.controls.planDate.value;

    if( !planDate || planDate == '' ) {
      this.toastrService.error('예정일을 선택하시기 바랍니다.', '' );
      return false;
    }

    if( !inspectionType || inspectionType == '' ) {
      this.toastrService.error('검사종류를 선택하시기 바랍니다.', '' );
      return false;
    }

    this.receptionService.getNextInspectionInfo( this.usageCode, planDate, inspectionType ).then( response => {
      if( response.ResultCode ) {
        const nextInspectionInfo = response.data;

        if( nextInspectionInfo !== null ) {
          if( nextInspectionInfo.nextInspectionDate !== null ) {
            this.dateModel = {
              year: parseInt( nextInspectionInfo.nextInspectionDate.substr( 0, 4 ) ),
              month: parseInt( nextInspectionInfo.nextInspectionDate.substr( 5, 2 ) ),
              day: parseInt( nextInspectionInfo.nextInspectionDate.substr( 8, 2 ) )
            };

            nextInspectionInfo.nextInspectionDate = this.dateModel;
          }

          this.form.patchValue( nextInspectionInfo );
        } else {
          this.toastrService.error( '차기검사 결과값이 없습니다.', '');
        }

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 검사 접수 등록/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setFacilityReception() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    // 정기검사 불가능 체크
    let checkTrmYn = true;
    // 정기검사 일때만 체크한다.
    if (parseInt(this.form.controls.inspectionType.value) == 0 ) {
      // 정기검사불가능 업체는 불능으로 선택했을 때만 저장이 가능하다.
      if (this.form.controls.trmYnName.value != '정기검사가능' && parseInt(this.form.controls.receptionStatus.value) != 5 ) {
        checkTrmYn = false;
      }

      if (checkTrmYn == false) {
        this.toastrService.error('정기검사불가능업체는 접수가 불가능합니다. 접수상태를 불능으로 설정하고 저장하세요.', '');
        return;
      }
    }

    if(this.form.valid) {
      this.receptionService.setFacilityReception( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '', {
            closeButton: true,
            positionClass: 'toast-top-right',
          });

          if ( this.receptionSeq === undefined || this.receptionSeq == 0 ) {
            this.receptionSeq = response.receptionSeq;

            this.form.patchValue({
              receptionSeq: this.receptionSeq
            });
          }

          window.opener.fn.namespace.publicFunc();

        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 검사 접수 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setFacilityReceptionDelete() {
    if( confirm("검사접수를 삭제하시겠습니까?") ) {
      this.receptionService.setFacilityReceptionDelete( this.receptionSeq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          //this.activeModal.close(true);
          this.onWindowClose();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 검사 접수 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getFacilityReceptionInfo() {
    await this.receptionService.getFacilityReceptionInfo( this.receptionSeq ).then( response => {
      if( response.ResultCode ) {
        let receptionInfo = response.data;

        if( receptionInfo.receptionDate != '' && receptionInfo.receptionDate != null ) {
          this.dateModel = {
            year: parseInt( receptionInfo.receptionDate.substr( 0, 4 ) ),
            month: parseInt( receptionInfo.receptionDate.substr( 5, 2 ) ),
            day: parseInt( receptionInfo.receptionDate.substr( 8, 2 ) )
          };

          receptionInfo.receptionDate = this.dateModel;
        } else {
          receptionInfo.receptionDate = null;
        }

        if( receptionInfo.planDate != '' && receptionInfo.planDate != null ) {
          this.dateModel = {
            year: parseInt( receptionInfo.planDate.substr( 0, 4 ) ),
            month: parseInt( receptionInfo.planDate.substr( 5, 2 ) ),
            day: parseInt( receptionInfo.planDate.substr( 8, 2 ) )
          };

          receptionInfo.planDate = this.dateModel;
        } else {
          receptionInfo.planDate = null;
        }

        if( receptionInfo.inspectionDate != '' && receptionInfo.inspectionDate != null ) {
          this.dateModel = {
            year: parseInt( receptionInfo.inspectionDate.substr( 0, 4 ) ),
            month: parseInt( receptionInfo.inspectionDate.substr( 5, 2 ) ),
            day: parseInt( receptionInfo.inspectionDate.substr( 8, 2 ) )
          };

          receptionInfo.inspectionDate = this.dateModel;
        } else {
          receptionInfo.inspectionDate = null;
        }

        if( receptionInfo.reinspectionDate != '' && receptionInfo.reinspectionDate != null ) {
          this.dateModel = {
            year: parseInt( receptionInfo.reinspectionDate.substr( 0, 4 ) ),
            month: parseInt( receptionInfo.reinspectionDate.substr( 5, 2 ) ),
            day: parseInt( receptionInfo.reinspectionDate.substr( 8, 2 ) )
          };

          receptionInfo.reinspectionDate = this.dateModel;
        } else {
          receptionInfo.reinspectionDate = null;
        }

        if( receptionInfo.nextInspectionDate != '' && receptionInfo.nextInspectionDate != null ) {
          this.dateModel = {
            year: parseInt( receptionInfo.nextInspectionDate.substr( 0, 4 ) ),
            month: parseInt( receptionInfo.nextInspectionDate.substr( 5, 2 ) ),
            day: parseInt( receptionInfo.nextInspectionDate.substr( 8, 2 ) )
          };

          receptionInfo.nextInspectionDate = this.dateModel;
        } else {
          receptionInfo.nextInspectionDate = null;
        }

        if( receptionInfo.certificateDate != '' && receptionInfo.certificateDate != null ) {
          this.dateModel = {
            year: parseInt( receptionInfo.certificateDate.substr( 0, 4 ) ),
            month: parseInt( receptionInfo.certificateDate.substr( 5, 2 ) ),
            day: parseInt( receptionInfo.certificateDate.substr( 8, 2 ) )
          };

          receptionInfo.certificateDate = this.dateModel;
        } else {
          receptionInfo.certificateDate = null;
        }

        this.form.patchValue( receptionInfo );

      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 예정일 변경 시 검사일도 동일하게 바인딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setInspectionDate() {

    // 검사일 변경
    this.form.patchValue({
      inspectionDate: this.form.controls.planDate.value
    });

    // 검사일 변경 시 발급일 가져와서 바인딩 처리
    this.receptionService.getCertificateDate( this.form.controls.inspectionDate.value ).then( response => {
      if( response.ResultCode ) {

        if( typeof response.data != 'undefined' ) {
          this.dateModel = {
            year: parseInt( response.data.certificateDate.substr( 0, 4 ) ),
            month: parseInt( response.data.certificateDate.substr( 5, 2 ) ),
            day: parseInt( response.data.certificateDate.substr( 8, 2 ) )
          };

          response.data.certificateDate = this.dateModel;
        }

        this.form.patchValue( response.data );
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });

  }

  /*******************************************************************************
    설  명 : 검사일 변경시 발급일을 가져와서 바인딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCertificateDate() {

    // 검사일 변경 시 발급일 가져와서 바인딩 처리
    this.receptionService.getCertificateDate( this.form.controls.inspectionDate.value ).then( response => {
      if( response.ResultCode ) {

        if( typeof response.data != 'undefined' ) {
          this.dateModel = {
            year: parseInt( response.data.certificateDate.substr( 0, 4 ) ),
            month: parseInt( response.data.certificateDate.substr( 5, 2 ) ),
            day: parseInt( response.data.certificateDate.substr( 8, 2 ) )
          };

          response.data.certificateDate = this.dateModel;
        }

        this.form.patchValue( response.data );
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });

  }

  /*******************************************************************************
    설  명 : 윈도우 창 닫기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onWindowClose() {
    window.opener.fn.namespace.publicFunc();
    window.close();
    //this.activeModal.close(true);
  }

}
