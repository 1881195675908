import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UtilService } from '@app/service/util.service';
import { ReportService } from '@app/service/report.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-print-books-inspect1',
  templateUrl: './print-books-inspect1.component.html',
  styleUrls: ['./print-books-inspect1.component.scss']
})
export class PrintBooksInspect1Component implements OnInit {

  public dataList: any = [];
  public inspectionSdate: any;
  public inspectionEdate: any;

  private search: any = {
    searchField: 'facilityName',
    searchText: '',
    //inspectionSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
    //inspectionEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
    inspectionSdate: '',
    inspectionEdate: '',
    officeCode: '',
    regionCode: '',
    cityCode: '',
    passYn: '',
    memberNo: '',
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private reportService: ReportService,
  ) {
    this.setPrint(true);
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  ngOnInit() {
    // 파라미터 가져오기
    this.activatedRoute.queryParams.subscribe(params => {
      this.search = ( typeof params.search == 'undefined' || params.search == '' ) ? '' : JSON.parse( decodeURIComponent(params.search) );

      this.inspectionSdate = this.utilService.getDateStr( this.search.inspectionSdate );
      this.inspectionEdate = this.utilService.getDateStr( this.search.inspectionEdate );

      this.getPrintInspectionResultList();
    });
  }

  /*******************************************************************************
    설  명 : view 로딩 후 생성자
  *******************************************************************************/
  ngAfterViewInit() {
    var data = $(".table tbody").children();
    setTimeout (() => {
      if( typeof data[0] !== undefined ) {
        window.print();
      }
    }, 500);
  }

  /*******************************************************************************
    설  명 : 검사실시대장 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getPrintInspectionResultList() {
    await this.reportService.getPrintInspectionResultList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.dataList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 프린트 설정
    입력값 : landscape(가로 출력 여부)
    리턴값 : 없음
  *******************************************************************************/
  setPrint(landscape: boolean) {
    var head = document.head || document.getElementsByTagName('head')[0];
    var style = document.createElement('style');
    style.type = 'text/css';
    style.media = 'print';

    style.appendChild(document.createTextNode(landscape ? '@page { size:auto; margin: 0.3in;}' : '' ));

    head.appendChild(style);
  }

}
