import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { ToastrService } from 'ngx-toastr';

import { Globals } from '@app/service/globals.service';
import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { MemberService } from '@app/service/member.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss',]
})
export class LoginComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {
    id:'',
    pw:''
  };

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      id:["", Validators.required],
      pw:["", Validators.required],
      saveId: [false, []]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private globals: Globals,
    public memberService: MemberService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    public authService: AuthService,
    private toastrService: ToastrService,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    let check = this.cookieService.check( config.cookie_token );

    if( check ) {
      this.form.patchValue({
        id: this.cookieService.get( config.cookie_token ),
        saveId: true
      });
    }
  }

  /*******************************************************************************
    설  명 : 로그인 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid){
      await this.memberService.login(this.form.value.id, this.form.value.pw).then(response => {

        if( response['ResultCode'] == true ) {
          this.authService.login(response);

          // 아이디 저장 시
          if( this.form.controls.saveId.value ) {
            this.cookieService.set( config.cookie_token, this.form.controls.id.value );
          } else {
            this.cookieService.delete( config.cookie_token );
          }

          this.toastrService.success('로그인에 성공하였습니다.', '로그인 성공');
          //window.location.href = '/dashboard';

          this.router.navigate(
            ['/dashboard'],
            {
              queryParams: {},
              queryParamsHandling: '', // remove to replace all query params by provided
            }
          );

        } else {
          this.toastrService.error('로그인에 실패하였습니다.', '로그인 실패');
        }
      }, error => {
        this.toastrService.error(error, '로그인 실패');
      });
    }
  }
}
