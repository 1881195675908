import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ReceptionService } from '@app/service/reception.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-print-giro',
  templateUrl: './print-giro.component.html',
  styleUrls: ['./print-giro.component.scss']
})
export class PrintGiroComponent implements OnInit {

  private params: any;
  private seqArray: any;
  public addressType: any;
  public recipient: any;

  public giroList: any = [];

  public year: any = moment().format('YYYY');
  public month: any = moment().format('MM');
  public day: any = moment().format('DD');

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private receptionService: ReceptionService,
  ) { }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  ngOnInit() {
    // 파라미터 가져오기
    this.params = JSON.parse( sessionStorage.getItem( 'inspectHistoryPrintGiro' ) );
    sessionStorage.removeItem( 'inspectHistoryPrintGiro' );

    this.addressType = this.params.type;
    this.seqArray = JSON.stringify( this.params.seq );
    this.recipient = this.params.recipient;

    this.getReceptionGiroInfo();
  }

  /*******************************************************************************
    설  명 : view 로딩 후 생성자
  *******************************************************************************/
  ngAfterViewInit() {
    var data = $(".table tbody").children();
    setTimeout (() => {
      if( typeof data[0] !== undefined ) {
        window.print();
      }
    }, 500);
  }

  /*******************************************************************************
    설  명 : 업체/시설 정보 가져오기
  *******************************************************************************/
  getReceptionGiroInfo() {
    this.receptionService.getReceptionGiroInfo( this.seqArray ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.giroList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

}
