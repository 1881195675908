import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ReportService } from '@app/service/report.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-print-books-facility',
  templateUrl: './print-books-facility.component.html',
  styleUrls: ['./print-books-facility.component.scss']
})
export class PrintBooksFacilityComponent implements OnInit {

  public facility: any = {};

  private params: any = {
    seq: ''
  };

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private reportService: ReportService,
  ) { }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  ngOnInit() {
    // 파라미터 가져오기
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.seq = ( typeof params.seq == 'undefined' || params.seq == '' ) ? '' : params.seq;

      this.getFacilityReport();
    });
  }

  /*******************************************************************************
    설  명 : view 로딩 후 생성자
  *******************************************************************************/
  ngAfterViewInit() {
    var data = $(".table tbody").children();
    setTimeout (() => {
      if( typeof data[0] !== undefined ) {
        window.print();
      }
    }, 500);
  }

  /*******************************************************************************
    설  명 : 시설검사업체 데이터 가져오기
  *******************************************************************************/
  getFacilityReport() {
    this.reportService.getFacilityReport( this.params.seq ).then( response => {
      console.log(response);
      if( response.ResultCode ) {
        this.facility = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

}
