/*******************************************************************************
  설  명 : 업체 / 시설정보 상세정보
  작성일 : 2020-02-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { Globals } from '@app/service/globals.service';
import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { MemberService } from '@app/service/member.service';
import { RegionService } from '@app/service/region.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { CompanyfacilityService } from '@app/service/companyfacility.service';
import { ReceptionService } from '@app/service/reception.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-facility-info',
  templateUrl: './modal-facility-info.component.html',
  styleUrls: ['./modal-facility-info.component.scss']
})
export class ModalFacilityInfoComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역 변수
  *******************************************************************************/
  @Input() companySeq: any;
  @Input() facilitySeq: any;
  @Output() emitData = new EventEmitter();

  // 공통코드 데이터 리스트
  public memberTypeList = [];
  public statusList = [];
  public regoinList = [];
  public cityList = [];
  public useList = [];
  public freezeList = [];
  public dmSendList = [];
  public completeTypeList = [];
  public completeGubunList = [];
  public inspectionList = [];

  public companyInfo: any = {};
  public facilityInfo: any = {};

  public form: FormGroup;
  public formErrors = {};

  private dateModel: NgbDateStruct;

  public changeCitySelectFunc: any;     // 시/도 변경시 실행 함수

  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      companySeq: ['', [] ],
      companyCode: ['', [] ],
      companyName: ['', [Validators.required, Validators.maxLength(50)] ],
      businessNo: ['', [Validators.maxLength(12)] ],
      ceoName: ['', [Validators.maxLength(50)] ],
      telNo: ['', [Validators.maxLength(20)] ],
      faxNo: ['', [Validators.maxLength(20)] ],
      phoneNo: ['', [Validators.maxLength(50)] ],
      zipCode: ['', [] ],
      address: ['', [Validators.maxLength(100)] ],
      addressDetail: ['', [Validators.maxLength(100)] ],
      memo: ['', [Validators.maxLength(255)] ],

      facilitySeq: ['', [] ],
      facilityCode: ['', [] ],
      facilityCodeFormat: ['', [] ],
      facilityName: ['', [Validators.required, Validators.maxLength(50)] ],
      regionCode: ['', [Validators.required] ],
      cityCode: ['', [Validators.required] ],
      facilityCeoName: ['', [Validators.maxLength(50)] ],
      chargeTel: ['', [Validators.maxLength(20)] ],
      chargeFax: ['', [Validators.maxLength(20)] ],
      facilityBusinessNo: ['', [Validators.maxLength(12)] ],
      facilityZipCode: ['', [CustomValidator.numeric] ],
      facilityAddress: ['', [Validators.maxLength(100)] ],
      facilityAddressDetail: ['', [Validators.maxLength(100)] ],
      zipCodeTax: ['', [] ],
      addressTax: ['', [Validators.maxLength(100)] ],
      addressDetailTax: ['', [Validators.maxLength(100)] ],
      companyNameTax: ['', [Validators.maxLength(50)] ],
      businessNoTax: ['', [Validators.maxLength(12)] ],
      memberType: ['', [Validators.required] ],
      status: ['0', [Validators.required] ],
      responName: ['', [Validators.maxLength(50)] ],
      saftyManagerName1: ['', [Validators.maxLength(20)] ],
      saftyManagerName2: ['', [Validators.maxLength(20)] ],
      saftyManagerName3: ['', [Validators.maxLength(20)] ],
      usageCode: ['', [Validators.required] ],
      freezeCode: ['', [Validators.required] ],
      freezeCapacity: ['', [Validators.required, Validators.maxLength(50)] ],
      completeType: ['', [] ],
      completeDate: [null, [] ],
      completeGubun: ['', [] ],
      nextInspectionDate: [null, [] ],
      nextInspectionType: ['', [] ],
      permitDate: [null, [] ],
      permitNum: ['', [Validators.maxLength(50)] ],
      dmSendCode: ['', [] ],
      dmSendName: ['', [] ],
      facilityMemo: ['', [Validators.maxLength(255)] ],
      facilityMemo1: ['', [Validators.maxLength(255)] ],
      trmYn: ['', [Validators.maxLength(3)] ],
      trmYnName: ['', [Validators.maxLength(30)] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private globals: Globals,
    private memberService: MemberService,
    private regionService: RegionService,
    private commonCodeService: CommonCodeService,
    private companyfacilityService: CompanyfacilityService,
    private receptionService: ReceptionService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    this.buildForm();

    this.changeCitySelectFunc = this.getCitySelect.bind(this);
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.form.patchValue({
      companySeq: this.companySeq,
      facilitySeq: this.facilitySeq
    });

    // 공통코드 리스트 가져오기
    this.getCommonList();

    if ( this.companySeq !== undefined && this.companySeq != 0 ) this.getCompanyInfo();
    if ( this.facilitySeq !== undefined && this.facilitySeq != 0 ) this.getFacilityInfo();
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 회원구분
    await this.commonCodeService.getMemberTypeSelect().then( response => {
      //console.log(response);
      if ( response.ResultCode ) {
        response.data.forEach( row => {
          this.memberTypeList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.memberTypeList = [];
      }
    });

    // 폐지여부
    await this.commonCodeService.getStatusSelect().then( response => {
      //console.log(response);
      if ( response.ResultCode ) {
        response.data.forEach( row => {
          this.statusList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.statusList = [];
      }
    });

    // 시/도
    await this.regionService.getRegionSelect().then( response => {
      //console.log(response);
      if ( response.ResultCode ) {
        response.data.forEach( row => {
          this.regoinList.push({
            title: row.regionName,
            value: row.regionCode
          });
        });
      } else {
        this.regoinList = [];
      }
    });

    // 사용용도
    await this.commonCodeService.getUseSelect().then( response => {
      //console.log(response);
      if ( response.ResultCode ) {
        response.data.forEach( row => {
          this.useList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.useList = [];
      }
    });

    // 냉매가스
    await this.commonCodeService.getFreezeSelect().then( response => {
      //console.log(response);
      if ( response.ResultCode ) {
        response.data.forEach( row => {
          this.freezeList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.freezeList = [];
      }
    });

    // DM발송
    await this.commonCodeService.getDmSendSelect().then( response => {
      //console.log(response);
      if ( response.ResultCode ) {
        response.data.forEach( row => {
          this.dmSendList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.dmSendList = [];
      }
    });

    // 완성검사기간
    await this.commonCodeService.getCompleteTypeSelect().then( response => {
      //console.log(response);
      if ( response.ResultCode ) {
        response.data.forEach( row => {
          this.completeTypeList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.completeTypeList = [];
      }
    });

    // 완성검사주기
    await this.commonCodeService.getCompleteGubunSelect().then( response => {
      //console.log(response);
      if ( response.ResultCode ) {
        response.data.forEach( row => {
          this.completeGubunList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.completeGubunList = [];
      }
    });

    // 검사종류
    await this.commonCodeService.getInspectionTypeSelect().then( response => {
      //console.log(response);
      if ( response.ResultCode ) {
        response.data.forEach( row => {
          this.inspectionList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.inspectionList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 시/구/군 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCitySelect() {
    this.cityList = [];

    this.regionService.getCitySelect( this.form.controls.regionCode.value ).then( response => {
      if ( response.ResultCode ) {
        response.data.forEach( row => {
          this.cityList.push({
            title: row.cityName,
            value: row.cityCode
          });
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 업체 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCompanyInfo() {
    await this.companyfacilityService.getCompanyInfo( this.companySeq ).then( response => {
      if ( response.ResultCode ) {
        this.form.patchValue( response.data );
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 업체 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getFacilityInfo() {
    await this.companyfacilityService.getFacilityInfo( {facilitySeq: this.facilitySeq} ).then( response => {
      if ( response.ResultCode ) {
        this.facilityInfo = response.data;

        if ( this.facilityInfo.completeDate != '' && this.facilityInfo.completeDate != null ) {
          this.dateModel = {
            year: parseInt( this.facilityInfo.completeDate.substr( 0, 4 ) ),
            month: parseInt( this.facilityInfo.completeDate.substr( 5, 2 ) ),
            day: parseInt( this.facilityInfo.completeDate.substr( 8, 2 ) )
          };

          this.facilityInfo.completeDate = this.dateModel;
        } else {
          this.facilityInfo.completeDate = null;
        }

        if ( this.facilityInfo.permitDate != '' && this.facilityInfo.permitDate != null ) {
          this.dateModel = {
            year: parseInt( this.facilityInfo.permitDate.substr( 0, 4 ) ),
            month: parseInt( this.facilityInfo.permitDate.substr( 5, 2 ) ),
            day: parseInt( this.facilityInfo.permitDate.substr( 8, 2 ) )
          };

          this.facilityInfo.permitDate = this.dateModel;
        } else {
          this.facilityInfo.permitDate = null;
        }

        if ( this.facilityInfo.nextInspectionDate != '' && this.facilityInfo.nextInspectionDate != null ) {
          this.dateModel = {
            year: parseInt( this.facilityInfo.nextInspectionDate.substr( 0, 4 ) ),
            month: parseInt( this.facilityInfo.nextInspectionDate.substr( 5, 2 ) ),
            day: parseInt( this.facilityInfo.nextInspectionDate.substr( 8, 2 ) )
          };

          this.facilityInfo.nextInspectionDate = this.dateModel;
        } else {
          this.facilityInfo.nextInspectionDate = null;
        }

      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });

    this.form.patchValue( this.facilityInfo );

    if ( this.form.controls.regionCode.value != '' )
      this.getCitySelect();
  }

  /*******************************************************************************
    설  명 : 다음주소창에서 클릭시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipCode: data.zip,
      address: data.addr
    });

    $('#addressDetail').focus();
  }

  /*******************************************************************************
    설  명 : 다음주소창에서 클릭시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApiFacility(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      facilityZipCode: data.zip,
      facilityAddress: data.addr
    });

    $('#facilityAddressDetail').focus();
  }

  /*******************************************************************************
    설  명 : 다음주소창에서 클릭시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddressApiTax(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipCodeTax: data.zip,
      addressTax: data.addr
    });

    $('#addressDetailTax').focus();
  }

  /*******************************************************************************
    설  명 : 차기검사 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getNextInspection() {
    let usageCode = this.form.controls.usageCode.value;
    let completeDate = this.form.controls.completeDate.value;
    let inspectionType = 10;

    if ( !usageCode || usageCode == '' ) {
      this.toastrService.error( '사용용도를 선택하시기 바랍니다', '' );
      return false;
    }

    if ( !completeDate || completeDate == '' ) {
      this.toastrService.error( '완성검사일자를 선택하시기 바랍니다', '' );
      return false;
    }

    this.receptionService.getNextInspectionInfo( usageCode, completeDate, inspectionType ).then( response => {
      if ( response.ResultCode ) {

        if ( response.data !== null ) {
          this.toastrService.success( response.ResultMessage, '');

          if ( response.data.nextInspectionDate !== null ) {
            this.dateModel = {
              year: parseInt( response.data.nextInspectionDate.substr( 0, 4 ) ),
              month: parseInt( response.data.nextInspectionDate.substr( 5, 2 ) ),
              day: parseInt( response.data.nextInspectionDate.substr( 8, 2 ) )
            };

            response.data.nextInspectionDate = this.dateModel;
          }

          this.form.patchValue( response.data );
        } else {
          this.toastrService.error( '조회된 데이터가 없습니다.', '');
        }

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });

  }

  /*******************************************************************************
    설  명 : 업체 정보 가져와서 시설 정보에 적용하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setFacilityInfo() {
    this.form.patchValue({
      facilityName: this.form.controls.companyName.value,
      facilityCeoName: this.form.controls.ceoName.value,
      facilityBusinessNo: this.form.controls.businessNo.value,
      chargeFax: this.form.controls.faxNo.value,
      chargeTel: this.form.controls.telNo.value,
      facilityZipCode: this.form.controls.zipCode.value,
      facilityAddress: this.form.controls.address.value,
      facilityAddressDetail: this.form.controls.addressDetail.value,
    });
  }

  /*******************************************************************************
    설  명 : 업체/시설 정보 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCompanyfacilitySave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.companyfacilityService.setCompanyfacilitySave( this.form ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.emitData.next( response.companyCode );

          if ( this.facilitySeq === undefined || this.facilitySeq == 0 ) {
            this.facilitySeq = response.facilitySeq;

            this.form.patchValue({
              facilitySeq: this.facilitySeq
            });
          }

          //this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 시설 정보 삭제하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCompanyfacilityDelete() {
    if ( confirm('해당 시설을 삭제하시겠습니까?') ) {
      this.companyfacilityService.setCompanyfacilityDelete( this.facilitySeq ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');

        this.activeModal.close();
      });
    }
  }
}
