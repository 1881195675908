import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})

export class InspectFeeService {

  constructor(
    private restful: RestfulService
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.log(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 수수료적용일 가져오기
  getInspectFeeDateList( memberType ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'inspectionfee',
        version: '1.0',
        action: 'getInspectFeeDateList'
      }, {
        memberType: memberType
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 수수료 세부내용 리스트 가져오기
  getInspectFeeList( memberType, registDate ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'inspectionfee',
        version: '1.0',
        action: 'getInspectFeeList'
      }, {
        memberType: memberType,
        registDate: registDate
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 수수료 세부내용 정보 가져오기
  getInspectFeeInfo( inspectFeeSeq ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'inspectionfee',
        version: '1.0',
        action: 'getInspectFeeInfo'
      }, {
        inspectFeeSeq: inspectFeeSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 수수료적용일 복사 등록
  setCopyInspectFee( form: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'inspectionfee',
        version: '1.0',
        action: 'setCopyInspectFee'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 수수료 정보를 추가
  setInspectFee( form: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'inspectionfee',
        version: '1.0',
        action: 'setInspectFee'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 수수료 중복체크
  getCheckFeeDuplicate( form: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'inspectionfee',
        version: '1.0',
        action: 'getCheckFeeDuplicate'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 수수료 정보 삭제하기
  setInspectFeeDelete( inspectFeeSeq ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'inspectionfee',
        version: '1.0',
        action: 'delInspectFee'
      }, {
        inspectFeeSeq: inspectFeeSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시설 수수료 정보 가져오기
  getFacilityInspectionFee( params: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'inspectionfee',
        version: '1.0',
        action: 'getFacilityInspectionFee'
      }, {
        facilitySeq: params.facilitySeq,
        facilityCode: params.facilityCode
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
