/*******************************************************************************
  설  명 : 필증발급관리대장
  작성일 : 2020-02-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbCalendar, NgbModal, ModalDismissReasons, NgbModalOptions, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { Globals } from '@app/service/globals.service';
import { MemberService } from '@app/service/member.service';
import { RegionService } from '@app/service/region.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { ReportService } from '@app/service/report.service';
import { ReceptionService } from '@app/service/reception.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-books-mandatory',
  templateUrl: './books-mandatory.component.html',
  styleUrls: ['./books-mandatory.component.scss']
})
export class BooksMandatoryComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 공통코드 데이터 리스트
  regoinList: any = [];
  cityList: any = [];
  officeList: any = [];
  
  officeCd: string;

   calendarInfo: any = {
    year: moment().format('YYYY'),
    month: moment().format('MM'),
    sdate: moment().format('YYYY-MM-01'),
    edate: moment().add(1, 'month').date(0).format('YYYY-MM-DD'),
    start_day: '',
    end_day: ''
  }

  // 검색 필드
  public search = {
    searchField: 'facilityName',
    searchText: '',
    //inspectionSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
    //inspectionEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
    inspectionSdate: '',
    inspectionEdate: '',
    officeCode: '',
    regionCode: '',
    cityCode: '',
  }

  // 그리드 관련 선언
  selectedRows: number = 0;
  dataList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private memberService: MemberService,
    private modalService: NgbModal,
    private regionService: RegionService,
    private commonCodeService: CommonCodeService,
    private reportService: ReportService,
    private receptionService: ReceptionService,
    private calendar: NgbCalendar,
    private globals: Globals,
    private ngbDatepickerConfig: NgbDatepickerConfig
    
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '순번', field: 'rowIndex', cellClass: 'cp center', checkboxSelection: true, valueGetter: "node.rowIndex + 1" ,width:85},
      {headerName: '순번', field: 'receptionSeq', cellClass: 'cp center', hide:true },
      {headerName: '업체명', field: 'companyName', cellClass: 'cp' ,width:150},
      {headerName: '시설코드', field: 'facilityCode', cellClass: 'cp center' ,width:90, hide:true},
      {headerName: '시설코드', field: 'facilityCodeFormat', cellClass: 'cp center' ,width:90},
      {headerName: '시설명', field: 'facilityName', cellClass: 'cp' ,width:150},
      {headerName: '필증번호', field: 'certificateNumber', cellClass: 'cp center',width:90,hide:true },
      {headerName: '필증번호', field: 'certificateNumberFormat', cellClass: 'cp center',width:90 },
      {headerName: '접수번호', field: 'receptionNumber', cellClass: 'cp center',width:90,hide:true },
      {headerName: '접수번호', field: 'receptionNumberFormat', cellClass: 'cp center',width:90 },
      {headerName: '냉동톤', field: 'freezeCapacity', cellClass: 'cp center',width:90 },
      {headerName: '검사종류', field: 'inspectionTypeName', cellClass: 'cp center' ,width:90},
      {headerName: '검사일', field: 'inspectionDate', cellClass: 'cp center' ,width:90},
      {headerName: '발급일', field: 'certificateDate', cellClass: 'cp center',width:90 },
      {headerName: '검사원', field: 'memberName', cellClass: 'cp center' ,width:90},
      {headerName: '발급', field: 'issuance', cellClass: 'cp center',width:80 },
      {headerName: '재발급', field: 'reissuance', cellClass: 'cp center',width:80 },
      {headerName: '관리자', field: 'managerName', cellClass: 'cp center',width:90 },
      {headerName: '비고', field: 'memo', cellClass: 'cp' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    ngbDatepickerConfig.minDate = {year: 1900, month: 1, day: 1};
    ngbDatepickerConfig.maxDate = {year: 2099, month: 12, day: 31};
    ngbDatepickerConfig.displayMonths = 1;
    ngbDatepickerConfig.firstDayOfWeek = 7; // 일요일 먼저


    
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 공통코드 리스트 가져오기
    this.getCommonList();

    // 그리드 리스트 가져오기
    this.getCertificateManagementList();
  }

  /*******************************************************************************
    설  명 : 뷰 처리 완료 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {

    // indicator 표시
    setTimeout (() => {
      if( this.dataList.length < 1 )
        this.globals.indicator.next(true);
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchField: 'facilityName',
      searchText: '',
      //inspectionSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
      //inspectionEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
      inspectionSdate: '',
      inspectionEdate: '',
      officeCode: this.officeCd,
      regionCode: '',
      cityCode: '',
    }

    this.getCertificateManagementList();
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 시/도
    await this.regionService.getRegionSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.regoinList = response.data;
      } else {
        this.regoinList = [];
      }
    });

    // 사무소
    await this.commonCodeService.getOfficeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.officeList = response.data;
      } else {
        this.officeList = [];
      }
    });
    
    // 사무소코드
    await this.memberService.getMemberOffice().then( response => {
     // console.log(response.data['officeCd']);
      if( response.ResultCode ) {
        this.officeCd = response.data['officeCd'];
      } else {
         this.officeCd = '';
      }
    });
    
    this.searchInit();
    
  }

  /*******************************************************************************
    설  명 : 시/구/군 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCitySelect() {
    this.cityList = [];

    await this.regionService.getCitySelect( this.search.regionCode ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.cityList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 필증발급관리대장 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCertificateManagementList() {
    await this.reportService.getCertificateManagementList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.dataList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {

  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getCertificateManagementList();
    }
  }

  /*******************************************************************************
    설  명 : 시/도 선택 시
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setRegionChange() {
    this.search.cityCode = '';

    this.getCitySelect();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.inspectionSdate = this.utilService.getDate( this.calendar.getToday() );
    } else {
      this.search.inspectionEdate = this.utilService.getDate( this.calendar.getToday() );
    }

    obj.close();
  }

  /*******************************************************************************
    설  명 : 필증발행
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openCertificate() {

  }

  /*******************************************************************************
    설  명 : 발행취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCancelCertificate() {

  }

  /*******************************************************************************
    설  명 : 필증발급관리대장출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openMandatory() {
    let search = encodeURIComponent( JSON.stringify( this.search ) );

    let url = '/books/mandatory/print/mandatory?search=' + search;

    window.open(url, 'booksMandatoryPrintMandatory', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1450, height=800, left=0, top=0' );
  }   
}
