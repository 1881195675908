/*******************************************************************************
  설  명 : 검사이력조회
  작성일 : 2020-02-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbCalendar, NgbModal, ModalDismissReasons, NgbModalOptions, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { MemberService } from '@app/service/member.service';
import { RegionService } from '@app/service/region.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { ReceptionService } from '@app/service/reception.service';
import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Select2OptionData } from 'ng2-select2';

import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

import { AddGiroComponent } from '@app/page/inspect/inspect-history/add-giro/add-giro.component';
import { EditDateComponent } from '@app/page/inspect/inspect-history/edit-date/edit-date.component';

import { PrintGiroComponent } from '@app/page/inspect/inspect-history/print/print-giro/print-giro.component';
import { PrintDmComponent } from '@app/page/inspect/inspect-history/print/print-dm/print-dm.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-inspect-history',
  templateUrl: './inspect-history.component.html',
  styleUrls: ['./inspect-history.component.scss']
})
export class InspectHistoryComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 공통코드 데이터 리스트
  officeList: any = [];
  regionList: any = [];
  select2List: Array<Select2OptionData>;
  cityList: any = [];
  memberTypeList: any = [];
  receptionStatusList: any = [];
  inspectionAgencyList: any = [];
  inspectionTypeList: any = [];
  memberList: any = [];
  certificateStatusList: any = [];
  freezeGasList: any = [];
  usageCodeList: any = [];


  officeCd:string;

  memberInfo: any = {};
  checkGiro: boolean = false;

   calendarInfo: any = {
    year: moment().format('YYYY'),
    month: moment().format('MM'),
    sdate: moment().format('YYYY-MM-01'),
    edate: moment().add(1, 'month').date(0).format('YYYY-MM-DD'),
    start_day: '',
    end_day: ''
  }

  // 검색 필드
  public search = {
    //receptionSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
    //receptionEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
    receptionSdate: '',
    receptionEdate: '',
    searchField: 'inspectionSdate',
    //inspectionSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
    //inspectionEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
    inspectionSdate: '',
    inspectionEdate: '',
    searchField1: 'facilityName',
    searchText: '',
    officeCode: '',
    regionCode: '',
    cityCode: [],
    memberType: '',
    receptionStatus: '2',
    inspectionAgency: '',
    inspectionYn: '',
    inspectionType: '',
    memberNo: '',
    passYn: '',
    recipient: '',
    companyCode: '',
    facilityCode: '',
    useYn: '0',
    certificateStatus:'',
    approvalYn: '',
    freezeGas: '',
    usageCode: '',
    capacityStart: '',
    capacityEnd: '',
  }

  // 주소 선택
  addressType: any = 'F';

  // 그리드 관련 선언
  selectedRows: number = 0;
  dataList: any = [];
  feeTotal: any = {};

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };



  select2Options = {
    multiple: true
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private authService: AuthService,
    private memberService: MemberService,
    private regionService: RegionService,
    private commonCodeService: CommonCodeService,
    private receptionService: ReceptionService,
    private calendar: NgbCalendar,
    private globals: Globals,
    private ngbDatepickerConfig: NgbDatepickerConfig

  ) {


    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'rowIndex', cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, valueGetter: "node.rowIndex + 1", width:80 , pinned: 'left' },
      { headerName: '순번', field: 'receptionSeq', cellClass: 'cp center',hide:true},
      { headerName: '업체명', field: 'companyName', cellClass: 'cp' , width:150 , pinned: 'left'},
      { headerName: '업체코드', field: 'companyCode', cellClass: 'cp', width:90 , pinned: 'left'},
      { headerName: '시설명', field: 'facilityName', cellClass: 'cp', width:150 , pinned: 'left'},
      { headerName: '시설코드', field: 'facilityCodeFormat', cellClass: 'cp', width:90 , pinned: 'left'},
      { headerName: '회원구분', field: 'memberGradeName', cellClass: 'cp center', width:90 },
      { headerName: '시/도', field: 'regionName', cellClass: 'cp center', width:90 },
      { headerName: '시/군/구', field: 'cityName', cellClass: 'cp center', width:90 },
      { headerName: '접수일', field: 'receptionDate', cellClass: 'cp center', width:90 },
      { headerName: '접수자', field: 'receptionMemberName', cellClass: 'cp center', width:90 },
      { headerName: '접수번호', field: 'receptionNumberFormat', cellClass: 'cp center', width:100 },
      { headerName: '접수상태', field: 'receptionStatusName', cellClass: 'cp center', width:90 },
      { headerName: '검사종류', field: 'inspectionTypeName', cellClass: 'cp center', width:90 },
      { headerName: '냉동톤', field: 'frozenCapacity', cellClass: 'cp center' , width:90},
      { headerName: '냉매', field: 'freezeName', cellClass: 'cp center', width:90 },
      { headerName: '사용용도', field: 'usageName', cellClass: 'cp center', width:90 },
      { headerName: '수수료', field: 'inspectionFeeTotal', cellClass: 'cp right', valueFormatter: currencyFormatter, width:90 },
      { headerName: '예정일', field: 'planDate', cellClass: 'cp center' , width:90},
      { headerName: '검사일', field: 'inspectionDate', cellClass: 'cp center' , width:90},
      { headerName: '이전검사완료일', field: 'prevInspectionDate', cellClass: 'cp center' , width:90},
      { headerName: '검사여부', field: 'inspectionYnName', cellClass: 'cp center' , width:90},
      { headerName: '검사기관', field: 'inspectionAgencyName', cellClass: 'cp center' , width:90},
      { headerName: '검사결과', field: 'passYnName', cellClass: 'cp center', width:90,
        cellStyle: function(params) {
          if( params.data.passYnName == '합격' ) {
            return {color: '#5cb85c'};
          } else {
            return {color: '#d9534f'};
          }
        }
      },
      { headerName: '검사원', field: 'memberName', cellClass: 'cp center', width:90 },
      { headerName: '필증번호', field: 'certificateNumber', cellClass: 'cp center', width:90 },
      { headerName: '발급일', field: 'certificateDate', cellClass: 'cp center', width:90 },
      { headerName: '필증여부', field: 'certificateStatusName', cellClass: 'cp center', width:90 },
      { headerName: '결재여부', field: 'approvalYnName', cellClass: 'cp center', width:90 },
      { headerName: '사용여부', field: 'useStatusName', cellClass: 'cp center', width:90 },
      { headerName: '시설주소', field: 'facilityAddressFull', cellClass: 'cp center', width:200 },
      { headerName: '검사가능여부', field: 'trmYnName', cellClass: 'cp center', hide:true },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    ngbDatepickerConfig.minDate = {year: 1900, month: 1, day: 1};
    ngbDatepickerConfig.maxDate = {year: 2099, month: 12, day: 31};
    ngbDatepickerConfig.displayMonths = 1;
    ngbDatepickerConfig.firstDayOfWeek = 7; // 일요일 먼저


    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 로그인 정보 가져오기
    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;

      if( this.memberInfo.id == 'admin' || this.memberInfo.id == 'dudwn1020' )
        this.checkGiro = true;
    });

    // 공통코드 리스트 가져오기
    this.getCommonList();

    // 검사이력 리스트 가져오기
    //this.getReceptionList();
  }

  /*******************************************************************************
    설  명 : 뷰 처리 완료 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {

    // indicator 표시
    setTimeout (() => {
      //if( this.dataList.length < 1 )
        //this.globals.indicator.next(true);
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      //receptionSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
      //receptionEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
      receptionSdate: '',
      receptionEdate: '',
      searchField: 'inspectionSdate',
      //inspectionSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
      //inspectionEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
      inspectionSdate: '',
      inspectionEdate: '',
      searchField1: 'facilityName',
      searchText: '',
      officeCode: this.officeCd,
      regionCode: '',
      cityCode: [],
      memberType: '',
      receptionStatus: '2',
      inspectionAgency: '',
      inspectionYn: '',
      inspectionType: '',
      memberNo: '',
      passYn: '',
      recipient: '',
      companyCode: '',
      facilityCode: '',
      useYn: '0',
      certificateStatus:'',
      approvalYn: '',
      freezeGas: '',
      usageCode: '',
      capacityStart: '',
      capacityEnd: '',
    }

    this.dataList = [];
    this.feeTotal = {};
    this.select2List = [];

//    this.getReceptionList();
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 사무소
    await this.commonCodeService.getOfficeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.officeList = response.data;
      } else {
        this.officeList = [];
      }
    });

    // 시/도
    await this.regionService.getRegionSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.regionList = response.data;
      } else {
        this.regionList = [];
      }
    });

    // 회원구분
    await this.commonCodeService.getMemberTypeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.memberTypeList = response.data;
      } else {
        this.memberTypeList = [];
      }
    });

    // 접수상태
    await this.commonCodeService.getReceptionStatusSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.receptionStatusList = response.data;
      } else {
        this.receptionStatusList = [];
      }
    });

    // 검사기관
    await this.commonCodeService.getInspectionAgencySelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.inspectionAgencyList = response.data;
      } else {
        this.inspectionAgencyList = [];
      }
    });

    // 검사종류
    await this.commonCodeService.getInspectionTypeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.inspectionTypeList = response.data;
      } else {
        this.inspectionTypeList = [];
      }
    });

    // 검사원
    await this.memberService.getMemberSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.memberList = response.data;
      } else {
        this.memberList = [];
      }
    });

    // 필증발행여부
    await this.commonCodeService.getCertificateStatusSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.certificateStatusList = response.data;
      } else {
        this.certificateStatusList = [];
      }
    });

    // 냉매
    await this.commonCodeService.getFreezeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.freezeGasList = response.data;
      } else {
        this.freezeGasList = [];
      }
    });
    // 사용용도
    await this.commonCodeService.getUseSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.usageCodeList = response.data;
      } else {
        this.usageCodeList = [];
      }
    });
    // 사무소코드
    await this.memberService.getMemberOffice().then( response => {
     // console.log(response.data['officeCd']);
      if( response.ResultCode ) {
        this.officeCd = response.data['officeCd'];
      } else {
        this.officeCd = '';
      }
    });

    this.searchInit();

  }

  /*******************************************************************************
    설  명 : 시/구/군 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCitySelect() {
    await this.regionService.getCitySelect( this.search.regionCode ).then( response => {
      //console.log(response);
      /*
      if( response.ResultCode ) {
        this.cityList = response.data;
      } else {
        this.cityList = [];
      }
      */
      if( response.ResultCode ) {
        this.cityList = [];

        response.data.forEach( row => {
          this.cityList.push({
            text: row.cityName,
            id: row.cityCode
          });
        });

        this.select2List = this.cityList;
      }
    });
  }

  /*******************************************************************************
    설  명 : 검사이력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getReceptionList() {
    if(
        ( this.search.receptionSdate == '' )
        &&
        ( this.search.inspectionSdate == '' )
        &&
        ( this.search.regionCode == '' )
        &&
        ( this.search.useYn == '' )
        &&
        ( this.search.officeCode == '' )
        &&
        ( this.search.searchText == '' )
        &&
        ( this.search.memberType == '' )
        &&
        ( this.search.inspectionAgency == '' )
        &&
        ( this.search.inspectionYn == '' )
        &&
        ( this.search.inspectionType == '' )
        &&
        ( this.search.passYn == '' )
        &&
        ( this.search.companyCode == '' )
        &&
        ( this.search.facilityCode == '' )
        &&
        ( this.search.certificateStatus == '' )
        &&
        ( this.search.approvalYn == '' )
        &&
        ( this.search.freezeGas == '' )
        &&
        ( this.search.usageCode == '' )
        &&
        ( this.search.capacityStart == '' )
      ) {
      this.toastrService.error( '접수상태 외에 검색조건을 하나이상 입력해야합니다.' );
      return false;
    }

    this.globals.indicator.next(true);
    await this.receptionService.getReceptionList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.dataList = response.data;
        this.feeTotal = response.fee;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady($event) {
    this.gridApi = $event.api;
    this.gridColumnApi = $event.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid rowData 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDataChanged($event) {
    this.selectedRows = $event.api.gridOptionsWrapper.gridOptions.rowData.length;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 더블클릭 시 처리 - 시설검사관리 새창
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClicked( $event ) {
    let url = '/inspect/facility?fcode=' + $event.data.facilityCode + '&useType=';

    let width = $event.event.view.screen.availWidth;
    let height = $event.event.view.screen.availHeight;

    window.open(url, 'inspectFacility', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width='+ width +', height='+ height +', left=0, top=0, fullscreen=yes' );
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getReceptionList();
    }
  }

  /*******************************************************************************
    설  명 : 시/도 선택 시
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setRegionChange() {
    this.search.cityCode = [];

    this.getCitySelect();
  }

  /*******************************************************************************
    설  명 : select2 변경 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changed( event: any ): void {
    //console.log(event);
    this.search.cityCode = event.value;
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: any ) {
    if( check == 1 ) {
      this.search.receptionSdate = this.utilService.getDate( this.calendar.getToday() );

    } else if( check == 2 ) {
      this.search.receptionEdate = this.utilService.getDate( this.calendar.getToday() );

    } else if( check == 3 ) {
      this.search.inspectionSdate = this.utilService.getDate( this.calendar.getToday() );

    } else if( check == 4 ) {
      this.search.inspectionEdate = this.utilService.getDate( this.calendar.getToday() );
    }

    obj.close();
  }

  /*******************************************************************************
    설  명 : 지로생성
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  addGiro() {
    const modalRef = this.modalService.open(AddGiroComponent, options);

    modalRef.result.then((result) => {

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 일자변경
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  editDate() {
    let rowsSelection = this.gridApi.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '변경할 검사이력을 선택하시기 바랍니다.' );
      return false;
    } else {
      const modalRef = this.modalService.open(EditDateComponent, options);

      let seqArray: any = [];
      rowsSelection.forEach(item => {
        seqArray.push( item.receptionSeq );
      });

      modalRef.componentInstance.params = seqArray;

      modalRef.result.then((result) => {
        if( result ) {
          this.getReceptionList();
        }
      }, (reason) => {
      });
    }
  }

  /*******************************************************************************
    설  명 : 접수증 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openReceipt() {
    let rowsSelection = this.gridApi.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '출력할 검사내역을 선택하시기 바랍니다.' );
      return false;
    }

    let tmp: any = [];
    let check: any = true;
    rowsSelection.forEach(item => {
      if( item.receptionNumber == '' || item.receptionNumber == false ) check = false;

      tmp.push({
        seq: item.receptionSeq,
        code: item.facilityCode
      });
    });

    if( check === false ) {
      this.toastrService.error( '선택한 것 중에서 검사 접수되지 않은 내역이 있습니다.' );
      return false;
    } else {
      let params = JSON.stringify( tmp );
      sessionStorage.setItem( 'inspectFacilityPrintReceipt', params );

      let url = '/inspect/facility/print/receipt';

      window.open(url, 'inspectFacilityPrintReceipt', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
    }
  }

  /*******************************************************************************
    설  명 : 지로 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openGiro() {
    let rowsSelection = this.gridApi.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '출력할 검사이력을 선택하시기 바랍니다.' );
      return false;
    }

    let seqArray: any = [];
    rowsSelection.forEach(item => {
//      if( item.receptionStatus == '2' || item.receptionStatus == '5' || item.inspectionYn == '1' || item.trmYn == 'N' ) {
      if( item.receptionStatus == '2' || item.receptionStatus == '5' || item.inspectionYn == '1'  ) {
      } else {
        if ( item.inspectionType == '0' &&  item.trmYn == 'N')
        {
        }
        else
        {
          seqArray.push( item.receptionSeq );
        }

      }
    });

    if( seqArray.length < 1 ) {
      this.toastrService.error( '출력 가능한 데이터가 없습니다.' );
      return false;
    }

    let params = JSON.stringify( { type: this.addressType, seq: seqArray, recipient: this.search.recipient } );
    sessionStorage.setItem( 'inspectHistoryPrintGiro', params );

    let url = '/inspect/history/print/giro';

    window.open(url, 'inspectHistoryPrintGiro', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : DM 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openDm() {
    let rowsSelection = this.gridApi.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '출력할 검사이력을 선택하시기 바랍니다.' );
      return false;
    }

    let seqArray: any = [];
    rowsSelection.forEach(item => {
      seqArray.push( item.receptionSeq );
    });

    let params = JSON.stringify( { type: this.addressType, seq: seqArray, recipient: this.search.recipient } );
    sessionStorage.setItem( 'inspectHistoryPrintDm', params );

    let url = '/inspect/history/print/dm';

    window.open(url, 'inspectHistoryPrintDm', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
  }


}
