/*******************************************************************************
  설  명 : 업체 / 시설정보관리
  작성일 : 2020-02-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbCalendar, NgbModal, ModalDismissReasons, NgbModalOptions , NgbDatepickerConfig} from '@ng-bootstrap/ng-bootstrap';

import { Globals } from '@app/service/globals.service';
import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { MemberService } from '@app/service/member.service';
import { RegionService } from '@app/service/region.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { CompanyfacilityService } from '@app/service/companyfacility.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

import { ModalFacilityInfoComponent } from '@app/components/modal-facility-info/modal-facility-info.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 공통코드 데이터 리스트
  memberTypeList: any = [];
  statusList: any = [];
  regoinList: any = [];
  cityList: any = [];
  officeList: any = [];
  memberList: any = [];

  officeCd: string;

  calendarInfo: any = {
    year: moment().format('YYYY'),
    month: moment().format('MM'),
    sdate: moment().format('YYYY-MM-01'),
    edate: moment().add(1, 'month').date(0).format('YYYY-MM-DD'),
    start_day: '',
    end_day: ''
  };

  // 검색 필드
  public search = {
    writeSdate: '',
    writeEdate: '',
    searchField: 'facilityName',
    searchText: '',
    memberType: '',
    regionCode: '',
    cityCode: '',
    officeCode: '',
    memberNo: '',
    useYn: '0',
    companyCode: ''
  };

  // 그리드 관련 선언
  selectedRows: number = 0;
  companyList: any = [];
  facilityList: any = [];

  facilityInfo: any = {};

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiFacility: any;
  gridColumnApiFacility: any;
  columnDefsFacility: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;
  rowSelectionFacility: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private memberService: MemberService,
    private regionService: RegionService,
    private commonCodeService: CommonCodeService,
    private companyfacilityService: CompanyfacilityService,
    private globals: Globals,
    private calendar: NgbCalendar,
    private ngbDatepickerConfig: NgbDatepickerConfig,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '순번', field: 'rowIndex', cellClass: 'cp center',headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,  checkboxSelection: true,valueGetter: "node.rowIndex + 1", width:80  },
      {headerName: '업체명', field: 'companyName', cellClass: 'cp',  width:200 },
      {headerName: '업체코드', field: 'companyCode', cellClass: 'cp center' , width:85},
      {headerName: '시설수', field: 'facilityCount', cellClass: 'cp center', width:80},
      {headerName: '사업자번호', field: 'BusinessNo', cellClass: 'cp center', width:80 , hide:true},
      {headerName: '대표자명', field: 'ceoName', cellClass: 'cp center', width:80, hide:true},
      {headerName: '전화번호', field: 'telNo', cellClass: 'cp center', width:80, hide:true},
      {headerName: '팩스번호', field: 'faxNo', cellClass: 'cp center', width:80, hide:true},
      {headerName: '휴대폰번호', field: 'phoneNo', cellClass: 'cp center', width:80, hide:true},
      {headerName: '우편번호', field: 'zipCode', cellClass: 'cp center', width:80, hide:true},
      {headerName: '우편주소', field: 'address', cellClass: 'cp center', width:80, hide:true},
      {headerName: '상세주소', field: 'addressDetail', cellClass: 'cp center', width:80, hide:true},
      {headerName: '메모', field: 'memo', cellClass: 'cp center', width:80, hide:true},
    ];

    // ag grid 컬럼 선언
    this.columnDefsFacility = [
      {headerName: '시설명', field: 'facilityName', cellClass: 'cp', width:250, checkboxSelection: true },
      {headerName: '시설코드', field: 'facilityCodeFormat', cellClass: 'cp' , width:85 },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";
    this.rowSelectionFacility = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    ngbDatepickerConfig.minDate = {year: 1900, month: 1, day: 1};
    ngbDatepickerConfig.maxDate = {year: 2099, month: 12, day: 31};
    ngbDatepickerConfig.displayMonths = 1;
    ngbDatepickerConfig.firstDayOfWeek = 7; // 일요일 먼저

  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 회원구분
    await this.commonCodeService.getMemberTypeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.memberTypeList = response.data;
      } else {
        this.memberTypeList = [];
      }
    });

    // 폐지여부
    await this.commonCodeService.getStatusSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.statusList = response.data;
      } else {
        this.statusList = [];
      }
    });

    // 시/도
    await this.regionService.getRegionSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.regoinList = response.data;
      } else {
        this.regoinList = [];
      }
    });

    // 사무소
    await this.commonCodeService.getOfficeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.officeList = response.data;
      } else {
        this.officeList = [];
      }
    });

    // 지역담당
    await this.memberService.getMemberSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.memberList = response.data;
      } else {
        this.memberList = [];
      }
    });

    // 사무소코드
    await this.memberService.getMemberOffice().then( response => {
     // console.log(response.data['officeCd']);
      if( response.ResultCode ) {
        this.officeCd = response.data['officeCd'];
      } else {
        this.officeCd = '';
      }
    });
  }

  /*******************************************************************************
    설  명 : 업체 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCompanyList() {
    this.companyList = [];
    this.globals.indicator.next(true);

    await this.companyfacilityService.getCompanyList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.companyList = response.data;

        this.facilityList = [];
        this.facilityInfo = {};
        if( this.companyList.length > 0 ) {
          this.search.companyCode = this.companyList[0].companyCode;
          this.getFacilityList(0);
        } else {
          this.globals.indicator.next(false);
        }
      } else {
        this.globals.indicator.next(false);
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 시설리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getFacilityList( facilitySeq ) {
    await this.companyfacilityService.getFacilityList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.facilityList = response.data;

        if( this.facilityList.length > 0 ) {
          this.facilityInfo = this.facilityList[0];
        }

        if( facilitySeq !== 0 ) {
          this.setFacilitySelected( facilitySeq );
        }
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : 시/구/군 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCitySelect() {
    this.cityList = [];

    await this.regionService.getCitySelect( this.search.regionCode ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.cityList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid rowData 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onRowDataChanged($event) {
    this.selectedRows = $event.api.gridOptionsWrapper.gridOptions.rowData.length;
  }

   /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 공통코드 리스트 가져오기
    this.getCommonList();
    this.globals.indicator.next(false);
    // 업체 리스트 가져오기
 //   this.getCompanyList();

  }

  /*******************************************************************************
    설  명 : 뷰 처리 완료 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {

    // indicator 표시
    setTimeout (() => {
//      if( this.companyList.length < 1 )
//       this.globals.indicator.next(true);
    });

  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      writeSdate: '',
      writeEdate: '',
      searchField: 'facilityName',
      searchText: '',
      memberType: '',
      regionCode: '',
      cityCode: '',
      officeCode: this.officeCd,
      memberNo: '',
      useYn: '',
      companyCode: ''
    };

//    this.getCompanyList();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: any ) {
    if( check == 1 ) {
      this.search.writeSdate = this.utilService.getDate( this.calendar.getToday() );
    } else if( check == 2 ) {
      this.search.writeEdate = this.utilService.getDate( this.calendar.getToday() );
    }

    obj.close();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : 업체리스트 ag grid 행 클릭 시 처리 - 업체 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.facilityInfo = {};

    this.companyList.forEach(item => {
      if( item.companySeq == $event.data.companySeq ) {
        this.facilityInfo = item;
      }
    });

    this.search.companyCode = $event.data.companyCode;

    this.getFacilityList(0);
  }

  /*******************************************************************************
    설  명 : 업체리스트 ag grid 행 키보드로 조작 시 처리 - 업체 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellKeyPress($event) {
    var keyEventCode = $event.event.code;

    if( keyEventCode === "Space" ) {
      this.onCellClicked($event);
    }
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyFacility(params) {
    this.gridApiFacility = params.api;
    this.gridColumnApiFacility = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChangedFacility($event) {
    let filteredRowCount = 0;
    this.gridApiFacility.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 시설리스트 ag grid 행 클릭 시 처리 - 시설 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedFacility($event) {
    this.facilityInfo = {};
    this.facilityList.forEach(item => {
      if( item.facilitySeq == $event.data.facilitySeq ) {
        this.facilityInfo = item;
      }
    });
  }

  /*******************************************************************************
    설  명 : 시설리스트 ag grid 행 더블클릭 시 처리 - 시설 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClickedFacility($event) {
    this.addFacility( $event.data.facilitySeq );
  }

  /*******************************************************************************
    설  명 : 시설리스트 더블클릭 후 해당 시설 자동선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setFacilitySelected( facilitySeq ) {
    let nodes = '';

    setTimeout(() => {
      nodes = this.gridApiFacility.getSelectedNodes();

      if( nodes.length < 1 ) {
        this.gridApiFacility.forEachNode(function(node) {
          if( node.data.facilitySeq == facilitySeq ) {
            node.setSelected(true);
          }
        });
      }
    }, 500);

    this.facilityList.forEach(item => {
      if( item.facilitySeq == facilitySeq ) {
        this.facilityInfo = item;
      }
    });
  }

  /*******************************************************************************
    설  명 : 업체 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addCompany() {
    const modalRef = this.modalService.open(ModalFacilityInfoComponent, options);

    modalRef.componentInstance.companySeq = 0;
    modalRef.componentInstance.facilitySeq = 0;

    modalRef.componentInstance.emitData.subscribe(($value) => {
      //console.log($value);
      if( $value ) {
        this.search.searchField = 'companyCode';
        this.search.searchText = $value;
      }
    });

    modalRef.result.then((result) => {
      //if( result ) {
        this.getCompanyList();
      //}
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 업체 및 시설 추가/수정
    입력값 : seq = 0 일 경우 추가, 이외에는 수정
    리턴값 : 없음
  *******************************************************************************/
  addFacility(facilitySeq) {
    const modalRef = this.modalService.open(ModalFacilityInfoComponent, options);

    modalRef.componentInstance.companySeq = this.facilityInfo.companySeq;
    modalRef.componentInstance.facilitySeq = facilitySeq;

    modalRef.result.then((result) => {
      if( this.facilityInfo.companyCode !== undefined ) {
        this.search.companyCode = this.facilityInfo.companyCode;
        this.getFacilityList( facilitySeq );
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getCompanyList();
    }
  }

  /*******************************************************************************
    설  명 : 시/도 선택 시
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setRegionChange() {
    this.search.cityCode = '';

    this.getCitySelect();
  }

  /*******************************************************************************
    설  명 : DM 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openDm() {
    let rowsSelection = this.gridApi.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '출력할 업체를 선택하시기 바랍니다.' );
      return false;
    }

    let seqArray: any = [];
    rowsSelection.forEach(item => {
      seqArray.push( item.companySeq );
    });

    let params = JSON.stringify( { type: 'C', seq: seqArray, recipient: '' } );
    sessionStorage.setItem( 'inspectHistoryPrintDm', params );

    let url = '/inspect/history/print/dm';

    window.open(url, 'inspectHistoryPrintDm', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
  }
}
