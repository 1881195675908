/*******************************************************************************
  설  명 : 검사지역정보관리 구/군 추가/수정
  작성일 : 2020-02-26
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { CommonService } from '@app/service/common.service';
import { Globals } from '@app/service/globals.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { MemberService } from '@app/service/member.service';
import { RegionService } from '@app/service/region.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-region-add',
  templateUrl: './region-add.component.html',
  styleUrls: ['./region-add.component.scss']
})
export class RegionAddComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역 변수
  *******************************************************************************/
  @Input() regionSeq: any;
  @Input() regionCode: any;
  @Input() regionName: any;
  @Input() citySeq: any;
  @Input() cityCode: any;
  @Input() cityName: any;

  public officeList = [];
  public memberList = [];
  public branchList = [];

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      regionCode:["", [Validators.required] ],
      regionName:["", [] ],
      cityCode:["", [] ],
      cityName:["", [] ],
      officeCode:["", [Validators.required] ],
      memberNo:["", [] ],
      managerBranchSeq:["", [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private globals: Globals,
    private modalService: NgbModal,
    private commonCodeService: CommonCodeService,
    private memberService: MemberService,
    private regionService: RegionService,
    public activeModal: NgbActiveModal,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.form.patchValue({
      regionCode: this.regionCode,
      regionName: this.regionName,
      cityCode: this.cityCode,
      cityName: this.cityName
    });

    // 공통코드 리스트 가져오기
    this.getCommonList();

    // 검사지역 상세정보 가져오기
    this.getRegionInspectableInfo();
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 사무소
    await this.commonCodeService.getOfficeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        response.data.forEach( row => {
          this.officeList.push({
            title: row.commonName,
            value: row.commonCode
          });
        });
      } else {
        this.officeList = [];
      }
    });

    // 지역담당
    await this.memberService.getMemberSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        response.data.forEach( row => {
          this.memberList.push({
            title: row.name,
            value: row.memberNo
          });
        });
      } else {
        this.memberList = [];
      }
    });

    // 관리지사
    await this.commonCodeService.getkgsBranchSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        response.data.forEach( row => {
          this.branchList.push({
            title: row.branchName,
            value: row.uid
          });
        });
      } else {
        this.branchList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 검사지역 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getRegionInspectableInfo() {
    await this.regionService.getRegionInspectableInfo( this.citySeq ).then( response => {
      if( response.ResultCode ) {
        this.form.patchValue( response.data );
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 검사지역 정보 일괄 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setRegionInspectableInfoUpdateAll() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {

      // 일괄 수정 시 구/군코드 제외
      this.form.patchValue({ cityCode: '' });

      this.regionService.setRegionInspectableInfoUpdate( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

  /*******************************************************************************
    설  명 : 검사지역 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setRegionInspectableInfoUpdate() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.regionService.setRegionInspectableInfoUpdate( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

}
