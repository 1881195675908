import { Injectable, NgModuleFactoryLoader } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';
import { MemberService } from '@app/service/member.service';
import { UtilService } from '@app/service/util.service';

@Injectable()
export class AuthGuard implements CanActivate {

  public redirectURL: string = '';

  constructor(
    private router: Router,
    private globals: Globals,
    private member: MemberService,
    private toastrService: ToastrService,
    private readonly loader: NgModuleFactoryLoader,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    this.redirectURL = state.url;

    let result = this.member.getMemberInfo().then( response => {
      if( response['ResultCode'] == true ) {
        this.authService.login( response );
        return true;

      } else {
        this.toastrService.error(response['ResultMessage'], '접근불가');
        this.authService.logout();
        this.router.navigate(
          ['/login'],
          {
            queryParams: {},
            relativeTo: this.activatedRoute,
            queryParamsHandling: '', // remove to replace all query params by provided
          }
        );

        return false;
      }
    })
    .catch(response => {
      return false;
    });

    return result;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return this.canActivate(next, state);
  }
}