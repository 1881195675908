/*******************************************************************************
  설  명 : 상담이력조회
  작성일 : 2020-02-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbCalendar, NgbModal, ModalDismissReasons, NgbModalOptions , NgbDatepickerConfig} from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { Globals } from '@app/service/globals.service';
import { MemberService } from '@app/service/member.service';
import { RegionService } from '@app/service/region.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { ReceptionService } from '@app/service/reception.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-inspect-consult',
  templateUrl: './inspect-consult.component.html',
  styleUrls: ['./inspect-consult.component.scss']
})
export class InspectConsultComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 공통코드 데이터 리스트
  regionList: any = [];
  cityList: any = [];
  officeList: any = [];

  officeCd:string;

   calendarInfo: any = {
    year: moment().format('YYYY'),
    month: moment().format('MM'),
    sdate: moment().format('YYYY-MM-01'),
    edate: moment().add(1, 'month').date(0).format('YYYY-MM-DD'),
    start_day: '',
    end_day: ''
  }

  // 검색 필드
  public search = {
    searchField: 'facilityName',
    searchText: '',
    //counselingSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
    //counselingEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
    counselingSdate: '',
    counselingEdate: '',
    officeCode: '',
    regionCode: '',
    cityCode: '',
  }

  // 그리드 관련 선언
  selectedRows: number = 0;
  dataList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private memberService: MemberService,
    private regionService: RegionService,
    private commonCodeService: CommonCodeService,
    private receptionService: ReceptionService,
    private calendar: NgbCalendar,
    private globals: Globals,
    private ngbDatepickerConfig: NgbDatepickerConfig
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'counselingSeq', cellClass: 'cp center' , hide: true },
      { headerName: '순번', field: 'rowIndex', cellClass: 'cp center', width: 80, cellRenderer: 'agGridHtmlComponent',
        valueGetter: function(params) {
          if( params.data.newDateCheck < 7 ) {
            return '<span class="badge badge-danger f12 fn">N</span>' + ' ' + (params.node.rowIndex + 1);
          } else {
            return params.node.rowIndex + 1;
          }
        }
      },
      { headerName: '업체명', field: 'companyName', cellClass: 'cp', width: 200 },
      { headerName: '업체코드', field: 'companyCode', cellClass: 'cp center', width: 90 },
      { headerName: '시설명', field: 'facilityName', cellClass: 'cp', width: 250 },
      { headerName: '시설코드', field: 'facilityCodeFormat', cellClass: 'cp center', width: 90 },
      { headerName: '상담일', field: 'counselingDate', cellClass: 'cp center' , width: 90 },
      { headerName: '상담구분', field: 'counselingTypeName', cellClass: 'cp center', width: 120 },
      { headerName: '상담자', field: 'memberName', cellClass: 'cp center', width: 90 },
      { headerName: '상담내용', field: 'counselingMemo', cellClass: 'cp', width: 550 },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    ngbDatepickerConfig.minDate = {year: 1900, month: 1, day: 1};
    ngbDatepickerConfig.maxDate = {year: 2099, month: 12, day: 31};
    ngbDatepickerConfig.displayMonths = 1;
    ngbDatepickerConfig.firstDayOfWeek = 7; // 일요일 먼저

  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 공통코드 리스트 가져오기
    this.getCommonList();

    // 상담이력 리스트 가져오기
//    this.getCounselingList();
  }

  /*******************************************************************************
    설  명 : 뷰 처리 완료 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {

    // indicator 표시
    setTimeout (() => {
      if( this.dataList.length < 1 )
        this.globals.indicator.next(true);
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchField: 'facilityName',
      searchText: '',
      //counselingSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
      //counselingEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
      counselingSdate: '',
      counselingEdate: '',
      officeCode: this.officeCd,
      regionCode: '',
      cityCode: '',
    }

    this.getCounselingList();
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 시/도
    await this.regionService.getRegionSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.regionList = response.data;
      } else {
        this.regionList = [];
      }
    });

    // 사무소
    await this.commonCodeService.getOfficeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.officeList = response.data;
      } else {
        this.officeList = [];
      }
    });
    // 사무소코드
    await this.memberService.getMemberOffice().then( response => {
     // console.log(response.data['officeCd']);
      if( response.ResultCode ) {
        this.officeCd = response.data['officeCd'];
      } else {
        this.officeCd = '';
      }
    });
     this.searchInit();


  }

  /*******************************************************************************
    설  명 : 상담이력 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCounselingList() {
    await this.receptionService.getCounselingList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.dataList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : 시/구/군 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCitySelect() {
    this.cityList = [];

    await this.regionService.getCitySelect( this.search.regionCode ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.cityList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : 시설검사관리 새창
    입력값 :
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClicked( $event ) {
    let url = '/inspect/facility?fcode=' + $event.data.facilityCode;

    let width = $event.event.view.screen.availWidth;
    let height = $event.event.view.screen.availHeight;

    window.open(url, 'inspectFacility', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width='+ width +', height='+ height +', left=0, top=0, fullscreen=yes' );
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getCounselingList();
    }
  }

  /*******************************************************************************
    설  명 : 시/도 선택 시
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setRegionChange() {
    this.search.cityCode = '';

    this.getCitySelect();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.counselingSdate = this.utilService.getDate( this.calendar.getToday() );

    } else {
      this.search.counselingEdate = this.utilService.getDate( this.calendar.getToday() );
    }

    obj.close();
  }

}
