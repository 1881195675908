import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { config } from '@app/service/config.service';
import { ReportService } from '@app/service/report.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-print-books-certificate1',
  templateUrl: './print-books-certificate1.component.html',
  styleUrls: ['./print-books-certificate1.component.scss']
})
export class PrintBooksCertificate1Component implements OnInit {

  private seqArray: any;

  public certificateList: any = [];

  public year: any = moment().format('YYYY');
  public month: any = moment().format('MM');
  public day: any = moment().format('DD');

  public hrmsURL: string = config.hrmsUrl;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private reportService: ReportService,
  ) { }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  ngOnInit() {
    // 파라미터 가져오기
    this.seqArray = sessionStorage.getItem( 'booksInspectPrintCertificate1' );
    sessionStorage.removeItem( 'booksInspectPrintCertificate1' );

    this.getInspectionCertificate();
  }

  /*******************************************************************************
    설  명 : view 로딩 후 생성자
  *******************************************************************************/
  ngAfterViewInit() {
    var data = $(".table tbody").children();
    setTimeout (() => {
      if( typeof data[0] !== undefined ) {
        window.print();
      }
    }, 500);
  }

  /*******************************************************************************
    설  명 : 검사부적합통지서 정보 가져오기
  *******************************************************************************/
  getInspectionCertificate() {
    this.reportService.getInspectionCertificate( this.seqArray ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.certificateList = response.data;

        window.opener.fn.namespace.publicFunc();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

}
