import { Component, OnInit, Input, AfterContentInit, Directive, ElementRef, Injectable } from '@angular/core';
import { NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

const I18N_VALUES = {
  ko: {
    weekdays: ['월', '화', '수', '목', '금', '토', '일'],
    months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  }
};

@Injectable()
export class I18n {
  language = 'ko';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
}

@Component({
  selector: 'inputEx',
  templateUrl: './input-ex.component.html',
  styleUrls: ['./input-ex.component.scss'],
  providers: [
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    NgbDatepickerConfig
  ]
})
export class InputExComponent implements OnInit {

  @Input() type: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() autofocus: boolean;
  @Input() formGroup:any;
  @Input() formErrors:any;
  @Input() readonly: boolean;
  @Input() size: string;
  @Input() data: any;
  @Input() addOn;
  @Input() addOnText: string;
  @Input() change;
  @Input() rows: string;
  @Input() cols: string;
  @Input() options: any;
  @Input() onChanged;
  @Input() title: string;
  @Input() dateCustomChange: any;

  public today: string;

  constructor(
    private el: ElementRef,
    public calendar: NgbCalendar,
    private ngbDatepickerConfig: NgbDatepickerConfig,
    private toastrService: ToastrService,
  ) {
    ngbDatepickerConfig.minDate = {year: 1900, month: 1, day: 1};
    ngbDatepickerConfig.maxDate = {year: 2099, month: 12, day: 31};
    ngbDatepickerConfig.displayMonths = 1;
    ngbDatepickerConfig.firstDayOfWeek = 7; // 일요일 먼저

    this.today = moment().format('YYYY-MM-DD');
  }

  ngOnInit() {
    if( typeof this.type == 'undefined' ) this.type = 'text';
  }

  public ngAfterContentInit() {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 500);
  }

  getToday( obj ) {
    let data: any = {};

    //data[this.name] = moment( this.calendar.getToday() ).format('YYYY-MM-DD');
    data[this.name] = this.calendar.getToday();

    this.formGroup.patchValue(data);

    obj.close();
  }

  resetDate( obj ) {
    let data: any = {};

    data[this.name] = null;

    this.formGroup.patchValue(data);

    obj.close();
  }

  onDateSelect( obj ) {
    let data: any = {};

    //data[this.name] = moment( obj ).format('YYYY-MM-DD');
    data[this.name] = obj;

    this.formGroup.patchValue(data);

    if( typeof this.dateCustomChange !== 'undefined' ) this.dateCustomChange();
  }

  checkAddOn() {
    return ( typeof this.addOn == 'undefined' ) ? false : true;
  }

  changed( e: any): void {
    if( typeof this.onChanged !== 'undefined' ) {
      this.onChanged( e );

    } else {
      let data: any = {};

      data[this.name] = e.value;

      this.formGroup.patchValue(data);
    }
  }

  numberCheck() {
    let data: any = {};
    let tmp:string = String( this.formGroup.controls[this.name].value );
    tmp = tmp.replace(/,/gi, '');

    data[this.name] = this.getComma( tmp );

    this.formGroup.patchValue( data );
  }

  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  selectChange() {
    if( typeof this.change !== 'undefined' ) this.change();
  }

}
