import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  // 메뉴 번호
  public menuseq: number;

  // 메뉴 리스트
  public menuList : any = [];

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorObservable (error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }

  private handleErrorPromise (error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 메뉴 리스트 가져오기
  getMenuList(): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'common',
        version: '1.0',
        action: 'getMenuList'
      }, {
      }
    ).then( response => {
      if( response['ResultCode'] == true ) {
        for( let key in response.data ) {
          if( response.data.hasOwnProperty(key) ) {
            this.menuList.push( response.data[key] );
          }
        }
      } else {
        this.menuList = [];
      }

      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

}
