/*******************************************************************************
  설  명 : 검사실시대장
  작성일 : 2020-02-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbCalendar, NgbModal, ModalDismissReasons, NgbModalOptions , NgbDatepickerConfig} from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { Globals } from '@app/service/globals.service';
import { MemberService } from '@app/service/member.service';
import { RegionService } from '@app/service/region.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { ReportService } from '@app/service/report.service';
import { ReceptionService } from '@app/service/reception.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Select2OptionData } from 'ng2-select2';

import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

@Component({
  selector: 'app-books-inspect',
  templateUrl: './books-inspect.component.html',
  styleUrls: ['./books-inspect.component.scss']
})
export class BooksInspectComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 공통코드 데이터 리스트
  regoinList: any = [];
  select2List: Array<Select2OptionData>;
  cityList: any = [];
  officeList: any = [];
  memberList: any = [];
  inspectionTypeList: any = [];
  certificateStatusList: any = [];

   calendarInfo: any = {
    year: moment().format('YYYY'),
    month: moment().format('MM'),
    sdate: moment().format('YYYY-MM-01'),
    edate: moment().add(1, 'month').date(0).format('YYYY-MM-DD'),
    start_day: '',
    end_day: ''
  }

  // 검색 필드
  public search = {
    searchField: 'facilityName',
    searchText: '',
    //inspectionSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
    //inspectionEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
    inspectionSdate: '',
    inspectionEdate: '',
    officeCode: '',
    regionCode: '',
    cityCode: [],
    passYn: '',
    memberNo: '',
    inspectionType: '',
    certificateStatus:'',
  }

  // 그리드 관련 선언
  selectedRows: number = 0;
  dataList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  select2Options = {
    multiple: true
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private memberService: MemberService,
    private regionService: RegionService,
    private commonCodeService: CommonCodeService,
    private reportService: ReportService,
    private receptionService: ReceptionService,
    private calendar: NgbCalendar,
    private globals: Globals,
    private ngbDatepickerConfig: NgbDatepickerConfig
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'rowIndex', cellClass: 'cp center', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, valueGetter: "node.rowIndex + 1", width:85, pinned:true },
      { headerName: '순번', field: 'receptionSeq', cellClass: 'cp center',width:85,  pinned:true },
      { headerName: '시설코드', field: 'facilityCode', cellClass: 'cp center',width:85,hide:true , pinned:true},
      { headerName: '시설코드', field: 'facilityCodeFormat', cellClass: 'cp center',width:85 , pinned:true},
      { headerName: '시설명', field: 'facilityName', cellClass: 'cp' ,width:150, pinned:true},
      { headerName: '업체코드', field: 'companyCode', cellClass: 'cp' ,width:90 },
      { headerName: '업체명', field: 'companyName', cellClass: 'cp' ,width:150 },
      { headerName: '시/도', field: 'regionName', cellClass: 'cp center',width:90 },
      { headerName: '구/군', field: 'cityName', cellClass: 'cp center',width:90 },
      { headerName: '주소', field: 'facilityAddressFull', cellClass: 'cp center',width:200},
      { headerName: '접수일', field: 'receptionDate', cellClass: 'cp center' ,width:90},
      { headerName: '접수번호', field: 'receptionNumber', cellClass: 'cp center' ,width:90,hide:true},
      { headerName: '접수번호', field: 'receptionNumberFormat', cellClass: 'cp center' ,width:90},
      { headerName: '검사기관', field: 'inspectionAgencyName', cellClass: 'cp center' ,width:90},
      { headerName: '검사종류', field: 'inspectionTypeName', cellClass: 'cp center',width:90 },
      { headerName: '사용용도', field: 'usageName', cellClass: 'cp center' ,width:90},
      { headerName: '냉매', field: 'freezeName', cellClass: 'cp center' ,width:100},
      { headerName: '냉동톤', field: 'freezeCapacity', cellClass: 'cp center' ,width:90},
      { headerName: '수수료', field: 'inspectionFeeTotal', cellClass: 'cp right', valueFormatter: currencyFormatter,width:90 },
      { headerName: '예정일', field: 'planDate', cellClass: 'cp center' ,width:90},
      { headerName: '검사일', field: 'inspectionDate', cellClass: 'cp center' ,width:90},
      { headerName: '검사결과', field: 'passYn', cellClass: 'cp center',width:90,
        valueGetter: function(params) {
          if( params.data.passYn == '1' && params.data.passYn == true ) return '합격';
          else return '불합격';
        },
        cellStyle: function(params) {
          if( params.data.passYn == '1' && params.data.passYn == true ) {
            return {color: '#28a745'};
          } else {
            return {color: '#dc3545'};
          }
        }
      },
      { headerName: '검사표 생성여부', field: 'resultYn', cellClass: 'cp center',width:120,
        valueGetter: function(params) {
          if( params.data.resultYn == 'Y' ) return '생성';
          else return '생성안함';
        },
        cellStyle: function(params) {
          if( params.data.resultYn == 'Y' ) {
            return {color: '#28a745'};
          } else {
            return {color: '#dc3545'};
          }
        }
      },
      { headerName: '필증번호', field: 'certificateNumber', cellClass: 'cp center',width:100 },
      { headerName: '필증발행상태', field: 'certificateStatusName', cellClass: 'cp center',width:110, 
        cellStyle: function(params) {
          if( params.data.certificateStatusName == '발행' ) {
            return {color: '#28a745'};
          } else if( params.data.certificateStatusName == '재발행' ) {
            return {color: '#007bff'};
          } else {
            return {color: '#dc3545'};
          }
        }
      },
      { headerName: '검사원', field: 'memberName', cellClass: 'cp center',width:90 },
      { headerName: '기술책임자', field: 'managerName', cellClass: 'cp center' ,width:100},
      { headerName: '비고', field: 'memo', cellClass: 'cp' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    ngbDatepickerConfig.minDate = {year: 1900, month: 1, day: 1};
    ngbDatepickerConfig.maxDate = {year: 2099, month: 12, day: 31};
    ngbDatepickerConfig.displayMonths = 1;
    ngbDatepickerConfig.firstDayOfWeek = 7; // 일요일 먼저


    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 공통코드 리스트 가져오기
    this.getCommonList();

    // 그리드 리스트 가져오기
    this.getInspectionResultList();
  }

  /*******************************************************************************
    설  명 : 뷰 처리 완료 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {

  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchField: 'facilityName',
      searchText: '',
      //inspectionSdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'first' ),
      //inspectionEdate: this.utilService.getMonthFirstLast( this.calendar.getToday(), 'last' ),
      inspectionSdate: '',
      inspectionEdate: '',
      officeCode: '',
      regionCode: '',
      cityCode: [],
      passYn: '',
      memberNo: '',
      inspectionType: '',
      certificateStatus:'',
    }

    this.select2List = [];

    this.getInspectionResultList();
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 시/도
    await this.regionService.getRegionSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.regoinList = response.data;
      } else {
        this.regoinList = [];
      }
    });

    // 사무소
    await this.commonCodeService.getOfficeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.officeList = response.data;
      } else {
        this.officeList = [];
      }
    });

    // 검사원
    await this.memberService.getMemberSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.memberList = response.data;
      } else {
        this.memberList = [];
      }
    });

    // 검사종류
    await this.commonCodeService.getInspectionTypeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.inspectionTypeList = response.data;
      } else {
        this.inspectionTypeList = [];
      }
    });

    // 필증발행여부
    await this.commonCodeService.getCertificateStatusSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.certificateStatusList = response.data;
      } else {
        this.certificateStatusList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 시/구/군 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCitySelect() {
    await this.regionService.getCitySelect( this.search.regionCode ).then( response => {
      //console.log(response);
      /*
      if( response.ResultCode ) {
        this.cityList = response.data;
      } else {
        this.cityList = [];
      }
      */
      if( response.ResultCode ) {
        this.cityList = [];

        response.data.forEach( row => {
          this.cityList.push({
            text: row.cityName,
            id: row.cityCode
          });
        });

        this.select2List = this.cityList;
      }
    });
  }

  /*******************************************************************************
    설  명 : 검사실시대장 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getInspectionResultList() {
    // indicator 표시
    await setTimeout (() => {
      this.globals.indicator.next(true);
    });

    await this.reportService.getInspectionResultList( this.search ).then( response => {
      if( response.ResultCode ) {
        this.dataList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {

  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getInspectionResultList();
    }
  }

  /*******************************************************************************
    설  명 : 시/도 선택 시
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  setRegionChange() {
    this.search.cityCode = [];

    this.getCitySelect();
  }

  /*******************************************************************************
    설  명 : select2 변경 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changed( event: any ): void {
    //console.log(event);
    this.search.cityCode = event.value;
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.inspectionSdate = this.utilService.getDate( this.calendar.getToday() );

    } else {
      this.search.inspectionEdate = this.utilService.getDate( this.calendar.getToday() );
    }

    obj.close();
  }
  
  /*******************************************************************************
    설  명 : 시설검사관리 새창
    입력값 : 
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClicked( $event ) {
    let url = '/inspect/facility?fcode=' + $event.data.facilityCode;

    let width = $event.event.view.screen.availWidth;
    let height = $event.event.view.screen.availHeight;

    window.open(url, 'inspectFacility', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width='+ width +', height='+ height +', left=0, top=0, fullscreen=yes' );
  }
  
  /*******************************************************************************
    설  명 : 검사부적합통지서 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
	openUnfitness() {
    let rowsSelection = this.gridApi.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '시설을 선택하시기 바랍니다.', '');
      return false;
    }

    let seqArray: any = [];
    let check: any = true;
    rowsSelection.forEach(item => {
      if( item.passYn != '0' && item.passYn != false ) check = false;

      seqArray.push( item.receptionSeq );
    });

    if( check === false ) {
      this.toastrService.error( '불합격인 검사내역만 출력할 수 있습니다.' );
      return false;
    } else {
      let params = JSON.stringify( seqArray );
      sessionStorage.setItem( 'booksInspectPrintUnfitness', params );

      let url = '/books/inspect/print/unfitness';

      window.open(url, 'booksInspectPrintUnfitness', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
    }
  }
  
  /*******************************************************************************
    설  명 : 발행취소
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCancelCertificate() {
    let rowsSelection = this.gridApi.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '발행취소할 내역을 선택하시기 바랍니다.' );
      return false;
    }

    let seqArray: any = [];
    let check: any = true;
    rowsSelection.forEach(item => {
      if( item.certificateStatus != '2' && item.certificateStatus != '3' ) check = false;

      seqArray.push({
        receptionSeq: item.receptionSeq,
        certificateStatus: item.certificateStatus
      });
    });

    if( check === false ) {
      this.toastrService.error( '발행상태가 없거나 미발행인 내역이 포함되어 있습니다.' );
      return false;
    } else {
      if( confirm("발행취소 하시겠습니까?") ) {
        this.receptionService.setCancelCertificate( seqArray ).then( response => {
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');
            this.getInspectionResultList();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 검사증명서 출력 (필증발행)
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openCertificate() {
    let rowsSelection = this.gridApi.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '시설을 선택하시기 바랍니다.', '');
      return false;
    }

    let seqArray: any = [];
    let check: any = true;
    let check2: any = true;
    let check3: any = true;
    rowsSelection.forEach(item => {
      if( item.passYn != '1' && item.passYn != true ) check = false;
      if( item.certificateNumber == '' && item.certificateNumber == null ) check2 = false;
      if( item.memberNo == '0' && item.memberNo == null ) check3 = false;

      seqArray.push( item.receptionSeq );
    });

    if( check === false ) {
      this.toastrService.error( '검사결과가 합격이 아닌 내역이 있습니다.' );
      return false;
    } else if( check2 === false ) {
      this.toastrService.error( '필증번호가 존재하지 않는 내역이 있습니다.' );
      return false;
    } else if( check3 === false ) {
      this.toastrService.error( '검사원이 지정되지 않는 내역이 있습니다.' );
      return false;
    } else {
      let params = JSON.stringify( seqArray );
      sessionStorage.setItem( 'booksInspectPrintCertificate1', params );

      let url = '/books/inspect/print/certificate1';

      window.open(url, 'booksInspectPrintCertificate1', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
    }
  }  
  
  /*******************************************************************************
    설  명 : 검사표 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openInspect() {
    let rowsSelection = this.gridApi.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '시설을 선택하시기 바랍니다.', '');
      return false;
    }

    let seqArray: any = [];
    let check: any = true;
    let check2: any = true;
    rowsSelection.forEach(item => {
      if( item.passYn != '1' && item.passYn != true ) check = false;
      if( item.resultYn != 'Y' ) check2 = false;

      seqArray.push( item.receptionSeq );
    });

    if( check === false ) {
      this.toastrService.error( '검사결과가 합격이 아닌 내역이 있습니다.' );
      return false;
    } else if( check2 === false ) {
      this.toastrService.error( '검사표가 생성되지 않은 내역이 있습니다.' );
      return false;
    } else {
      let params = JSON.stringify( seqArray );
      sessionStorage.setItem( 'booksInspectPrintInspect', params );

      let url = '/books/inspect/print/inspect';

      window.open(url, 'booksInspectPrintInspect', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
    }
  }
            
  /*******************************************************************************
    설  명 : 검사실시대장 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openInspect1() {
    let search = encodeURIComponent( JSON.stringify( this.search ) );

    let url = '/books/inspect/print/inspect1?search=' + search;

    window.open(url, 'booksInspectPrintInspect1', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1450, height=800, left=0, top=0' );
  }

}
