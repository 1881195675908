import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})

export class CompanyfacilityService {

  constructor(
    private restful: RestfulService
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.log(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 업체리스트 가져오기
  getCompanyList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'companyfacility',
        version: '1.0',
        action: 'getCompanyList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 업체 상세정보 가져오기
  getCompanyInfo( companySeq ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'companyfacility',
        version: '1.0',
        action: 'getCompanyInfo'
      }, {
        companySeq: companySeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 업체정보 DM리스트 가져오기
  getCompanyDmList( seqArray ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'companyfacility',
        version: '1.0',
        action: 'getCompanyDmList'
      }, {
        seqArray: seqArray
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시설리스트 가져오기
  getFacilityList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'companyfacility',
        version: '1.0',
        action: 'getFacilityList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시설 상세정보 가져오기
  getFacilityInfo( params: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'companyfacility',
        version: '1.0',
        action: 'getFacilityInfo'
      }, {
        facilitySeq: params.facilitySeq,
        facilityCode: params.facilityCode
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 업체/시설 정보 저장하기
  setCompanyfacilitySave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'companyfacility',
        version: '1.0',
        action: 'setCompanyfacilitySave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시설 삭제하기
  setCompanyfacilityDelete( facilitySeq ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'companyfacility',
        version: '1.0',
        action: 'setCompanyfacilityDelete'
      }, {
        facilitySeq: facilitySeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 업체/시설 상세정보 가져오기
  getCompanyFacilityInfo( receptionSeq ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'companyfacility',
        version: '1.0',
        action: 'getCompanyFacilityInfo'
      }, {
        receptionSeq: receptionSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
