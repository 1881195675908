/*******************************************************************************
  설  명 : 수수료 관리
  작성일 : 2020-02-25
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { Globals } from '@app/service/globals.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { InspectFeeService } from '@app/service/inspect.fee.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

import { FeeAddComponent } from '@app/page/basic/fee/fee-add/fee-add.component';
import { FeeDetailAddComponent } from '@app/page/basic/fee/fee-detail-add/fee-detail-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-fee',
  templateUrl: './fee.component.html',
  styleUrls: ['./fee.component.scss']
})
export class FeeComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // 회원구분 리스트
  public memberTypeList = [];

  public dateList = [];
  public feeList = [];

  // 검색 필드
  public memberType: any = '';
  public registDate: any = '';

  // 그리드 관련 선언
  selectedRows: number = 0;
  clientList: any = [];
  detailList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  gridApiDetail: any;
  gridColumnApiDetail: any;
  columnDefsDetail: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private globals: Globals,
    private commonCodeService: CommonCodeService,
    private inspectFeeService: InspectFeeService,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      {headerName: '일자', field: 'registDate', cellClass: 'cp', checkboxSelection: true },
    ];


    // ag grid 컬럼 선언
    this.columnDefsDetail = [
      {headerName: '냉동톤(TEXT)', field: 'freezeCapacityText', cellClass: 'cp' },
      {headerName: '냉동톤(초과)', field: 'freezeCapacityMin', cellClass: 'cp right' },
      {headerName: '냉동톤(이하)', field: 'freezeCapacityMax', cellClass: 'cp right' },
      {headerName: '수수료(공급가액)', field: 'memberFee', cellClass: 'cp right' },
      {headerName: '수수료(부가세)', field: 'memberVat', cellClass: 'cp right' },
      {headerName: '수수료(합계)', field: 'feeTotal', cellClass: 'cp right' },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : 공통코드 - 회원구분 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 회원구분
    await this.commonCodeService.getMemberTypeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.memberTypeList = response.data;
        this.memberType = response.data[0].commonCode;
      } else {
        this.memberTypeList = [];
      }
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    // 공통코드 가져오기
    await this.getCommonList();

    // 수수료적용일 리스트 가져오기
    await this.getInspectFeeDateList();
  }

  /*******************************************************************************
    설  명 : 뷰 처리 완료 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {

    // indicator 표시
    setTimeout (() => {
      if( this.dateList.length < 1 )
        this.globals.indicator.next(true);
    });
  }

  /*******************************************************************************
    설  명 : 수수료적용일 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getInspectFeeDateList() {
    this.dateList = [];

    await this.inspectFeeService.getInspectFeeDateList( this.memberType ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.dateList = response.data;

        if( this.dateList.length > 0 ) {
          this.registDate = this.dateList[0].registDate;
          this.getInspectFeeList();
        }
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : 수수료 세부내용 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getInspectFeeList() {
    await this.inspectFeeService.getInspectFeeList( this.memberType, this.registDate ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.feeList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 회원구분 버튼 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setMemberType( memberType ) {
    this.memberType = memberType;

    this.getInspectFeeDateList();
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.memberType = '';

    this.getInspectFeeDateList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    this.registDate = $event.data.registDate;
    this.getInspectFeeList();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReadyDetail(params) {
    this.gridApiDetail = params.api;
    this.gridColumnApiDetail = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChangedDetail($event) {
    let filteredRowCount = 0;
    this.gridApiDetail.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 더블클릭 시 처리 - 수수료 세부정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClickedDetail($event) {
    this.addFeeDetail( $event.data.inspectFeeSeq );
  }

  /*******************************************************************************
    설  명 : 수수료 적용일 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  addFee() {
    const modalRef = this.modalService.open(FeeAddComponent, options);

    modalRef.componentInstance.registDate = this.registDate;

    modalRef.result.then((result) => {
      if( result ) {
        this.getInspectFeeDateList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 수수료 추가
    입력값 : inspectFeeSeq = 0 일 경우 추가, 이외에는 수정
    리턴값 : 없음
  *******************************************************************************/
  addFeeDetail( inspectFeeSeq ) {
    if( inspectFeeSeq == 0 ) {
      if( !this.registDate ) {
        this.toastrService.error('수수료 적용일을 선택하시기 발바니다.', '');
        return false;
      }

      if( !this.memberType ) {
        this.toastrService.error('회원 구분을 선택하시기 발바니다.', '');
        return false;
      }
    }

    const modalRef = this.modalService.open(FeeDetailAddComponent, options);

    modalRef.componentInstance.memberType = this.memberType;
    modalRef.componentInstance.registDate = this.registDate;
    modalRef.componentInstance.inspectFeeSeq = inspectFeeSeq;

    modalRef.result.then((result) => {
      if( result ) {
        this.getInspectFeeList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 검색 input에서 엔터키 입력 시 검색 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( event ) {
    if( event.key == 'Enter' ) {
      this.getInspectFeeDateList();
    }
  }

}
