/*******************************************************************************
  설  명 : 대시보드
  작성일 : 2020-01-20
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker, NgbCalendar, NgbModal, ModalDismissReasons, NgbModalOptions, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import { Globals } from '@app/service/globals.service';
import { UtilService } from '@app/service/util.service';
import { DashboardService } from '@app/service/dashboard.service';
import { CommonCodeService } from '@app/service/common.code.service';


import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

import { ModalCounselingAddComponent } from '@app/components/modal-counseling-add/modal-counseling-add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-adashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  statistics: any = {};
  calendarList: any = [];
  officeList: any = [];
  widget_view = false;

  calendarInfo: any = {
    year: moment().format('YYYY'),
    month: moment().format('MM'),
    sdate: moment().format('YYYY-MM-01'),
    edate: moment().add(1, 'month').date(0).format('YYYY-MM-DD'),
    start_day: '',
    end_day: ''
  }

  search: any = {
    searchField: 'reception',
    startDate: this.utilService.getDate( this.calendar.getToday() ),
    endDate: this.utilService.getDate( this.calendar.getToday() ),
    dateGroup: '',
    officeCode: ''
  }

  // 그리드 관련 선언
  selectedRows: number = 0;
  dataList: any = [];

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  startDateModel: NgbDateStruct;
  endDateModel: NgbDateStruct;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private globals: Globals,
    private calendar: NgbCalendar,
    private utilService: UtilService,
    private modalService: NgbModal,
    private commonCodeService: CommonCodeService,
    private dashboardService: DashboardService,
    private toastrService: ToastrService,
    private ngbDatepickerConfig: NgbDatepickerConfig,
  ) {
    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'rowIndex', cellClass: 'cp center',   pinned:true ,
        headerCheckboxSelection: true, 
        headerCheckboxSelectionFilteredOnly: true, 
        checkboxSelection: true,
        valueGetter: "node.rowIndex + 1" , width:70
      },
      { headerName: '시설코드', field: 'facilityCode', cellClass: 'cp', hide:true },
      { headerName: '시설코드', field: 'facilityCodeFormat', cellClass: 'cp', width:85 , pinned:true },
      { headerName: '시설명', field: 'facilityName', cellClass: 'cp'  , pinned:true},
      { headerName: '접수번호', field: 'receptionNumberFormat', cellClass: 'cp center' , width:85 },
      { headerName: '접수일', field: 'receptionDate', cellClass: 'cp center', width:85 },
      { headerName: '예정일', field: 'planDate', cellClass: 'cp center', width:85 },
      { headerName: '검사일', field: 'inspectionDate', cellClass: 'cp center' , width:85},
      { headerName: '검사여부', field: 'inspectionYnName', cellClass: 'cp center' , width:85},
      { headerName: '합격여부', field: 'passYnName', cellClass: 'cp center' , width:85},
      { headerName: '검사종류', field: 'inspectionTypeName', cellClass: 'cp center' , width:85},
      { headerName: '냉동톤', field: 'frozenCapacity', cellClass: 'cp center' , width:85},
      { headerName: '수수료', field: 'inspectionFeeTotal', cellClass: 'cp right', valueFormatter: currencyFormatter , width:85 },
      { headerName: '검사원', field: 'memberName', cellClass: 'cp center' , width:85},
      { headerName: '업체명', field: 'companyName', cellClass: 'cp' , width:85},
      { headerName: '업체코드', field: 'companyCode', cellClass: 'cp' , width:85},
      { headerName: '시도', field: 'regionName', cellClass: 'cp center', width:85 },
      { headerName: '구군', field: 'cityName', cellClass: 'cp center' , width:85},
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "multiple";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";

    ngbDatepickerConfig.minDate = {year: 1900, month: 1, day: 1};
    ngbDatepickerConfig.maxDate = {year: 2099, month: 12, day: 31};
    ngbDatepickerConfig.displayMonths = 1;
    ngbDatepickerConfig.firstDayOfWeek = 7; // 일요일 먼저



 
    function currencyFormatter(params: any) {
      return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.initCalendar();

    this.getMainBusiness();
    this.getCalendar();
    this.getReceptionListDashboard();
          // 공통코드 리스트 가져오기
    this.getCommonList();

  }


  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 사무소
    await this.commonCodeService.getOfficeSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        this.officeList = response.data;
      } else {
        this.officeList = [];
      }
    });
  }
  /*******************************************************************************
    설  명 : 메인 통계 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getMainBusiness() {
    await this.dashboardService.getMainBusiness().then( response => {
      if( response.ResultCode ) {
        this.statistics = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 캘린더 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCalendar() {
    await this.dashboardService.getCalendar( this.calendarInfo.year, this.calendarInfo.month ).then( response => {
      if( response.ResultCode ) {
        this.calendarList = response.data;
        //console.log(this.calendarList);
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 접수내역 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getReceptionListDashboard() {
    await this.dashboardService.getReceptionListDashboard( this.search ).then( response => {
      if( response.ResultCode ) {
        this.dataList = response.data;
        //console.log(this.dataList);
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 초기화 버튼 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchInit() {
    this.search = {
      searchField: 'reception',
      startDate: this.utilService.getDate( this.calendar.getToday() ),
      endDate: this.utilService.getDate( this.calendar.getToday() ),
      dateGroup: '',
      officeCode: ''
    }

    this.getReceptionListDashboard();
  }

  /*******************************************************************************
    설  명 : 달력 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  initCalendar() {
    let sweek: any = moment(this.calendarInfo.sdate).isoWeekday();
    this.calendarInfo.start_day = moment(this.calendarInfo.sdate).add(sweek * -1, 'day').format('YYYY-MM-DD');

    let eweek: any = moment(this.calendarInfo.edate).isoWeekday();
    this.calendarInfo.end_day = moment(this.calendarInfo.edate).add(6 - eweek, 'day').format('YYYY-MM-DD');
  }

  /*******************************************************************************
    설  명 : 달력 루프
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  range( i : number ) {
    return Array(i);
  }

  /*******************************************************************************
    설  명 : 달력 루프의 현재일 계산
    입력값 : i = 행, j = 열
    리턴값 : 현재 일(월-일)
  *******************************************************************************/
  getCurrDay(i, j) {
    let term = ( i * 7 ) + j;
    let date: any = moment(this.calendarInfo.start_day).add(term, 'day').format('DD');
    if( date == '01' )
      date = moment(this.calendarInfo.start_day).add(term, 'day').format('MM-DD');

    return date;
  }

  /*******************************************************************************
    설  명 : 달력 루프의 현재일 계산
    입력값 : i = 행, j = 열
    리턴값 : 현재 일(월-일)
  *******************************************************************************/
  getReceptionCount(i, j) {
    let term = ( i * 7 ) + j;
    let date: any = moment(this.calendarInfo.start_day).add(term, 'day').format('YYYY-MM-DD');

    let reception = this.calendarList.filter(function (calendar) {
      return calendar.calendarDate === date;
    });

    return ( reception.length > 0 ) ? '접수' + reception[0].receptionCount + '건' : '';
  }

  /*******************************************************************************
    설  명 : 달력 루프의 현재일 계산
    입력값 : i = 행, j = 열
    리턴값 : 현재 일(월-일)
  *******************************************************************************/
  getPlanCount(i, j) {
    let term = ( i * 7 ) + j;
    let date: any = moment(this.calendarInfo.start_day).add(term, 'day').format('YYYY-MM-DD');

    let reception = this.calendarList.filter(function (calendar) {
      return calendar.calendarDate === date;
    });

    return ( reception.length > 0 ) ? '예정' + reception[0].planCount + '건' : '';
  }

  /*******************************************************************************
    설  명 : 달력 루프의 현재일 계산
    입력값 : i = 행, j = 열
    리턴값 : 현재 일(월-일)
  *******************************************************************************/
  getInspectionCount(i, j) {
    let term = ( i * 7 ) + j;
    let date: any = moment(this.calendarInfo.start_day).add(term, 'day').format('YYYY-MM-DD');

    let reception = this.calendarList.filter(function (calendar) {
      return calendar.calendarDate === date;
    });

    return ( reception.length > 0 ) ? '검사' + reception[0].inspectionCount + '건' : '';
  }

  /*******************************************************************************
    설  명 : Moment
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getMoment( type: any ) {
    return moment().format( type );
  }

  /*******************************************************************************
    설  명 : 다음달
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  nextMonth() {
    this.calendarInfo.year = moment(this.calendarInfo.sdate).add(1, 'months').format('YYYY');
    this.calendarInfo.month = moment(this.calendarInfo.sdate).add(1, 'months').format('MM');
    
    let date = this.calendarInfo.year + '-' + this.calendarInfo.month;

    this.calendarInfo.sdate = moment( date ).format('YYYY-MM-01');
    this.calendarInfo.edate = moment( date ).add(1, 'month').date(0).format('YYYY-MM-DD');

    this.initCalendar();
    this.getCalendar();
  }

  /*******************************************************************************
    설  명 : 현재월
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  currMonth() {
    this.calendarInfo.year = moment().format('YYYY');
    this.calendarInfo.month = moment().format('MM');
    
    let date = this.calendarInfo.year + '-' + this.calendarInfo.month;

    this.calendarInfo.sdate = moment( date ).format('YYYY-MM-01');
    this.calendarInfo.edate = moment( date ).add(1, 'month').date(0).format('YYYY-MM-DD');

    this.initCalendar();
    this.getCalendar();
  }

  /*******************************************************************************
    설  명 : 이전달
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  prevMonth() {
    this.calendarInfo.year = moment(this.calendarInfo.sdate).subtract(1, 'months').format('YYYY');
    this.calendarInfo.month = moment(this.calendarInfo.sdate).subtract(1, 'months').format('MM');
    
    let date = this.calendarInfo.year + '-' + this.calendarInfo.month;

    this.calendarInfo.sdate = moment( date ).format('YYYY-MM-01');
    this.calendarInfo.edate = moment( date ).add(1, 'month').date(0).format('YYYY-MM-DD');

    this.initCalendar();
    this.getCalendar();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: boolean ) {
    if( check ) {
      this.search.startDate = this.utilService.getDate( this.calendar.getToday() );

    } else {
      this.search.endDate = this.utilService.getDate( this.calendar.getToday() );
    }

    obj.close();
  }

  /*******************************************************************************
    설  명 : 날짜 그룹 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSearchDate( dateGroup ) {
    this.search.dateGroup = dateGroup;

    if( this.search.dateGroup == '1' ) {
      this.startDateModel = {
        year: parseInt( moment().format('YYYY') ),
        month: parseInt( moment().format('MM') ),
        day: parseInt( moment().format('DD') )
      };
    } else if( this.search.dateGroup == '2' ) {
      this.startDateModel = {
        year: parseInt( moment().subtract(7, 'days').format('YYYY') ),
        month: parseInt( moment().subtract(7, 'days').format('MM') ),
        day: parseInt( moment().subtract(7, 'days').format('DD') )
      };
    } else if( this.search.dateGroup == '3' ) {
      this.startDateModel = {
        year: parseInt( moment().format('YYYY') ),
        month: parseInt( moment().format('MM') ),
        day: parseInt( moment().format('01') )
      };
    }

    this.endDateModel = {
      year: parseInt( moment().format('YYYY') ),
      month: parseInt( moment().format('MM') ),
      day: parseInt( moment().format('DD') )
    };

    this.search.startDate = this.startDateModel;
    this.search.endDate = this.endDateModel;
  }

  /*******************************************************************************
    설  명 : 달력 날짜 선택시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  clickCalendarDate( i, j, type ) {
    this.search.searchField = type;
    this.search.dateGroup = '';

    let term = ( i * 7 ) + j;
    let date: any = moment(this.calendarInfo.start_day).add(term, 'day').format('YYYY-MM-DD');

    this.startDateModel = {
      year: parseInt( moment(date).format('YYYY') ),
      month: parseInt( moment(date).format('MM') ),
      day: parseInt( moment(date).format('DD') )
    };

    this.endDateModel = {
      year: parseInt( moment(date).format('YYYY') ),
      month: parseInt( moment(date).format('MM') ),
      day: parseInt( moment(date).format('DD') )
    };

    this.search.startDate = this.startDateModel;
    this.search.endDate = this.endDateModel;

    this.getReceptionListDashboard();
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApi.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 더블클릭 시 처리 - 시설검사관리 새창
    입력값 : 
    리턴값 : 없음
  *******************************************************************************/
  onCellDoubleClicked( $event ) {
    let url = '/inspect/facility?fcode=' + $event.data.facilityCode;

    let width = $event.event.view.screen.availWidth;
    let height = $event.event.view.screen.availHeight;

    window.open(url, 'inspectFacility', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width='+ width +', height='+ height +', left=0, top=0, fullscreen=yes' );
  }

  /*******************************************************************************
    설  명 : 상담 등록/수정
    입력값 : counselingSeq = 0 일 경우 등로5, 이외에는 수정
    리턴값 : 없음
  *******************************************************************************/
  addCounseling( counselingSeq ) {
    let rowsSelection = this.gridApi.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '등록할 접수내역을 선택하시기 바랍니다.', '접수리스트' );
      return false;
    }

    if( rowsSelection.length > 1 ) {
      this.toastrService.error( '한 개의 접수내역만 선택하시기 바랍니다.', '접수리스트' );
      return false;
    }

    const modalRef = this.modalService.open(ModalCounselingAddComponent, options);

    modalRef.componentInstance.counselingSeq = counselingSeq;
    modalRef.componentInstance.receptionSeq = rowsSelection[0].receptionSeq;
    modalRef.componentInstance.facilityCode = rowsSelection[0].facilityCode;

    modalRef.result.then((result) => {
      if( result ) {
        this.getReceptionListDashboard();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 현장검사표 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openChecklist() {
    let url = '/inspect/facility/print/checklist';

    window.open(url, 'inspectFacilityPrintChecklist', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : 접수증 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openReceipt() {
    let rowsSelection = this.gridApi.gridOptionsWrapper.gridOptions.api.getSelectedRows();
    //console.log(rowsSelection);
    if( rowsSelection.length < 1 ) {
      this.toastrService.error( '출력할 검사내역을 선택하시기 바랍니다.', '접수리스트' );
      return false;
    }

    let tmp: any = [];
    let check: any = true;
    rowsSelection.forEach(item => {
      if( item.receptionNumber == '' || item.receptionNumber == false ) check = false;

      tmp.push({
        seq: item.receptionSeq,
        code: item.facilityCode
      });
    });

    if( check === false ) {
      this.toastrService.error( '선택한 것 중에서 검사 접수되지 않은 내역이 있습니다.', '접수리스트' );
      return false;
    } else {
      //let params = encodeURIComponent( JSON.stringify( tmp ) );
      let params = JSON.stringify( tmp );
      sessionStorage.setItem( 'inspectFacilityPrintReceipt', params );

      let url = '/inspect/facility/print/receipt';

      window.open(url, 'inspectFacilityPrintReceipt', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
    }
  }

}
