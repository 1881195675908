import { RouterModule, Route } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbActiveModal, NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule, MatTreeModule } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AgGridModule } from 'ag-grid-angular';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ToastrModule } from 'ngx-toastr';
import { DpDatePickerModule } from 'ng2-date-picker';
import { FileSelectDirective } from 'ng2-file-upload';
import { PioneerTreeModule } from '@pioneer-code/pioneer-tree';
import { Select2Module } from 'ng2-select2';
import { CookieService } from 'ngx-cookie-service';
import { AngularSplitModule } from 'angular-split';

/* 공통 서비스 모듈 */
import { AuthGuard } from '@app/service/auth.guard';
import { AuthLoginCheck } from '@app/service/auth.login.check';
import { AuthService } from '@app/service/auth.service';
import { RestfulService } from '@app/service/restful.service';
import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { MemberService } from '@app/service/member.service';

import { AutoFocusDirective } from '@app/directive/auto-focus.directive';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';

/* 공용 컴포넌트 모듈 */
import { InputExComponent } from '@app/components/input-ex/input-ex.component';
import { AgGridImageComponent } from '@app/components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHeaderCheckboxComponent } from '@app/components/ag-grid-header-checkbox/ag-grid-header-checkbox.component';
import { DaumAddressComponent } from '@app/components/daum-address/daum-address.component';

/* 페이지 모듈 */
import { HeaderComponent } from '@app/page/layout/header/header.component';
import { GnbComponent } from '@app/page/layout/gnb/gnb.component';
import { LoginComponent } from '@app/page/member/login/login.component';
import { DashboardComponent } from '@app/page/dashboard/dashboard.component';
import { AuthGroupComponent } from '@app/page/system/auth-group/auth-group.component';
import { AuthGroupAddComponent } from '@app/page/system/auth-group/add/add.component';
import { CommonCodeComponent } from '@app/page/system/common/common.component';
import { CommonCodeAddComponent } from '@app/page/system/common/add/add.component';
import { CommonCodeGroupAddComponent } from '@app/page/system/common/group-add/group-add.component';

import { ClientComponent } from '@app/page/basic/client/client.component';
import { FeeComponent } from '@app/page/basic/fee/fee.component';
import { FeeAddComponent } from '@app/page/basic/fee/fee-add/fee-add.component';
import { FeeDetailAddComponent } from '@app/page/basic/fee/fee-detail-add/fee-detail-add.component';
import { RegionComponent } from '@app/page/basic/region/region.component';
import { RegionAddComponent } from '@app/page/basic/region/region-add/region-add.component';
import { InspectFacilityComponent } from '@app/page/inspect/inspect-facility/inspect-facility.component';
import { InspectAddComponent } from '@app/page/inspect/inspect-facility/inspect-add/inspect-add.component';
import { ReceiptInfoComponent } from '@app/page/inspect/inspect-facility/receipt-info/receipt-info.component';
import { InspectReportComponent } from '@app/page/inspect/inspect-facility/inspect-report/inspect-report.component';
import { CounselingComponent } from '@app/page/inspect/inspect-facility/counseling/counseling.component';
import { InspectHistoryComponent } from '@app/page/inspect/inspect-history/inspect-history.component';
import { AddGiroComponent } from '@app/page/inspect/inspect-history/add-giro/add-giro.component';
import { EditDateComponent } from '@app/page/inspect/inspect-history/edit-date/edit-date.component';
import { InspectConsultComponent } from '@app/page/inspect/inspect-consult/inspect-consult.component';
import { BooksFacilityComponent } from '@app/page/books/books-facility/books-facility.component';
import { BooksRegisterComponent } from '@app/page/books/books-register/books-register.component';
import { BooksInspectComponent } from '@app/page/books/books-inspect/books-inspect.component';
import { BooksMandatoryComponent } from '@app/page/books/books-mandatory/books-mandatory.component';
import { BooksRtfeeComponent } from '@app/page/books/books-rtfee/books-rtfee.component';
import { BooksPerformanceComponent } from '@app/page/books/books-performance/books-performance.component';

import { PrintBooksFacilityComponent } from '@app/page/books/books-facility/print/print-books-facility/print-books-facility.component';
import { PrintBooksRegisterComponent } from '@app/page/books/books-register/print/print-books-register/print-books-register.component';
import { PrintBooksUnfitnessComponent } from '@app/page/books/books-inspect/print/print-books-unfitness/print-books-unfitness.component';
import { PrintBooksCertificateComponent } from '@app/page/books/books-inspect/print/print-books-certificate/print-books-certificate.component';
import { PrintBooksCertificate1Component } from '@app/page/books/books-inspect/print/print-books-certificate1/print-books-certificate1.component';
import { PrintBooksInspectComponent } from '@app/page/books/books-inspect/print/print-books-inspect/print-books-inspect.component';
import { PrintBooksMandatoryComponent } from '@app/page/books/books-mandatory/print/print-books-mandatory/print-books-mandatory.component';
import { PrintFacilityChecklistComponent } from './page/inspect/inspect-facility/print/print-facility-checklist/print-facility-checklist.component';
import { PrintFacilityReceiptComponent } from './page/inspect/inspect-facility/print/print-facility-receipt/print-facility-receipt.component';
import { PrintGiroComponent } from './page/inspect/inspect-history/print/print-giro/print-giro.component';
import { PrintDmComponent } from './page/inspect/inspect-history/print/print-dm/print-dm.component';
import { PrintBooksInspect1Component } from './page/books/books-inspect/print/print-books-inspect1/print-books-inspect1.component';

/* 팝업 컴포넌트 */
import { ModalFacilityInfoComponent } from '@app/components/modal-facility-info/modal-facility-info.component';
import { ModalCounselingAddComponent } from '@app/components/modal-counseling-add/modal-counseling-add.component';
import { ModalRegisterMemoComponent } from '@app/page/books/books-register/modal-register-memo/modal-register-memo.component';

/* 달력 컴포넌트 한글 화 */
const I18N_VALUES = {
  ko: {
    weekdays: ['월', '화', '수', '목', '금', '토', '일'],
    months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  }
};

@Injectable()
export class I18n {
  language = 'ko';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@NgModule({
  declarations: [
    AppComponent,

    AutoFocusDirective,
    DaumAddressComponent,
    InputExComponent,
    AgGridImageComponent,
    AgGridHtmlComponent,
    AgGridSaveComponent,
    AgGridExcelComponent,
    AgGridHeaderCheckboxComponent,

    HeaderComponent,
    GnbComponent,
    LoginComponent,
    DashboardComponent,
    AuthGroupComponent,
    AuthGroupAddComponent,
    CommonCodeComponent,
    CommonCodeAddComponent,
    CommonCodeGroupAddComponent,

    ClientComponent,
    FeeComponent,
    RegionComponent,
    InspectFacilityComponent,
    InspectHistoryComponent,
    InspectConsultComponent,
    BooksFacilityComponent,
    BooksRegisterComponent,
    BooksInspectComponent,
    BooksMandatoryComponent,
    BooksRtfeeComponent,
    BooksPerformanceComponent,
    FeeAddComponent,
    FeeDetailAddComponent,
    RegionAddComponent,
    InspectAddComponent,
    ReceiptInfoComponent,
    InspectReportComponent,
    CounselingComponent,

    ModalFacilityInfoComponent,
    ModalCounselingAddComponent,
    
    PrintBooksFacilityComponent,
    PrintBooksRegisterComponent,
    PrintBooksUnfitnessComponent,
    PrintBooksCertificateComponent,
    PrintBooksCertificate1Component,
    PrintBooksInspectComponent,
    PrintBooksMandatoryComponent,
    
    ModalRegisterMemoComponent,
    
    PrintFacilityChecklistComponent,
    
    PrintFacilityReceiptComponent,
    
    AddGiroComponent,
    
    EditDateComponent,
    
    PrintGiroComponent,
    
    PrintDmComponent,
    
    PrintBooksInspect1Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    //NgbModule.forRoot(),
    NgbModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTreeModule,
    DragDropModule,
    DpDatePickerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right'
    }),
    AgGridModule.withComponents([
      AgGridImageComponent,
      AgGridHtmlComponent,
      AgGridHeaderCheckboxComponent
    ]),
    PioneerTreeModule,
    NgxSummernoteModule,
    Select2Module,
    AngularSplitModule.forRoot(),
  ],
  providers: [
    RestfulService,
    MemberService,
    UtilService,
    Globals,
    AuthService,
    AuthGuard,
    AuthLoginCheck,
    AgGridImageComponent,
    AgGridHtmlComponent,
    AgGridSaveComponent,
    AgGridExcelComponent,
    CookieService,
    NgbActiveModal,
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    NgbDatepickerConfig
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    AuthGroupAddComponent,
    CommonCodeAddComponent,
    CommonCodeGroupAddComponent,
    FeeAddComponent,
    FeeDetailAddComponent,
    RegionAddComponent,
    InspectAddComponent,
    AddGiroComponent,
		EditDateComponent,
		
    ModalFacilityInfoComponent,
    ModalCounselingAddComponent,
    ModalRegisterMemoComponent
  ]
})
export class AppModule { }
