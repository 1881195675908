/*******************************************************************************
  설  명 : 지로생성
  작성일 : 
  작성자 : 
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { ReceptionService } from '@app/service/reception.service';
import { Globals } from '@app/service/globals.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-giro',
  templateUrl: './add-giro.component.html',
  styleUrls: ['./add-giro.component.scss']
})
export class AddGiroComponent implements OnInit {
  /*******************************************************************************
    설  명 : 전역 변수
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  yearList: any = [];
  monthList: any = [];
  
  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      lastMonth:["", [Validators.required] ],
      year:[moment().format('YYYY'), [Validators.required] ],
      month:["", [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private receptionService: ReceptionService,
    private globals: Globals,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
  	this.buildForm();
   }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    let year: any;
    let month: any;
    let startYear: any = moment().format('YYYY');
    let endYear: any = moment().add(3, 'years').format('YYYY');

    for(year = startYear; year <= endYear; year++) {
      this.yearList.push({
        title: year + '년',
        value: year
      });
    }
    
    for(month = '1'; month <= '12'; month++) {
      if( month < 10 ) month = '0' + month;
      this.monthList.push({
        title: month + '월',
        value: month
      });
    }

    this.getGiroLastMonth();
  }

  /*******************************************************************************
    설  명 : 시설별 검사내역 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getGiroLastMonth() {
    await this.receptionService.getGiroLastMonth().then( response => {
      if( response.ResultCode ) {
        this.form.patchValue( response.data );
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 지로 생성하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setGiroCreate() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      let CreateMonth = this.form.controls.year.value + '-' + this.form.controls.month.value;

      if( CreateMonth <= this.form.controls.lastMonth.value ) {
        this.toastrService.error('이미 생성한 년월입니다.');
        return false;
      }

      this.receptionService.setGiroCreate( CreateMonth ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

}
