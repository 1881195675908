import { NgModule } from '@angular/core';
import {
  Router, Routes, RouterModule,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { Globals } from '@app/service/globals.service';
import { CommonService } from '@app/service/common.service';
import { AuthGuard } from '@app/service/auth.guard';
import { AuthLoginCheck } from '@app/service/auth.login.check';

import { LoginComponent } from '@app/page/member/login/login.component';
import { DashboardComponent } from '@app/page/dashboard/dashboard.component';
import { AuthGroupComponent } from '@app/page/system/auth-group/auth-group.component';
import { CommonCodeComponent } from '@app/page/system/common/common.component';

import { ClientComponent } from '@app/page/basic/client/client.component';
import { FeeComponent } from '@app/page/basic/fee/fee.component';
import { RegionComponent } from '@app/page/basic/region/region.component';
import { InspectFacilityComponent } from '@app/page/inspect/inspect-facility/inspect-facility.component';
import { InspectAddComponent } from '@app/page/inspect/inspect-facility/inspect-add/inspect-add.component';
import { InspectHistoryComponent } from '@app/page/inspect/inspect-history/inspect-history.component';
import { InspectConsultComponent } from '@app/page/inspect/inspect-consult/inspect-consult.component';
import { BooksFacilityComponent } from '@app/page/books/books-facility/books-facility.component';
import { BooksRegisterComponent } from '@app/page/books/books-register/books-register.component';
import { BooksInspectComponent } from '@app/page/books/books-inspect/books-inspect.component';
import { BooksMandatoryComponent } from '@app/page/books/books-mandatory/books-mandatory.component';
import { BooksRtfeeComponent } from '@app/page/books/books-rtfee/books-rtfee.component';
import { BooksPerformanceComponent } from '@app/page/books/books-performance/books-performance.component';

import { PrintBooksFacilityComponent } from '@app//page/books/books-facility/print/print-books-facility/print-books-facility.component';
import { PrintBooksRegisterComponent } from '@app/page/books/books-register/print/print-books-register/print-books-register.component';
import { PrintBooksUnfitnessComponent } from '@app/page/books/books-inspect/print/print-books-unfitness/print-books-unfitness.component';
import { PrintBooksCertificateComponent } from '@app/page/books/books-inspect/print/print-books-certificate/print-books-certificate.component';
import { PrintBooksCertificate1Component } from '@app/page/books/books-inspect/print/print-books-certificate1/print-books-certificate1.component';
import { PrintBooksInspectComponent } from '@app/page/books/books-inspect/print/print-books-inspect/print-books-inspect.component';
import { PrintBooksMandatoryComponent } from '@app/page/books/books-mandatory/print/print-books-mandatory/print-books-mandatory.component';
import { PrintFacilityChecklistComponent } from '@app/page/inspect/inspect-facility/print/print-facility-checklist/print-facility-checklist.component';
import { PrintFacilityReceiptComponent } from '@app/page/inspect/inspect-facility/print/print-facility-receipt/print-facility-receipt.component';
import { PrintGiroComponent } from '@app/page/inspect/inspect-history/print/print-giro/print-giro.component';
import { PrintDmComponent } from '@app/page/inspect/inspect-history/print/print-dm/print-dm.component';
import { PrintBooksInspect1Component } from './page/books/books-inspect/print/print-books-inspect1/print-books-inspect1.component';

const routes: Routes = [
  /* 관리자 path */
  { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
  { path: 'basic', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'client', component: ClientComponent },
      { path: 'fee', component: FeeComponent },
      { path: 'region', component: RegionComponent },
    ]
  },
  { path: 'inspect', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'facility', component: InspectFacilityComponent },
      { path: 'facility/add', component: InspectAddComponent },
      { path: 'history', component: InspectHistoryComponent },
      { path: 'consult', component: InspectConsultComponent },

      { path: 'facility/print/checklist', component: PrintFacilityChecklistComponent },
      { path: 'facility/print/receipt', component: PrintFacilityReceiptComponent },

      { path: 'history/print/giro', component: PrintGiroComponent },
      { path: 'history/print/dm', component: PrintDmComponent },
    ]
  },
  { path: 'books', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'facility', component: BooksFacilityComponent },
      { path: 'register', component: BooksRegisterComponent },
      { path: 'inspect', component: BooksInspectComponent },
      { path: 'mandatory', component: BooksMandatoryComponent },
      { path: 'rtfee', component: BooksRtfeeComponent },
      { path: 'performance', component: BooksPerformanceComponent },
      
      { path: 'facility/print/facility', component: PrintBooksFacilityComponent },
      { path: 'register/print/register', component: PrintBooksRegisterComponent },
      { path: 'inspect/print/unfitness', component: PrintBooksUnfitnessComponent },
      { path: 'inspect/print/certificate', component: PrintBooksCertificateComponent },
      { path: 'inspect/print/certificate1', component: PrintBooksCertificate1Component },
      { path: 'inspect/print/inspect', component: PrintBooksInspectComponent },
      { path: 'inspect/print/inspect1', component: PrintBooksInspect1Component },
      { path: 'mandatory/print/mandatory', component: PrintBooksMandatoryComponent },
    ]
  },
  { path: 'system', canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always',
    children: [
      { path: 'common', component: CommonCodeComponent },
      { path: 'auth/group', component: AuthGroupComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(
    private router: Router,
    private commonService: CommonService,
  ) {
    // 로딩 처리
    this.router.events.subscribe((event: Event) => {
      switch( true ) {
        case event instanceof NavigationStart: {
          break;
        }
        case event instanceof NavigationEnd:
          // uri 분석해서 현재 페이지 번호 가져오기
          for( let index in this.commonService.menuList ) {
            if( this.commonService.menuList[index].link_url !== '' && location.pathname.indexOf( this.commonService.menuList[index].link_url ) > -1 ) {
              this.commonService.menuseq = this.commonService.menuList[index].seq;
              break;
            } else {
              let check = false;
              for( let subindex in this.commonService.menuList[index].sub ) {
                if( this.commonService.menuList[index].sub[subindex].link_url !== '' && location.pathname.indexOf( this.commonService.menuList[index].sub[subindex].link_url ) > -1 ) {
                  this.commonService.menuseq = this.commonService.menuList[index].sub[subindex].seq;
                  check = true;
                  break;
                }
              }

              if( check ) break;
            }
          }

          // 페이지 전환 시 최 상단으로 자동 스크롤
          window.scrollTo(0, 0);
          break;

        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          break;
        }

        default: {
          break;
        }
      }
    });
  }
}
