/*******************************************************************************
  설  명 : 시설검사관리 - 검사표
  작성일 : 2020-02-26
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbInputDatepicker, NgbCalendar, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { ReceptionService } from '@app/service/reception.service';
import { CommonCodeService } from '@app/service/common.code.service';
import { Globals } from '@app/service/globals.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AgGridSaveComponent } from '@app/components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@app/components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHtmlComponent } from '@app/components/ag-grid-html/ag-grid-html.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

let judgmentList: any = [];

@Component({
  selector: 'app-inspect-report',
  templateUrl: './inspect-report.component.html',
  styleUrls: ['./inspect-report.component.scss']
})
export class InspectReportComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() data: any;

  // 그리드 관련 선언
  selectedRows: number = 0;
  dataList: any = [];

  gridApiReport: any;
  gridColumnApiReport: any;
  columnDefs: any;

  defaultColDef: any;
  domLayout: any;
  rowSelection: any;

  noRowsTemplate: string;

  // 그리드 이미지 처리
  frameworkComponents = {
    agGridHtmlComponent: AgGridHtmlComponent
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private receptionService: ReceptionService,
    private commonCodeService: CommonCodeService,
    private globals: Globals,
    private calendar: NgbCalendar
  ) {

  }

  /*******************************************************************************
    설  명 : 그리드 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async setGridInit() {

    var judgment = [];
    if( judgmentList.length > 0 ) {
      judgmentList.forEach(item => {
        judgment.push(item.commonName);
      });
    }

    // ag grid 컬럼 선언
    this.columnDefs = [
      { headerName: '순번', field: 'reportSeq', cellClass: 'cp center' ,hide:true },
      { headerName: '순번', field: 'rowIndex', cellClass: 'cp center', valueGetter: "node.rowIndex + 1" ,width:70 },
      { headerName: '검사항목', field: 'inspectionItem', cellClass: 'cp center',width:150 },
      { headerName: '검사기준', field: 'inspectionBasic', cellClass: 'cp',width:350,
        editable: true,
        cellEditor: 'agTextCellEditor',
        cellRenderer: this.getRenderer()      
      },
      { headerName: '검사결과', field: 'inspectionResult', cellClass: 'cp',width:300,
        editable: true,
        cellEditor: 'agTextCellEditor',
        cellRenderer: this.getRenderer()      
      },
      { headerName: '판정', field: 'judgmentText', cellClass: 'cp center',width:100, 
        //cellRenderer: actionCellRenderer
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellRenderer: this.getRenderer(),
        /*
        cellRenderer: function (data: any) {
          var color = judgmentList.find(item => item.commonName == data.value);

          return color.commonName;
        },
        */
        onCellValueChanged: function (data: any) {
          //console.log(data);
          var judgmentText = data.data.judgmentText;
          data.data.judgment = judgmentList.find(item => item.commonName == judgmentText).commonCode;
        },
        cellEditorParams: {
          values: judgment
        }

      },
      {headerName: '관계조항', field: 'referCls', cellClass: 'cp center',width:100  },
    ];

    // default 컬럼 옵션
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowSelection = "single";

    // 메시지 표시 선언
    this.noRowsTemplate = "검색된 데이터가 없습니다.";
  }

  /*******************************************************************************
    설  명 : cellRenderer 커스텀
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getRenderer() {
    function CellRenderer() {}
    CellRenderer.prototype.createGui = function() {
      var template =
        '<span><button id="theButton" class="btn btn-default f12" style="padding: 0 5px 0 5px !important; vertical-align: text-bottom !important;">수정</button><span id="theValue" style="padding-left: 4px;"></span></span>';
      var tempDiv = document.createElement("div");
      tempDiv.innerHTML = template;
      this.eGui = tempDiv.firstElementChild;
    };
    CellRenderer.prototype.init = function(params) {
      this.createGui();
      this.params = params;
      var eValue = this.eGui.querySelector("#theValue");
      eValue.innerHTML = params.value;
      this.eButton = this.eGui.querySelector("#theButton");
      this.buttonClickListener = this.onButtonClicked.bind(this);
      this.eButton.addEventListener("click", this.buttonClickListener);
    };
    CellRenderer.prototype.onButtonClicked = function() {
      var startEditingParams = {
        rowIndex: this.params.rowIndex,
        colKey: this.params.column.getId()
      };
      this.params.api.startEditingCell(startEditingParams);
    };
    CellRenderer.prototype.getGui = function() {
      return this.eGui;
    };
    CellRenderer.prototype.destroy = function() {
      this.eButton.removeEventListener("click", this.buttonClickListener);
    };
    return CellRenderer;
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async ngOnInit() {
    // 공통코드 가져오기
    await this.getCommonList();
    
    // 그리드 초기화
    await this.setGridInit();
  }

  async ngOnChanges() {
    // 검사표 리스트 가져오기
    if( this.data.receptionSeq != null ) {
      await this.getInspectionReport();
    }
  }

  /*******************************************************************************
    설  명 : 공통코드 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getCommonList() {
    // 검사표 판정결과
    await this.commonCodeService.getJudgmentSelect().then( response => {
      //console.log(response);
      if( response.ResultCode ) {
        judgmentList = response.data;
      }
    });
  }

  /*******************************************************************************
    설  명 : 검사표 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getInspectionReport() {
    await this.receptionService.getInspectionReportNew( this.data.receptionSeq ).then( response => {
      if( response.ResultCode ) {
        this.dataList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : ag grid ready 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onGridReady(params) {
    this.gridApiReport = params.api;
    this.gridColumnApiReport = params.columnApi;
  }

  /*******************************************************************************
    설  명 : ag grid filter 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onFilterChanged($event) {
    let filteredRowCount = 0;
    this.gridApiReport.forEachNodeAfterFilter( function(node) {
      filteredRowCount++;
    });
    this.selectedRows = filteredRowCount;
  }

  /*******************************************************************************
    설  명 : ag grid 행 클릭 시 처리 - 거래처 정보 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onCellClicked($event) {
    if( $event.colDef.field !== 'seq' ) {
      //this.addClient( $event.data.seq );
    } else {

    }
  }

  /*******************************************************************************
    설  명 : 검사표 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setInspectionReportCreate() {
    let gridData = this.gridApiReport.gridOptionsWrapper.gridOptions.rowData;

    if( gridData.length > 0 ) {
      this.toastrService.error( '이미 검사표가 생성되어 있습니다' );
      return false;
    } else {
      if( confirm("검사표를 생성하시겠습니까?") ) {
        this.receptionService.setInspectionReportCreate( this.data.receptionSeq, this.data.facilityCode ).then( response => {
          //console.log(response);
          if( response.ResultCode ) {
            this.toastrService.success( response.ResultMessage, '');

            this.getInspectionReport();
          } else {
            this.toastrService.error( response.ResultMessage, '');
          }
        }, error => {
          this.toastrService.error( error, '');
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 검사표 일괄저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setInspectionReport() {
    if( confirm("수정한 내용을 일괄 저장하시겠습니까?") ) {
      let gridData = this.gridApiReport.gridOptionsWrapper.gridOptions.rowData;

      if( gridData.length < 1 ) {
        this.toastrService.error( '저장할 데이터가 없습니다.', '');
        return false;
      }

      this.receptionService.setInspectionReport( JSON.stringify(gridData) ).then( response => {
        //console.log(response);
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');

          this.getInspectionReport();
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      }, error => {
        this.toastrService.error( error, '');
      });
    }
  }

  /*******************************************************************************
    설  명 : 검사표 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openInspect() {
    if( this.dataList.length < 1 ) {
      this.toastrService.error( '검사표가 생성되지 않았습니다.' );
      return false;
    } else {
      let seqArray: any = [];

      seqArray.push( this.data.receptionSeq );

      let params = JSON.stringify( seqArray );
      sessionStorage.setItem( 'booksInspectPrintInspect', params );

      let url = '/books/inspect/print/inspect';

      window.open(url, 'booksInspectPrintInspect', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=1050, height=800, left=0, top=0' );
    }
  }

}
