/*******************************************************************************
  설  명 : 수수료 적용일 추가/수정
  작성일 : 2020-02-26
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { UtilService } from '@app/service/util.service';
import { CustomValidator } from '@app/service/custom.validators';
import { CommonService } from '@app/service/common.service';
import { Globals } from '@app/service/globals.service';
import { InspectFeeService } from '@app/service/inspect.fee.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-fee-add',
  templateUrl: './fee-add.component.html',
  styleUrls: ['./fee-add.component.scss']
})
export class FeeAddComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역 변수
  *******************************************************************************/
  public registDate: any;

  title: any = '추가';

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      registDateOld:["", [Validators.required] ],
      registDateNew:["", [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private globals: Globals,
    private modalService: NgbModal,
    private inspectFeeService: InspectFeeService,
    public activeModal: NgbActiveModal,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.form.patchValue({
      registDateOld: this.registDate
    });
  }

  /*******************************************************************************
    설  명 : 수수료적용일 복사 등록하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCopyInspectFee() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      this.inspectFeeService.setCopyInspectFee( this.form ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '');
          this.activeModal.close(true);
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.', '');
    }
  }

}
