import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@app/service/auth.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-aheader',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  // 메뉴 슬라이드
  menu_slide() {
  	if($("#main-menu").hasClass("small")) {
      $("#main-menu").removeClass("small");
    } else {
      $("#main-menu").addClass("small");
    }
  	if($("#admin-contents-area").hasClass("admin-contents-area-small")) {
      $("#admin-contents-area").removeClass("admin-contents-area-small");
    } else {
      $("#admin-contents-area").addClass("admin-contents-area-small");
    }
  }

  // 검색창 toggle
  search_toggle() {
  	if($(".search-box").hasClass("active")) {
      $(".search-box").removeClass("active");
    } else {
    	$(".search-box").addClass("active");
    }
  	if($(".search-input").hasClass("active")) {
      $(".search-input").removeClass("active");
    } else {
    	$(".search-input").addClass("active");
    	$(".search-input>input").focus();
    }
  }

	loginUserTooltip() {
		$(".tooltip-loginuser").toggle();
	}

  onLogout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}
