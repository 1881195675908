import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})

export class ReportService {

  constructor(
    private restful: RestfulService
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.log(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 시설대장 리스트 가져오기
  getFacilityReportList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'report',
        version: '1.0',
        action: 'getFacilityReportList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시설대장 출력 정보 가져오기
  getFacilityReport( facilitySeq ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'report',
        version: '1.0',
        action: 'getFacilityReport'
      }, {
        facilitySeq: facilitySeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  //  접수결과대장 리스트 가져오기
  getReceptionReportList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'report',
        version: '1.0',
        action: 'getReceptionReportList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  //  검사실시대장 리스트 가져오기
  getInspectionResultList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'report',
        version: '1.0',
        action: 'getInspectionResultList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  //  검사실시대장 출력 리스트 가져오기
  getPrintInspectionResultList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'report',
        version: '1.0',
        action: 'getPrintInspectionResultList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사부적합통지서 정보 가져오기
  getRejectReport( seqArray ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'report',
        version: '1.0',
        action: 'getRejectReport'
      }, {
        seqArray: seqArray
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 검사증명서 정보 가져오기
  getInspectionCertificate( seqArray ): Promise<any> {
    return this.restful.post({
        program: 'fms',
        service: 'report',
        version: '1.0',
        action: 'getInspectionCertificate'
      }, {
        seqArray: seqArray
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 필증발급관리대장 리스트 가져오기
  getCertificateManagementList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'report',
        version: '1.0',
        action: 'getCertificateManagementList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 필증발급관리대장 출력용 리스트 가져오기
  getPrintCertificateManagementList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'report',
        version: '1.0',
        action: 'getPrintCertificateManagementList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // RT, 용도별 수수료집계 가져오기
  getFeeReport( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'report',
        version: '1.0',
        action: 'getFeeReport1'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시설검사대행 시도별실적 리스트 가져오기
  getInspectionPerformanceRegion( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'report',
        version: '1.0',
        action: 'getInspectionPerformanceRegion'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시설검사대행 구군별실적 리스트 가져오기
  getInspectionPerformanceCity( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'report',
        version: '1.0',
        action: 'getInspectionPerformanceCity'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시설검사대행 안전공사지사별 리스트 가져오기
  getInspectionPerformanceKgs( search: any ): Promise<any> {
    return this.restful.get({
        program: 'fms',
        service: 'report',
        version: '1.0',
        action: 'getInspectionPerformanceKgs'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
